"use strict";

app.SSOComponent = {
    mounted: function mounted() {
        this.SSO;

        $(window).on("SSO_DIALOG_OPEN", this.openDialog.bind(this));
        $(window).on("SSO_DIALOG_CLOSE", this.closeDialog.bind(this));
        window.SSOCallback = this.SSOCallback.bind(this);
        setTimeout(this.init.bind(this), 500);
    },
    computed: function computed() {},

    getData: function getData() {
        return {
            config: window.SSO_CONFIG.SSO,
            cas_url: "",
            location: location,
            dialog: false,
            statuc: ""
        };
    },
    dispose: function dispose() {},
    init: function init() {
        this.SSO = new verallia.SSO();
        this.SSO.login().done(this.SSOLoggedIn.bind(this));
    },
    openDialog: function openDialog() {
        this.dialog = true;
        this.cas_url = window.SSO_CONFIG.SSO.CAS_XDOMAIN_URI + window.SSO_CONFIG.SSO.CAS_AUTH_URI + '?client_id=' + window.SSO_CONFIG.SSO.CAS_CLIENT_ID + '&redirect_uri=' + location.origin + location.pathname + 'static/sso.html';
    },
    closeDialog: function closeDialog() {
        this.dialog = false;
        this.cas_url = "";
    },
    SSOCallback: function SSOCallback(data) {
        if (data.accessToken) {
            /*trace("-------------------------- User logged in as:");
            trace(this.SSO.getLoggedUser());
            trace(data);
              window.user = this.SSO.getLoggedUser();
            $(window).trigger("SSO_LOGIN");*/
        } else if (data.error) {
            /*trace("-------------------------- Error user not logged in !");
            trace(data);
              window.user = null;
            delete window.user;
            $(window).trigger("SSO_LOGOUT");*/
        }
    },
    SSOLoggedIn: function SSOLoggedIn() {
        window.user = this.SSO.getLoggedUser();
        this.$destroy();
        $(window).trigger("SSO_LOGIN");
    }
};