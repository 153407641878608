"use strict";

var AppFilters = function AppFilters() {

        this.timeToDate = function (time) {
                if (!time) return 'time null';

                var date = new Date();
                date.setTime(time);

                var date_date = String(date.getDate());
                if (date.getDate() < 10) date_date = "0" + String(date.getDate());
                var date_month = String(date.getMonth() + 1);
                if (date.getMonth() < 10) date_month = "0" + String(date.getMonth() + 1);

                var date_hours = String(date.getHours());
                if (date.getHours() < 10) date_hours = "0" + String(date.getHours());
                var date_minutes = String(date.getMinutes());
                if (date.getMinutes() < 10) date_minutes = "0" + String(date.getMinutes());

                return date_date + "/" + date_month + "/" + date.getFullYear() + " - " + date_hours + ":" + date_minutes;
        };
        this.formatPLSDate = function (time) {
                if (!time) return 'time null';

                time = time.split("T")[0];

                var date = new Date(time);

                var date_date = String(date.getDate());
                if (date.getDate() < 10) date_date = "0" + String(date.getDate());
                var date_month = String(date.getMonth() + 1);
                if (date.getMonth() < 9) date_month = "0" + String(date.getMonth() + 1);

                if (app.Data.data.config.language == "fr") return date_date + "/" + date_month + "/" + date.getFullYear();else return date_month + "/" + date_date + "/" + date.getFullYear();
        };
        this.formatNumbers = function (num) {
                //return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1 ');
                return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        };
        this.formatStocksDate = function (value) {
                if (!value || value == "") return "";

                var diff = 2592000000; //30 days in ms

                var current_date = new Date();
                var stock_date = new Date(value);
                var stock_date_ms = stock_date.getTime();
                var current_date_ms = current_date.getTime();

                var day = stock_date.getDate();
                var month = stock_date.getMonth() + 1;
                var year = stock_date.getFullYear();

                if (day < 10) day = "0" + day;
                if (month < 10) month = "0" + month;

                if (stock_date_ms < current_date_ms + 2592000000) return day + "/" + month + "/" + year;else if (stock_date_ms > current_date_ms + 2592000000) return month + "/" + year;else return day + "/" + month + "/" + year;
        };
};
app.AppFilters = new AppFilters();