"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.Application = function () {
    function _class() {
        _classCallCheck(this, _class);

        this.init();
    }

    _createClass(_class, [{
        key: "init",
        value: function init() {
            $(window).on("SELECT_BOTTLE", this.selectBottle.bind(this));
            $(window).on("OPEN_VG", this.openVirtualGlass.bind(this));
            $(window).on("resize", this.resizeTrigger.bind(this));
            $(window).on(verallia.Event.RESIZE, this.resize.bind(this));
            //ermove before dispose
            this.resize();

            if (app.Data.data.platform == "MyVerallia") {
                if (app.Data.data.mode == "VirtualGlass") {
                    $(".MyVerallia").css("margin-top", 0);
                    $(".MyVerallia #ct_product .CTProductHeader").css("top", 0);
                }
            }

            $("#ct_loader").delay(1000).fadeOut(500);
            $("#catalog_app").show();
            $(window).trigger(verallia.Event.APP_READY);
            $(window).trigger(verallia.Event.STOP_WORKING);

            var can_access_vg = true;

            if (app.Data.data.platform == "MyVerallia") {
                try {
                    if (mv_can_access_virtualglass == false || mv_can_access_virtualglass == "false") can_access_vg = false;else if (mv_can_access_virtualglass == true || mv_can_access_virtualglass == "true") can_access_vg = true;
                } catch (e) {
                    can_access_vg = false;
                }
            }

            try {
                trace("calling catalog loaded callback for VG");

                if (app.Data.data.platform == "MyVerallia") {
                    trace("SF contact id : " + mv_user_contactid);
                    window.catalogLoaded(app.Data.data.access_token, can_access_vg, mv_user_contactid);
                    window.setCatalogCountry(app.Data.data.config.country);
                } else {
                    window.catalogLoaded(app.Data.data.access_token, can_access_vg);
                }
            } catch (e) {
                trace("catalog functions not found, trying to send message to parent window (for VirtualGlass V3)");

                if (app.Data.data.platform == "MyVerallia") {
                    var post_data = JSON.stringify({ type: "verallia", data: { message: "catalogLoaded", data: { access_token: app.Data.data.access_token, can_access_vg: can_access_vg, user_contact_id: mv_user_contactid } } });
                } else {
                    var post_data = JSON.stringify({ type: "verallia", data: { message: "catalogLoaded", data: { access_token: app.Data.data.access_token, can_access_vg: can_access_vg } } });
                }

                window.parent.postMessage(post_data, "*");
            }

            try {
                trace("trying to send products to VG V3");

                var post_data = JSON.stringify({ type: "verallia", data: { message: "catalogReady", data: { products: app.Utils.getAllMetaProducts() } } });
                window.parent.postMessage(post_data, "*");
            } catch (e) {}
        }
    }, {
        key: "resizeTrigger",
        value: function resizeTrigger() {
            $(window).trigger(verallia.Event.RESIZE);

            //setTimeout(this.resizeTrigger.bind(this), 500);
        }
    }, {
        key: "resize",
        value: function resize() {
            //trace("resize");
            var w = $(window).width();
            if (w >= 740) {
                if (app.Data.data.isMobile == true) app.Data.data.mobileFilters = false;
                app.Data.data.isMobile = false;
                app.Data.data.mobileFilters = true;
            } else {
                if (app.Data.data.isMobile == false) app.Data.data.mobileFilters = false;
                app.Data.data.isMobile = true;
            }
        }
    }, {
        key: "dispose",
        value: function dispose() {
            $(window).off("SELECT_BOTTLE");
            $(window).off("OPEN_VG");
            $(window).off("resize", this.resizeTrigger.bind(this));
            $(window).off(verallia.Event.RESIZE, this.resize.bind(this));
        }
    }, {
        key: "openVirtualGlass",
        value: function openVirtualGlass(e, baseproduct) {
            var index = baseproduct.selected_index;

            var p = {};
            p.id = baseproduct.id;
            p.name = baseproduct.la;
            p.is_project = false;
            p.country = baseproduct.country;
            p.co = baseproduct.country;
            p.area = baseproduct.pr[index].ai;
            p.gd = baseproduct.gd.toLowerCase();
            p.original_gd = baseproduct.gd.toLowerCase();
            p.gds = baseproduct.gds;
            p.fh = baseproduct.fh;
            p.he = baseproduct.he;
            if (!p.he || p.he == "" || p.he == 0) p.he = 350;
            p.lmh = baseproduct.lmh;
            p.we = baseproduct.we;
            p.vo = baseproduct.vo;
            p.di = baseproduct.di;
            p.account = baseproduct.ac;
            p.ac = baseproduct.ac;
            p.color_ref = baseproduct.pr[index].cl_en.toLowerCase();
            p.colors = {};
            p.colors_tab = [];
            p.fi = baseproduct.fi;
            p.vg = baseproduct.vg;
            p.pr = {};
            p.pr.cb = baseproduct.pr[index].cb;
            p.pr.cs = baseproduct.pr[index].cs;
            p.pr.cc = baseproduct.pr[index].cc;
            p.pr.cl = baseproduct.pr[index].cl;
            p.pr.cl_en = baseproduct.pr[index].cl_en;
            p.pr.br = baseproduct.pr[index].br;

            if (baseproduct.pr[index].mg.toLowerCase().indexOf("naa") != -1) {
                p.is_bottle = true;
            } else {
                p.is_bottle = false;
            }

            var a = baseproduct.pr.length;
            for (var i = 0; i < a; i++) {
                //p.colors[baseproduct.pr[index].cl_en.toLowerCase()] = { cc:baseproduct.pr[index].cc, la:baseproduct.pr[index].cl_en.toLowerCase(), cs:baseproduct.pr[index].cs };
                p.colors[baseproduct.pr[i].cl_en.toLowerCase()] = { cc: baseproduct.pr[i].cc, la: baseproduct.pr[i].cl_en.toLowerCase(), cs: baseproduct.pr[i].cs };
                p.colors_tab.push({ cc: baseproduct.pr[i].cc, la: baseproduct.pr[i].cl_en.toLowerCase(), cs: baseproduct.pr[i].cs });
            }

            p.filling_height = parseInt(baseproduct.fh);
            p.height = parseInt(baseproduct.he);
            p.diameter = parseInt(baseproduct.di);
            p.label_max_height = parseInt(baseproduct.lmh);

            if (app.Data.data.platform == "MyVerallia") {

                //PROD
                /*if(window.location.href.indexOf("verallia.force") != -1){
                    try{
                        if(mv_can_access_virtualglass == false || mv_can_access_virtualglass  == "false"){
                            app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/web_catalogvg", "/Web_VirtualGlassViewer");
                            verallia.WindowUtils.openURL(app.Data.data.config.virtualglass_url + "?id=" + baseproduct.id, "_blank");
                            return;
                        }else{
                            app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/web_catalogvg", "/Web_VirtualGlass");
                        }
                    }catch(e){
                        app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/web_catalogvg", "/Web_VirtualGlassViewer");
                        verallia.WindowUtils.openURL(app.Data.data.config.virtualglass_url + "?id=" + baseproduct.id, "_blank");
                        return;
                    }
                }else{ //QUAL*/

                try {
                    var vg_menu = $('.menu-container a[href*="Web_VirtualGlass"]').parent().attr("style");
                    var vg_access = false;
                    if (vg_menu == "display:block") vg_access = true;

                    if (mv_can_access_virtualglass == true || mv_can_access_virtualglass == "true" || vg_access == true) {
                        app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/web_catalogvg", "/Web_VirtualGlass");

                        if (window.location.href.toLowerCase().indexOf("/verallia_catalog") != -1) {
                            trace("parent url: " + parent.document.location.href);
                            app.Data.data.config.virtualglass_url = parent.document.location.href.replace("/catalog", "/virtual-glass");
                            trace("vg url: " + app.Data.data.config.virtualglass_url);
                        }
                        //app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/verallia_catalog", "/Web_VirtualGlass");
                        verallia.WindowUtils.openURL(app.Data.data.config.virtualglass_url + "?mode=render&platform=catalog#/" + JSON.stringify(p), "_blank");
                        return;
                    }

                    /*if(vg_access == false){
                        //app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/web_catalogvg", "/Web_VirtualGlassViewer");
                        app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/verallia_catalog", "/Web_VirtualGlass");
                        verallia.WindowUtils.openURL(app.Data.data.config.virtualglass_url + "?id=" + baseproduct.id, "_blank");
                        return;
                    }*/
                } catch (e) {}

                app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/web_catalogvg", "/Web_VirtualGlass");
                //app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/verallia_catalog", "/Web_VirtualGlass");

                if (window.location.href.toLowerCase().indexOf("/verallia_catalog") != -1) {
                    trace("parent url: " + parent.document.location.href);
                    app.Data.data.config.virtualglass_url = parent.document.location.href.replace("/catalog", "/virtual-glass");
                    trace("vg url: " + app.Data.data.config.virtualglass_url);
                }
                //}
            }

            verallia.WindowUtils.openURL(app.Data.data.config.virtualglass_url + "?mode=render&platform=catalog#/" + JSON.stringify(p), "_blank");
        }
    }, {
        key: "selectBottle",
        value: function selectBottle(e, baseproduct) {
            var index = baseproduct.selected_index;
            var p = {};
            p.id = baseproduct.id;
            p.co = baseproduct.country;
            p.area = baseproduct.pr[index].ai;
            p.la = baseproduct.la;
            p.fh = baseproduct.fh;
            p.he = baseproduct.he;
            if (!p.he || p.he == "" || p.he == 0) p.he = 350;
            p.lmh = baseproduct.lmh;
            p.we = baseproduct.we;
            p.vo = baseproduct.vo;
            p.di = baseproduct.di;
            p.gd = baseproduct.gd;
            p.original_gd = baseproduct.gd;
            p.gd_without_version = app.Utils.getGDWithoutVersion(baseproduct);
            p.gds = baseproduct.gds;
            p.ac = baseproduct.ac;
            p.fi = baseproduct.fi;
            p.vg = baseproduct.vg;

            p.pr = {};
            p.pr.cb = baseproduct.pr[index].cb;
            p.pr.cs = baseproduct.pr[index].cs;
            p.pr.cc = baseproduct.pr[index].cc;
            p.pr.cl = baseproduct.pr[index].cl;
            p.pr.cl_en = baseproduct.pr[index].cl_en;
            p.pr.br = baseproduct.pr[index].br;

            if (p.pr.cl == "half flint") p.pr.cl = "half-flint";
            if (p.pr.cl_en == "half flint") p.pr.cl_en = "half-flint";

            if (baseproduct.pr[index].mg.toLowerCase().indexOf("naa") != -1) {
                p.is_bottle = true;
            } else {
                p.is_bottle = false;
            }

            p.colors = [];
            var c = baseproduct.pr.length;
            for (var k = 0; k < c; k++) {
                p.colors.push({ la: baseproduct.pr[k].cl_en.toLowerCase(), cs: baseproduct.pr[k].cs, cc: baseproduct.pr[k].cc });
            }

            try {
                window.ONBottleSelect(p);
            } catch (e) {
                trace("catalog functions not found, trying to send message to parent window (for VirtualGlass V3)");

                var post_data = JSON.stringify({ type: "verallia", data: { message: "productSelected", product: p } });
                window.parent.postMessage(post_data, "*");
            }
        }
    }]);

    return _class;
}();