"use strict";

app.ProductPLS = {
    mounted: function mounted() {
        $(window).off("UPDATE_PRODUCT_DATA").on("UPDATE_PRODUCT_DATA", this.updateProductData.bind(this));
    },
    update: function update() {},
    getData: function getData() {
        return {
            description: true
        };
    },
    dispose: function dispose() {
        $(window).off("UPDATE_PRODUCT_DATA", this.updateProductData.bind(this));
    },
    closeProduct: function closeProduct() {
        app.Data.data.baseproduct = null;
        app.Data.data.section = "products";
        verallia.Core.getObject("app").updateHash();
        verallia.Core.getObject("filters").updateDisplay();
    },
    updateProductData: function updateProductData() {
        $("#ct_product_description .nano").nanoScroller({ alwaysVisible: true });
        $("#ct_product_details .nano").nanoScroller();
    },
    generatePDF: function generatePDF() {
        app.PLSUtils.openPLSProductPDF(this.baseproduct);
    },
    generatePPT: function generatePPT() {
        app.PLSUtils.openPLSProductPPT(this.baseproduct);
    },
    openPLSEdit: function openPLSEdit(id) {
        verallia.WindowUtils.openURL(app.Data.data.config.pls_suite_edit_url + id + "/", "_blank");
    }
};