"use strict";

app.Search = {
    mounted: function mounted() {
        $("#ct_search input").on("propertychange change keyup input paste focus", this.checkField.bind(this));
        $("#ct_search input").on("focusout", this.focusOut.bind(this));
    },
    update: function update() {},
    getData: function getData() {
        return {
            field: "",
            result: {}
        };
    },
    dispose: function dispose() {
        $("#ct_search input").off("propertychange change keyup input paste focus");
        $("#ct_search input").off("focusout");
    },
    checkField: function checkField() {
        this.result = {};

        $("#ct_search").attr("sdata", this.field);

        if (this.field.length < 3) return;

        var field = String(this.field).toLowerCase();

        for (var str in app.Data.data.baseproducts) {
            var baseproduct = app.Data.data.baseproducts[str];

            if (app.Data.data.platform == "VirtualGlass") {
                if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1 || window.location.href.indexOf("apps.corp.inpkg.net") != -1) {
                    if (baseproduct.vg == false) {
                        continue;
                    }
                } else {
                    if (baseproduct.vg == false) {
                        if (!baseproduct.easyrender) {
                            continue;
                        }
                    }
                }
            }

            if (baseproduct.la.toLowerCase().indexOf(field) != -1 || baseproduct.gd.toLowerCase().indexOf(field) != -1) {
                this.result[baseproduct.id] = { id: baseproduct.id, index: baseproduct.index };
                continue;
            }

            if (app.Data.data.platform == "ICTPLS") {
                if (baseproduct.pls.baseProduct && baseproduct.pls.baseProduct.toLowerCase().indexOf(field) != -1) {
                    this.result[baseproduct.id] = { id: baseproduct.id, index: baseproduct.index };
                    continue;
                }

                if (baseproduct.pls.baseProductName && baseproduct.pls.baseProductName.toLowerCase().indexOf(field) != -1) {
                    this.result[baseproduct.id] = { id: baseproduct.id, index: baseproduct.index };
                    continue;
                }
            }

            var a = baseproduct.pr.length;
            for (var i = 0; i < a; i++) {
                if (baseproduct.pr[i].cb.toLowerCase().indexOf(field) != -1) {
                    this.result[baseproduct.id] = { id: baseproduct.id, index: i };
                    break;
                }
            }
        }
    },
    focusOut: function focusOut() {
        setTimeout(this.closeSearch.bind(this), 200);
    },
    closeSearch: function closeSearch() {
        this.result = {};
    },

    openBaseProduct: function openBaseProduct(baseproduct) {
        //trace(verallia.ObjectUtils.copy(baseproduct));

        //app.Data.data.mobileFilters = false;

        app.Data.data.product_section = 0;
        app.Data.data.baseproduct = app.Data.data.baseproducts[baseproduct.id];
        trace(verallia.ObjectUtils.copy(app.Data.data.baseproduct));

        app.Data.data.baseproduct.selected_index = baseproduct.index;
        this.$forceUpdate();

        /*if(app.Data.data.platform == "ICTPLS"){
            app.PLSUtils.openPLSProductPDF(app.Data.data.baseproduct);
            return;
        }*/

        if (app.Data.data.section == "product") {
            $(window).trigger("UPDATE_PRODUCT_DATA");
        }

        app.Data.data.section = "product";
        verallia.Core.getObject("app").updateHash();

        setTimeout(this.closeSearch.bind(this), 200);
    }
};