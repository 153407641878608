"use strict";

app.ProductCard = {
    mounted: function mounted() {},
    update: function update() {},
    getData: function getData() {
        return {};
    },
    dispose: function dispose() {},
    openProduct: function openProduct(id, index) {
        /*if(app.Data.data.platform == "ICTPLS"){
            app.PLSUtils.openPLSProductPDF(app.Data.data.baseproducts[id]);
            return;
        }*/

        app.Data.data.product_section = 0;
        app.Data.data.baseproduct = app.Data.data.baseproducts[id];
        app.Data.data.baseproduct.selected_index = index;
        app.Data.data.section = "product";
        verallia.Core.getObject("app").updateHash();
    },
    openVG: function openVG() {
        $(window).trigger("OPEN_VG", this.baseproduct);
    },

    compare: function compare(id, index) {
        if (app.Data.data.comparator_products[id]) this.$delete(app.Data.data.comparator_products, id);else this.$set(app.Data.data.comparator_products, id, { id: id, index: index });

        verallia.LocalStorageUtils.setKey("ct_comparator" + app.Data.data.platform, JSON.stringify(app.Data.data.comparator_products));

        $(window).trigger("COMPARATOR_UPDATE");
    },
    sendToSamples: function sendToSamples(id, index) {
        if (app.Data.data.samples_products[id]) this.$delete(app.Data.data.samples_products, id);else this.$set(app.Data.data.samples_products, id, { id: id, index: index, quantity: 1 });

        verallia.LocalStorageUtils.setKey("ct_samples" + app.Data.data.platform, JSON.stringify(app.Data.data.samples_products));

        $(window).trigger("SAMPLES_UPDATE");
    },
    selectBottle: function selectBottle() {
        this.baseproduct.selected_index = this.baseproduct.index;
        $(window).trigger("SELECT_BOTTLE", this.baseproduct);
    },

    checkMTO: function checkMTO(baseproduct) {
        return app.Utils.checkMTO(baseproduct, baseproduct.selected_index);
    }
};