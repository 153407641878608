"use strict";

app.FilterValuesRanges = {
    mounted: function mounted() {
        $(window).off("UPDATE_FILTERS_DATA").on("UPDATE_FILTERS_DATA", this.updateFiltersData.bind(this));
    },
    update: function update() {},
    getData: function getData() {
        return {};
    },
    dispose: function dispose() {
        $(window).off("UPDATE_FILTERS_DATA");
    },
    updateFiltersData: function updateFiltersData() {
        this.checkCheckBoxValues();
        this.checkInput();
    },
    checkCheckBoxValues: function checkCheckBoxValues() {
        if (app.Data.data.config.filters.values_checkbox[this.target]) {
            var a = app.Data.data.config.filters.values_checkbox[this.target].length;
            for (var i = 0; i < a; i++) {
                var values_data = app.Data.data.config.filters.values_checkbox[this.target][i];

                if (app.Data.data.filters[this.target].selected_min > app.Data.data.filters[this.target].min || app.Data.data.filters[this.target].selected_max < app.Data.data.filters[this.target].max) {
                    if (app.Data.data.filters[this.target].selected_min >= values_data.values[0] && app.Data.data.filters[this.target].selected_min < values_data.values[1]) {
                        values_data.selected = true;
                    } else if (app.Data.data.filters[this.target].selected_max > values_data.values[0] && app.Data.data.filters[this.target].selected_max < values_data.values[1]) {
                        values_data.selected = true;
                    } else if (app.Data.data.filters[this.target].selected_min <= values_data.values[0] && app.Data.data.filters[this.target].selected_max >= values_data.values[1]) {
                        values_data.selected = true;
                    } else {
                        values_data.selected = false;
                    }
                } else {
                    values_data.selected = false;
                }
            }
        }
    },
    checkInput: function checkInput() {
        var min = parseInt(app.Data.data.filters[this.target].selected_min);
        var max = parseInt(app.Data.data.filters[this.target].selected_max);

        if (!min || min == "") min = app.Data.data.filters[this.target].min;
        if (!max || max == "") max = app.Data.data.filters[this.target].max;

        if (min < app.Data.data.filters[this.target].min) min = app.Data.data.filters[this.target].min;
        if (min > max) min = max;
        if (max < min) max = min;
        if (max > app.Data.data.filters[this.target].max) max = app.Data.data.filters[this.target].max;

        app.Data.data.filters[this.target].selected_min = min;
        app.Data.data.filters[this.target].selected_max = max;
    },
    applyFilters: function applyFilters() {
        this.updateFiltersData();
        verallia.Core.getObject("filters").applyFilters();
    },
    applyCheckboxValue: function applyCheckboxValue() {
        var min = 100000;
        var max = 0;

        var selection = false;
        var a = app.Data.data.config.filters.values_checkbox[this.target].length;
        for (var i = 0; i < a; i++) {
            var values_data = app.Data.data.config.filters.values_checkbox[this.target][i];

            if (values_data.selected == true) {
                if (values_data.values[0] < min) min = values_data.values[0];
                if (values_data.values[1] > max) max = values_data.values[1];
                selection = true;
            }
        }

        if (selection == true) {
            app.Data.data.filters[this.target].selected_min = min;
            app.Data.data.filters[this.target].selected_max = max;
        } else {
            app.Data.data.filters[this.target].selected_min = app.Data.data.filters[this.target].min;
            app.Data.data.filters[this.target].selected_max = app.Data.data.filters[this.target].max;
        }

        this.applyFilters();
    }
};