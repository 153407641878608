"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.Data = function () {
        function _class() {
                _classCallCheck(this, _class);

                this.vue_data;
                this.main_app;
                this.application;
                this.html_templates;

                this.data;
        }

        _createClass(_class, null, [{
                key: "init",
                value: function init() {
                        this.data = {};
                        this.data.config = null;

                        this.data.user = { id: "XXXXXX", email: "mail@verallia.com", name: "Global", given_name: "User" };

                        this.data.access_token = "XXXXXX";
                        this.data.mode = null;
                        this.data.platform = null; // ICT || ICTPLS || ICTCOMPETITORS || VIRTUALGLASS || APP || MYVERALLIA || VERALLIACOM
                        this.data.MVEnv = "prod"; // if we are in PROD or QUAL
                        this.data.app_ready = false;
                        this.data.vg_sf_countries = null;

                        this.data.use_player = true;
                        this.data.working = true;
                        this.data.loading = false;
                        this.data.section = "products";
                        this.data.product_section = 0;
                        this.data.check_hashchange = false;
                        this.data.finishedproducts = {};
                        this.data.pf_exclude = null;
                        this.data.premium = null;
                        this.data.mot_data = null;
                        this.data.displayMissingMarketsProcucts = false;
                        this.data.missingMarketsProducts = [];

                        this.data.baseproducts = {};
                        this.data.baseproducts_id = {};
                        this.data.baseproducts_gd = {};
                        this.data.baseproduct = null;
                        this.data.filtered_baseproducts = {};
                        this.data.filtered_baseproducts_array = [];
                        this.data.filters = {};
                        this.data.used_filters = {};
                        this.data.product_page = 0;
                        this.data.products_order = "name";
                        this.data.phantom_product_cards = 0;
                        this.data.comparator_products = {};
                        this.data.samples_products = {};
                        this.data.comparator = false;
                        this.data.samples = false;
                        this.data.burger = false;
                        this.data.only_easyrender_products = true;
                        this.data.product_mode = "general"; //general || myproducts for myverallia
                        this.data.selected_country = "fr"; //selected country for myverallia
                        this.data.products_overlay = false;
                        this.data.isMobile = false;
                        this.data.mobileFilters = false;

                        this.data.stock_icons = {}; //stock icons
                        this.stock_request = null; //stock rquest for myverallia
                        this.data.stock_check = false; //stock rquest for myverallia
                        this.html_templates = {};
                }
        }, {
                key: "reset",
                value: function reset() {
                        var platform = window.platform;
                        var user = this.data.user;

                        this.init();

                        this.data.platform = platform;
                        this.data.user = user;
                }
        }]);

        return _class;
}();