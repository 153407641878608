"use strict";

app.Products = {
    mounted: function mounted() {
        $(window).off("UPDATE_PRODUCTS_DATA").on("UPDATE_PRODUCTS_DATA", this.updateProductsData.bind(this));
        $(window).on(verallia.Event.RESIZE, this.resize.bind(this));
        $("#ct_products_list").nanoScroller().on("scrollend", this.scrollEnd.bind(this));
    },
    update: function update() {},
    getData: function getData() {
        return {};
    },
    dispose: function dispose() {
        $(window).off("UPDATE_PRODUCTS_DATA");
        //$(window).off(verallia.Event.RESIZE, this.resize.bind(this));
    },
    scrollEnd: function scrollEnd() {
        app.Data.data.product_page++;
        $("#ct_products_list").nanoScroller();
    },
    updateProductsData: function updateProductsData() {
        //app.Data.data.filtered_baseproducts = verallia.ObjectUtils.copy(app.Data.data.baseproducts_id);
        app.Data.data.filtered_baseproducts = {};
        app.Data.data.filtered_baseproducts_array = [];
        app.Data.data.product_page = 1;
        var filtered_products = {};
        var filters = {};

        //CHECK RANGES
        if (app.Data.data.config.filters.use_ranges == true && app.Data.data.used_filters.indexOf("ranges") != -1) {
            filters.ranges = false;
            for (var str in app.Data.data.filters.ranges) {
                var filter = app.Data.data.filters.ranges[str];
                if (filter.selected == true) {
                    filters.ranges = true;
                }
            }
        }

        //CHECK BRANDS
        if (app.Data.data.config.filters.use_brands == true && app.Data.data.used_filters.indexOf("brands") != -1) {
            filters.brands = false;
            for (var str in app.Data.data.filters.brands) {
                var filter = app.Data.data.filters.brands[str];
                if (filter.selected == true) {
                    filters.brands = true;
                }
            }
        }

        //CHECK COLORS
        if (app.Data.data.config.filters.use_colors == true && app.Data.data.used_filters.indexOf("colors") != -1) {
            filters.colors = false;
            for (var str in app.Data.data.filters.colors) {
                var filter = app.Data.data.filters.colors[str];
                if (filter.selected == true) {
                    filters.colors = true;
                }
            }
        }

        //CHECK FINISH
        if (app.Data.data.config.filters.use_finish == true && app.Data.data.used_filters.indexOf("finish") != -1) {
            filters.finish = false;
            for (var str in app.Data.data.filters.finish) {
                var filter = app.Data.data.filters.finish[str];
                if (filter.selected == true) {
                    filters.finish = true;
                }
            }
        }

        //CHECK PLS VALUES*
        if (app.Data.data.platform == "ICTPLS") {
            var a = app.Data.data.config.filters.pls_values.length;
            for (var i = 0; i < a; i++) {
                var value = app.Data.data.config.filters.pls_values[i];

                if (app.Data.data.config.filters["use_pls_" + value] == true && app.Data.data.used_filters.indexOf("pls_" + value) != -1) {
                    filters["pls_" + value] = false;

                    if (value == "decorators" || value == "contents" || value == "communication") {
                        for (var str in app.Data.data.filters["pls_" + value]) {
                            var filter = app.Data.data.filters["pls_" + value][str];
                            if (filter.selected == true) {
                                filters["pls_" + value] = true;
                            }
                        }
                    } else {
                        if (app.Data.data.filters["pls_" + value].selected.length > 0) filters["pls_" + value] = true;
                    }
                }
            }
        }

        var removed_products = {};

        // PARSE EVERY PRODUCT AND CHECK FILTERS AVAILABILITY
        var a = 0;
        for (var str in app.Data.data.baseproducts) {
            var baseproduct = app.Data.data.baseproducts[str];
            baseproduct.index = 0;

            //PRODUCT MODE
            if (app.Data.data.platform == "MyVerallia") {
                if (!filtered_products["product_mode"]) filtered_products["product_mode"] = {};

                if (app.Data.data.product_mode == "general" && !baseproduct.myproduct) {
                    filtered_products["product_mode"][baseproduct.id] = baseproduct.id;
                } else if (app.Data.data.product_mode == "myproducts" && baseproduct.myproduct == true) {
                    filtered_products["product_mode"][baseproduct.id] = baseproduct.id;
                }
            }

            //COUNTRIES
            if (app.Data.data.config.filters.use_countries == true && app.Data.data.used_filters.indexOf("countries") != -1) {
                if (!filtered_products["country"]) filtered_products["country"] = {};

                for (var str in app.Data.data.filters.countries) {
                    var country = app.Data.data.filters.countries[str];

                    if (country.selected == true && baseproduct.countries.indexOf(str) != -1) {
                        filtered_products["country"][baseproduct.id] = baseproduct.id;
                    }
                }
            }

            //RANGES
            if (app.Data.data.config.filters.use_ranges == true && filters.ranges == true && app.Data.data.used_filters.indexOf("ranges") != -1) {
                if (!filtered_products["range"]) filtered_products["range"] = {};

                for (var str in app.Data.data.filters.ranges) {
                    var filter = app.Data.data.filters.ranges[str];
                    if (filter.selected == true) {
                        if (Object.keys(filter.sub).length > 0) {
                            var subfilter_selected = false;
                            for (var str2 in filter.sub) {
                                var subfilter = filter.sub[str2];
                                if (subfilter.selected == true) {
                                    subfilter_selected = true;
                                    var a = baseproduct.fo.length;
                                    for (var i = 0; i < a; i++) {
                                        if (baseproduct.fo[i].id == subfilter.id) {
                                            filtered_products["range"][baseproduct.id] = baseproduct.id;
                                        }
                                    }
                                }
                            }

                            //if no sub filters are selected, then we check the global filter
                            if (subfilter_selected == false) {
                                var a = baseproduct.ga.length;
                                for (var i = 0; i < a; i++) {
                                    if (baseproduct.ga[i].id == filter.id) {
                                        filtered_products["range"][baseproduct.id] = baseproduct.id;
                                    }
                                }
                            }
                        } else {
                            var a = baseproduct.ga.length;
                            for (var i = 0; i < a; i++) {
                                if (baseproduct.ga[i].id == filter.id) {
                                    filtered_products["range"][baseproduct.id] = baseproduct.id;
                                }
                            }
                        }
                    }
                }
            }

            //BRANDS
            if (app.Data.data.config.filters.use_brands == true && filters.brands == true && app.Data.data.used_filters.indexOf("brands") != -1) {
                if (!filtered_products["brands"]) filtered_products["brands"] = {};

                for (var str in app.Data.data.filters.brands) {
                    var filter = app.Data.data.filters.brands[str];

                    if (filter.selected == true && baseproduct.br.toLowerCase() == filter.id.toLowerCase()) {
                        filtered_products["brands"][baseproduct.id] = baseproduct.id;
                    }
                }
            }

            //DEVELOP READY
            if (app.Data.data.config.filters.use_develop_ready == true && app.Data.data.filters.develop_ready == true && app.Data.data.used_filters.indexOf("develop_ready") != -1) {
                if (!filtered_products["develop_ready"]) filtered_products["develop_ready"] = {};

                if (baseproduct.develop_ready == true) {
                    filtered_products["develop_ready"][baseproduct.id] = baseproduct.id;
                }
            }
            //DEVELOP READY NOT VISIBLE IF FILTER IS NOT SELECTED
            if (app.Data.data.config.filters.use_develop_ready == true && app.Data.data.filters.develop_ready == false) {
                if (baseproduct.develop_ready == true) {
                    removed_products[baseproduct.id] = {};
                }
            }

            //VG OBJECTS
            if (app.Data.data.config.filters.use_vg_objects == true && app.Data.data.filters.vg_objects == true && app.Data.data.used_filters.indexOf("vg_objects") != -1) {
                if (!filtered_products["vg_objects"]) filtered_products["vg_objects"] = {};

                if (baseproduct.vg_object == true) {
                    filtered_products["vg_objects"][baseproduct.id] = baseproduct.id;
                }
            }
            //VG OBJECTS NOT VISIBLE IF FILTER IS NOT SELECTED
            if (app.Data.data.config.filters.use_vg_objects == true && app.Data.data.filters.vg_objects == false) {
                if (baseproduct.vg_object == true) {
                    removed_products[baseproduct.id] = {};
                }
            }

            //VG
            var use_vg = false;
            if (app.Data.data.config.filters.use_vg == true && app.Data.data.filters.vg == true && app.Data.data.used_filters.indexOf("vg") != -1) {
                use_vg = true;
            }
            if (app.Data.data.platform == "VirtualGlass") {
                use_vg = true;
                app.Data.data.filters.vg = true;
            }

            if (use_vg == true) {
                if (!filtered_products["vg"]) filtered_products["vg"] = {};

                if (app.Data.data.platform == "VirtualGlass") {
                    if (app.Data.data.only_easyrender_products == true) {
                        if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1 || window.location.href.indexOf("apps.corp.inpkg.net") != -1) {
                            if (baseproduct.vg == true && baseproduct.easyrender == true && !filtered_products["vg"][baseproduct.id]) {
                                filtered_products["vg"][baseproduct.id] = baseproduct.id;
                            }
                        } else {
                            if (baseproduct.easyrender == true && !filtered_products["vg"][baseproduct.id]) {
                                filtered_products["vg"][baseproduct.id] = baseproduct.id;
                            }
                        }
                    } else {
                        //if(baseproduct.develop_ready == true) trace(baseproduct.la);
                        //if(baseproduct.vg == true && baseproduct.from_int_json == true){
                        if (baseproduct.vg == true && baseproduct.from_int_json == true) {
                            filtered_products["vg"][baseproduct.id] = baseproduct.id;
                        }

                        if (baseproduct.develop_ready == true || baseproduct.vg_object == true) filtered_products["vg"][baseproduct.id] = baseproduct.id;
                    }
                } else {
                    if (baseproduct.vg == true) {
                        filtered_products["vg"][baseproduct.id] = baseproduct.id;
                    }
                }
            }

            //TYPE
            if (app.Data.data.config.filters.use_type == true && app.Data.data.used_filters.indexOf("type") != -1) {
                if (app.Data.data.filters.type.standard == true || app.Data.data.filters.type.special == true) {
                    if (!filtered_products["type"]) filtered_products["type"] = {};

                    if (app.Data.data.filters.type.special == true && baseproduct.special == true) {
                        filtered_products["type"][baseproduct.id] = baseproduct.id;
                    }

                    if (app.Data.data.filters.type.standard == true && baseproduct.special == false) {
                        filtered_products["type"][baseproduct.id] = baseproduct.id;
                    }
                }
            }

            //VALUES RANGES
            var b = app.Data.data.config.filters.values_ranges.length;
            for (var j = 0; j < b; j++) {
                var value = app.Data.data.config.filters.values_ranges[j];

                if (app.Data.data.config.filters["use_" + value] == true && app.Data.data.used_filters.indexOf(value) != -1) {
                    if (!filtered_products[value]) filtered_products[value] = {};

                    var pdata;
                    if (value == "volume") pdata = parseInt(baseproduct.vo) / 10;else if (value == "height") pdata = parseInt(baseproduct.he);else if (value == "weight") pdata = parseInt(baseproduct.we);else if (value == "diameter") pdata = parseInt(baseproduct.di);

                    if (pdata >= app.Data.data.filters[value].selected_min && pdata <= app.Data.data.filters[value].selected_max) {
                        filtered_products[value][baseproduct.id] = baseproduct.id;
                    }
                }
            }

            //FINISH
            if (app.Data.data.config.filters.use_finish == true && filters.finish == true && app.Data.data.used_filters.indexOf("finish") != -1) {
                if (!filtered_products["finish"]) filtered_products["finish"] = {};

                for (var str in app.Data.data.filters.finish) {
                    var filter = app.Data.data.filters.finish[str];

                    if (filter.selected == true) {
                        var subfilter_selected = false;
                        for (var str2 in filter.sub) {
                            var subfilter = filter.sub[str2];
                            if (subfilter.selected == true) {
                                subfilter_selected = true;

                                if (baseproduct.fi.toLowerCase() == subfilter.id.toLowerCase()) {
                                    filtered_products["finish"][baseproduct.id] = baseproduct.id;
                                }
                            }
                        }

                        //if no sub filters are selected, then we check the global filter
                        if (subfilter_selected == false) {
                            for (var str2 in filter.sub) {
                                var subfilter = filter.sub[str2];
                                if (baseproduct.fi.toLowerCase() == subfilter.id.toLowerCase()) {
                                    filtered_products["finish"][baseproduct.id] = baseproduct.id;
                                }
                            }
                        }
                    }
                }
            }

            if (app.Data.data.platform == "ICTPLS") {
                //DECORATION
                if (app.Data.data.config.filters.use_pls_decoration == true && app.Data.data.filters.pls_decoration == true && app.Data.data.used_filters.indexOf("pls_decoration") != -1) {
                    if (!filtered_products["pls_decoration"]) filtered_products["pls_decoration"] = {};

                    if (baseproduct.pls.isDecorated == true) {
                        filtered_products["pls_decoration"][baseproduct.id] = baseproduct.id;
                    }
                }

                //YEARBOOK
                if (app.Data.data.config.filters.use_pls_yearbook == true && app.Data.data.filters.pls_yearbook == true && app.Data.data.used_filters.indexOf("pls_yearbook") != -1) {
                    if (!filtered_products["pls_yearbook"]) filtered_products["pls_yearbook"] = {};

                    if (baseproduct.pls.yearbook == true) {
                        filtered_products["pls_yearbook"][baseproduct.id] = baseproduct.id;
                    }
                }

                //COUNTRY PUBLICCATION
                if (app.Data.data.config.filters.use_pls_countrypublication == true && app.Data.data.used_filters.indexOf("pls_countrypublication") != -1) {
                    var passCP = false;
                    for (var str in app.Data.data.filters.pls_countrypublication) {
                        if (app.Data.data.filters.pls_countrypublication[str] == true) passCP = true;
                    }

                    if (passCP == true) {
                        if (!filtered_products["pls_countrypublication"]) filtered_products["pls_countrypublication"] = {};

                        if (baseproduct.pls.publicationChannels) {
                            passCP = false;
                            for (var str in app.Data.data.filters.pls_countrypublication) {
                                if (app.Data.data.filters.pls_countrypublication[str] == true) {
                                    if (baseproduct.pls.publicationChannels.indexOf(str) != -1) passCP = true;
                                }
                            }

                            if (passCP == true) filtered_products["pls_countrypublication"][baseproduct.id] = baseproduct.id;
                        }
                    }
                }

                //PLS DATES
                if (app.Data.data.config.filters.use_pls_dates == true && app.Data.data.used_filters.indexOf("pls_dates") != -1) {
                    if (app.Data.data.filters.pls_dates.selected_min != app.Data.data.filters.pls_dates.min || app.Data.data.filters.pls_dates.selected_max != app.Data.data.filters.pls_dates.max) {
                        if (!filtered_products["pls_dates"]) filtered_products["pls_dates"] = {};

                        var start_date = app.Data.data.filters.pls_dates.dates[app.Data.data.filters.pls_dates.selected_min];
                        var end_date = app.Data.data.filters.pls_dates.dates[app.Data.data.filters.pls_dates.selected_max];

                        if (start_date.date.getTime() <= baseproduct.pls.date.getTime() && end_date.date.getTime() >= baseproduct.pls.date.getTime()) {
                            filtered_products["pls_dates"][baseproduct.id] = baseproduct.id;
                        }
                    }
                }

                //PLS CREATION DATES
                if (app.Data.data.config.filters.use_pls_cdates == true && app.Data.data.used_filters.indexOf("pls_cdates") != -1) {
                    if (app.Data.data.filters.pls_cdates.selected_min != app.Data.data.filters.pls_cdates.min || app.Data.data.filters.pls_cdates.selected_max != app.Data.data.filters.pls_cdates.max) {
                        if (!filtered_products["pls_cdates"]) filtered_products["pls_cdates"] = {};

                        var start_date = app.Data.data.filters.pls_cdates.dates[app.Data.data.filters.pls_cdates.selected_min];
                        var end_date = app.Data.data.filters.pls_cdates.dates[app.Data.data.filters.pls_cdates.selected_max];

                        if (start_date.cdate.getTime() <= baseproduct.pls.cdate.getTime() && end_date.cdate.getTime() >= baseproduct.pls.cdate.getTime()) {
                            filtered_products["pls_cdates"][baseproduct.id] = baseproduct.id;
                        }
                    }
                }

                /*if(app.Data.data.config.filters.use_brands == true && filters.brands == true && app.Data.data.used_filters.indexOf("brands") != -1){
                    if(!filtered_products["brands"]) filtered_products["brands"] = {};
                      for(var str in app.Data.data.filters.brands){
                        var filter = app.Data.data.filters.brands[str];
                          if(filter.selected == true && baseproduct.br.toLowerCase() == filter.id.toLowerCase()){
                            filtered_products["brands"][baseproduct.id] = baseproduct.id;
                        }
                    }
                }*/

                //PLS VALUES
                var a = app.Data.data.config.filters.pls_values.length;
                for (var i = 0; i < a; i++) {
                    var value = app.Data.data.config.filters.pls_values[i];
                    if (app.Data.data.config.filters["use_pls_" + value] == true && app.Data.data.used_filters.indexOf("pls_" + value) != -1 && filters["pls_" + value] == true) {
                        if (!filtered_products["pls_" + value]) filtered_products["pls_" + value] = {};

                        var filter_uid;
                        if (value == "clients") filter_uid = verallia.StringUtils.makeURLString(baseproduct.pls.customerName);else if (value == "salesoffice") filter_uid = verallia.StringUtils.makeURLString(baseproduct.pls.salesOfficeDescription);else if (value == "decorators") filter_uid = verallia.StringUtils.makeURLString(baseproduct.pls.decoration);else if (value == "contents") filter_uid = verallia.StringUtils.makeURLString(baseproduct.pls.content);else if (value == "communication" && baseproduct.pls.communication) filter_uid = verallia.StringUtils.makeURLString(baseproduct.pls.communication);else if (value == "commercials") filter_uid = verallia.StringUtils.makeURLString(baseproduct.pls.commercial);
                        filter_uid = verallia.StringUtils.replace(filter_uid, "-", "");

                        if (!filter_uid || filter_uid == "") continue;

                        //trace(value + " : " + filter_uid)

                        //DECORATORS
                        if (value == "decorators" || value == "contents" || value == "communication") {
                            for (var str in app.Data.data.filters["pls_" + value]) {
                                var filter = app.Data.data.filters["pls_" + value][str];

                                if (filter.selected == true && filter_uid.toLowerCase() == filter.id.toLowerCase()) {
                                    filtered_products["pls_" + value][baseproduct.id] = baseproduct.id;
                                }
                            }
                        } else {
                            if (app.Data.data.filters["pls_" + value].selected.indexOf(filter_uid) != -1) {
                                filtered_products["pls_" + value][baseproduct.id] = baseproduct.id;
                            }
                        }

                        //if(app.Data.data.filters["pls_" + value][filter_uid] && app.Data.data.filters["pls_" + value][filter_uid].selected == true) filtered_products["pls_" + value][baseproduct.id] = baseproduct.id;
                    }
                }
            }

            var c = baseproduct.pr.length;
            for (var k = 0; k < c; k++) {
                var product = baseproduct.pr[k];

                //ACCOUNTS FOR MYVERALLIA
                if (app.Data.data.platform == "MyVerallia") {
                    if (app.Data.data.product_mode == "myproducts" && app.Data.data.config.filters.use_myverallia_groups == true && app.Data.data.used_filters.indexOf("myverallia_groups") != -1) {
                        if (!filtered_products["myverallia_groups"]) filtered_products["myverallia_groups"] = {};

                        if (baseproduct.myproduct == true) {
                            var d = product.fip.length;
                            for (var l = 0; l < d; l++) {
                                var e = product.fip[l].ac.length;
                                for (var m = 0; m < e; m++) {
                                    if (app.Data.data.filters.myverallia_groups.selected_account == "all") {

                                        //old version with groups in cwg
                                        /*var f = app.Data.data.filters.myverallia_groups.cwg.length;
                                        for(var n=0; n<f; n++){
                                            trace(product.fip[l].ac[m].id + " " + app.Data.data.filters.myverallia_groups.cwg[n].id);
                                            if(app.Data.data.filters.myverallia_groups.cwg[n].disabled == false && product.fip[l].ac[m].id == app.Data.data.filters.myverallia_groups.cwg[n].id){
                                                filtered_products["myverallia_groups"][baseproduct.id] = baseproduct.id;
                                            }
                                        }*/

                                        //new version with cu in gr
                                        var z = app.Data.data.filters.myverallia_groups.gr.length;
                                        for (var y = 0; y < z; y++) {
                                            if (app.Data.data.filters.myverallia_groups.gr[y].cu) {
                                                var f = app.Data.data.filters.myverallia_groups.gr[y].cu.length;
                                                for (var n = 0; n < f; n++) {
                                                    if (product.fip[l].ac[m].id == app.Data.data.filters.myverallia_groups.gr[y].cu[n].id) {
                                                        filtered_products["myverallia_groups"][baseproduct.id] = baseproduct.id;
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        if (product.fip[l].ac[m].id == app.Data.data.filters.myverallia_groups.selected_account) {
                                            filtered_products["myverallia_groups"][baseproduct.id] = baseproduct.id;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                //SL
                if (app.Data.data.config.filters.use_sl == true && app.Data.data.filters.sl == true && app.Data.data.used_filters.indexOf("sl") != -1) {
                    if (!filtered_products["sl"]) filtered_products["sl"] = {};

                    if (product.mg == "NAAC") {
                        filtered_products["sl"][baseproduct.id] = baseproduct.id;
                        baseproduct.index = k;
                    }
                }

                //PREMIUM
                if (app.Data.data.config.filters.use_premium == true && app.Data.data.filters.premium == true && app.Data.data.used_filters.indexOf("premium") != -1) {
                    if (!filtered_products["premium"]) filtered_products["premium"] = {};

                    if (product.premium == true) {
                        filtered_products["premium"][baseproduct.id] = baseproduct.id;
                        baseproduct.index = k;
                    }
                }

                //COLORS
                if (app.Data.data.config.filters.use_colors == true && filters.colors == true && app.Data.data.used_filters.indexOf("colors") != -1) {
                    if (!filtered_products["color"]) filtered_products["color"] = {};

                    for (var str in app.Data.data.filters.colors) {
                        var filter = app.Data.data.filters.colors[str];

                        if (filter.selected == true) {
                            var subfilter_selected = false;
                            var a = 0;
                            for (var str2 in filter.sub) {
                                var subfilter = filter.sub[str2];

                                if (subfilter.selected == true) {
                                    subfilter_selected = true;
                                    a++;

                                    if (product.cs == subfilter.cs) {
                                        filtered_products["color"][baseproduct.id] = baseproduct.id;
                                        baseproduct.index = k;
                                    }
                                }
                            }

                            //if no sub filters are selected, then we check the global filter
                            if (subfilter_selected == false) {
                                var a = 0;
                                for (var str2 in filter.sub) {
                                    var subfilter = filter.sub[str2];
                                    a++;

                                    if (product.cs == subfilter.cs) {
                                        filtered_products["color"][baseproduct.id] = baseproduct.id;
                                        baseproduct.index = k;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //trace(filtered_products);

            var a = Object.keys(filtered_products).length;
            var b = 0;
            for (var str in filtered_products) {
                if (filtered_products[str][baseproduct.id]) b++;
            }

            if (b == a) {
                /*if(app.Data.data.platform == "VirtualGlass"){
                    if(app.Data.data.only_easyrender_products == false){
                        //if(baseproduct.easyrender == false){
                        if(baseproduct.vg == true && baseproduct.from_int_json == true){
                            if(!app.Data.data.filtered_baseproducts[baseproduct.id]){
                                app.Data.data.filtered_baseproducts[baseproduct.id] = baseproduct.id;
                                app.Data.data.filtered_baseproducts_array.push(baseproduct);
                            }
                        }
                    }else{
                        //trace(baseproduct.gd + " " + baseproduct.easyrender);
                        if(baseproduct.easyrender == true){
                            if(!app.Data.data.filtered_baseproducts[baseproduct.id]){
                                app.Data.data.filtered_baseproducts[baseproduct.id] = baseproduct.id;
                                app.Data.data.filtered_baseproducts_array.push(baseproduct);
                            }
                        }
                    }
                }else{*/
                if (!app.Data.data.filtered_baseproducts[baseproduct.id] && !removed_products[baseproduct.id]) {
                    app.Data.data.filtered_baseproducts[baseproduct.id] = baseproduct.id;
                    app.Data.data.filtered_baseproducts_array.push(baseproduct);
                }
                //}
            }
        }

        if (app.Data.data.config.filters.use_order_filters == true) this.applyFilterOrder(app.Data.data.products_order);

        $("#ct_products_list").nanoScroller({ scroll: "top" });
        setTimeout(this.resize.bind(this), 200);
        this.resize();
    },
    applyFilterOrder: function applyFilterOrder(key) {
        if (!key) return;

        app.Data.data.products_order = key;
        verallia.LocalStorageUtils.setKey("ct_products_order" + app.Data.data.platform, key);

        var values = [];
        values.push(app.Data.data.loc.filters.orders[key].value1);
        values.push(app.Data.data.loc.filters.orders[key].value2);

        var normal_order = true;
        if (app.Data.data.loc.filters.orders[key].decrease == true) normal_order = false;
        app.Data.data.filtered_baseproducts_array.sort(verallia.ArrayUtils.sortArray([values[0], values[1]], normal_order));

        app.Data.data.product_page = 1;
        $("#ct_products_list").nanoScroller({ scroll: "top" });
    },
    resize: function resize() {
        var first_element_width = $($(".CTProductCard")[0]).width();
        var w = $("#ct_products_list_container").width();
        var nb = Math.floor(w / first_element_width);

        var nb_lines = Math.floor(app.Data.data.filtered_baseproducts_array.length / nb);
        var nb_last_line = app.Data.data.filtered_baseproducts_array.length - nb_lines * nb;
        var nb_missing = nb - nb_last_line;

        if (nb_last_line > 0 && nb_missing > 0) {
            app.Data.data.phantom_product_cards = nb_missing;
        } else {
            app.Data.data.phantom_product_cards = 0;
        }
    }
};