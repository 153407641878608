"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.SSO = function () {
    function _class(logged_in, logged_out) {
        _classCallCheck(this, _class);

        this.callbacks = { logged_in: logged_in, logged_out: logged_out };
        this.sso_app;
        this.sso_data;
        this.html_templates = {};

        var SSO_config = new verallia.SSOConfig();

        if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1) window.SSO_CONFIG = { SSO: SSO_config.DEV };else if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1) window.SSO_CONFIG = { SSO: SSO_config.QUAL };else if (window.location.href.indexOf("apps.corp.inpkg.net") != -1) window.SSO_CONFIG = { SSO: SSO_config.PROD };

        $(window).on("SSO_LOGIN", this.SSOLogin.bind(this));
        $(window).on("SSO_LOGOUT", this.SSOLogout.bind(this));

        this.init();
    }

    _createClass(_class, [{
        key: "init",
        value: function init() {
            verallia.FileLoader.addFile("sso_loc", "static/assets/json/loc/sso.json");
            verallia.FileLoader.addFile("sso_template", "static/assets/html/sso.html");

            verallia.FileLoader.load(this.initVue.bind(this), this.filesError.bind(this));
        }
    }, {
        key: "filesError",
        value: function filesError() {
            trace("error loading sso files !");
        }
    }, {
        key: "initVue",
        value: function initVue() {
            this.sso_data = { components: {} };

            // MAIN APP DATA
            this.sso_data.main_vue = new app.MainSSOVue();

            this.sso_data.main_vue_data = {
                data: this.sso_data.main_vue.getData,
                methods: this.sso_data.main_vue

                // COMPONENTS
            };this.components = [];
            this.components.push({ id: "sso", class: app.SSOComponent, props: [], watch: [] });

            var a = this.components.length;
            for (var i = 0; i < a; i++) {
                this.html_templates[this.components[i].id] = verallia.FileLoader.get(this.components[i].id + "_template");
                var c = this.components[i].class;
                this.sso_data["cp_" + this.components[i].id] = c;

                var watch = {};
                var b = this.components[i].watch.length;
                for (var j = 0; j < b; j++) {
                    watch[this.components[i].watch[i].var] = this.sso_data["cp_" + this.components[i].id][this.components[i].watch[i].callback];
                }

                var app_data = {
                    data: this.sso_data["cp_" + this.components[i].id].getData,
                    methods: this.sso_data["cp_" + this.components[i].id]
                };

                this.sso_data.components[this.components[i].id] = Vue.extend({
                    mixins: [this.sso_data.main_vue_data],
                    props: this.components[i].props,
                    template: this.html_templates[this.components[i].id],
                    data: app_data.data,
                    methods: app_data.methods,
                    mounted: this.sso_data["cp_" + this.components[i].id].mounted,
                    beforeUpdate: this.sso_data["cp_" + this.components[i].id].update,
                    watch: watch
                });
                Vue.component("cp-" + [this.components[i].id], this.sso_data.components[this.components[i].id]);
            }

            //VUE
            Vue.use(Vuetify); //use vuetify

            this.sso_app = new Vue({
                el: '#sso_app',
                data: this.sso_data.main_vue_data.data,
                methods: this.sso_data.main_vue_data.methods
            });
        }
    }, {
        key: "SSOLogin",
        value: function SSOLogin() {
            $("#sso_app").remove();
            setTimeout(this.callbacks.logged_in.bind(this), 500);
        }
    }, {
        key: "SSOLogout",
        value: function SSOLogout() {
            $("#sso_app").show();
            this.callbacks.logged_out();
        }
    }]);

    return _class;
}();