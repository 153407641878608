"use strict";

app.FinishedProductsNew = {
    mounted: function mounted() {
        $(window).off("UPDATE_PRODUCT_PALETTES_DATA").on("UPDATE_PRODUCT_PALETTES_DATA", this.updatePalettesData.bind(this));
        $(window).on(verallia.Event.RESIZE, this.resize.bind(this));
        this.resize();
        setTimeout(this.resize.bind(this), 200);
    },
    update: function update() {
        //this.product.selected_line = 0;
    },
    getData: function getData() {
        return {
            cart_position: "right",
            offsetX: 0,
            offsetY: 0,
            palettes_check: false,
            palettes_data: null
        };
    },
    dispose: function dispose() {
        $(window).off("UPDATE_PRODUCT_PALETTES_DATA", this.updatePalettesData.bind(this));
        //$(window).off("resize", this.resize.bind(this));
    },
    updatePalettesData: function updatePalettesData() {
        setTimeout(this.resize.bind(this), 200);
    },
    resize: function resize() {
        if (this.product.fip.length <= 0) return;
    },
    selectLine: function selectLine(index) {
        this.product.selected_line = index;
        this.$forceUpdate();

        verallia.Core.getObject("app").updateHash();
    },
    openPlan: function openPlan(index) {
        var fip = this.product.fip[index];
        //trace(this.product);
        //trace(fip);

        var url = "https://digital-api.corp.inpkg.net/api/sap-documents/palletization-forms/" + fip.id + "?channel=" + this.product.pc + "&salesOrg=" + this.product.so;
        //var url = "/apex/Web_ViewDocumentFile?distribChannel=" + this.product.pc + "&objType=PALLET&objNum=" + fip.id + "&salesorg=" + this.product.so;
        verallia.WindowUtils.openURL(url, "_blank");
    }
};