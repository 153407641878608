"use strict";

app.FinishedProductsStocks = {
    mounted: function mounted() {
        $(window).off("UPDATE_FINISHED_PRODUCTS_STOCK_DATA").on("UPDATE_FINISHED_PRODUCTS_STOCK_DATA", this.updateStocksData.bind(this));
    },
    update: function update() {},
    getData: function getData() {
        return {
            country: null,
            countries: [],
            waiting_stock: true,
            stock_data: null
        };
    },
    dispose: function dispose() {
        $(window).off("UPDATE_FINISHED_PRODUCTS_STOCK_DATA", this.updateStocksData.bind(this));
    },
    updateStocksData: function updateStocksData() {
        if (app.Data.data.baseproduct.pr[app.Data.data.baseproduct.selected_index].mg.toLowerCase() != "naac") return;

        if (!this.baseproduct.fip) this.baseproduct.fip = [];

        var a = this.baseproduct.fip.length;
        for (var i = 0; i < a; i++) {
            var fp = this.baseproduct.fip[i];
            fp.stock = null;
        }

        this.country = "all";
        this.countries = ["all"];
        this.waiting_stock = true;

        //var url = "http://talend.corp.inpkg.net:8040/services/extranet/products?finishedProducts=";
        var url = app.Data.data.config.finished_products_stock_url;

        var a = this.baseproduct.fip.length;
        for (var i = 0; i < a; i++) {
            if (this.baseproduct.fip[i].display == true) {
                if (i < a - 1) url += this.baseproduct.fip[i].id + ",";else url += this.baseproduct.fip[i].id;
            }
        }
        url += "&lang=" + app.Data.data.config.language;

        $.ajax({ method: "GET", url: url, complete: this.setStock.bind(this) });
    },
    setStock: function setStock(data) {
        try {
            data = $(data.responseText);
        } catch (e) {
            trace("stock data failed");
            return;
        }

        this.stock_data = {};

        var t = this;

        var a = t.baseproduct.fip.length;
        for (var i = 0; i < a; i++) {
            var fp = t.baseproduct.fip[i];

            var status = true;
            //if(fp.status && fp.status.toLowerCase() == "z3") status = false;

            if (fp.materialGroup.toLowerCase() != "naad" && status == true) {
                fp.stock = {};

                $(data[2]).find("EItemFp").find("item").each(function (index) {
                    var code = $(this).find('ProductCode').text();

                    var company_good = true;
                    var company = $(this).find('Company').text();
                    if (company && company != "") {
                        if (company == "S09" || company == "S08") company_good = false;
                    }

                    var plantcode = $(this).find('PlantCode').text();
                    if (plantcode && plantcode != "") {
                        if (plantcode == "D830" || plantcode == "D500" || plantcode == "D700") company_good = false;
                    }

                    if (code.indexOf(t.baseproduct.fip[i].id) != -1 && company_good == true) {
                        var stock = {};

                        stock.BaseUnit = $(this).find('BaseUnit').text();
                        stock.ProductCode = $(this).find('ProductCode').text();
                        stock.ProductDescription = $(this).find('ProductDescription').text();
                        stock.Country = $(this).find('Country').text();
                        stock.PlantCode = $(this).find('PlantCode').text();
                        stock.PlantText = $(this).find('PlantText').text();
                        stock.FreeStock = $(this).find('FreeStock').text();
                        //fp.stock.FreeStock = Math.round(Math.random() * 200000);
                        stock.BlockedStock = $(this).find('BlockedStock').text();
                        stock.PartsVendorStock = $(this).find('PartsVendorStock').text();
                        stock.OrdersOnhandStock = $(this).find('OrdersOnhandStock').text();
                        stock.SalesReqQty = $(this).find('SalesReqQty').text();
                        //fp.stock.SalesReqQty = Math.round(Math.random() * 50000);
                        stock.ProdDateFrom = $(this).find('ProdDateFrom').text();
                        if (stock.ProdDateFrom == "0000-00-00") stock.ProdDateFrom = "";
                        stock.ProdDateTo = $(this).find('ProdDateTo').text();
                        stock.ProdTotalQty = $(this).find('ProdTotalQty').text();
                        stock.ProdRemainQty = $(this).find('ProdRemainQty').text();
                        stock.StckInTransit = $(this).find('StckInTransit').text();
                        stock.StckInTransitUsed = 0;

                        //try{
                        stock.StckInTransitUb = $(this).find('StckInTransitUb').text();
                        stock.StckInTransitEb = $(this).find('StckInTransitEb').text();
                        //}catch(e){
                        //stock.StckInTransitUb = "none";
                        //stock.StckInTransitEb = "none";
                        //}

                        stock.quantity = Number(stock.FreeStock) - Number(stock.SalesReqQty);

                        if (stock.StckInTransitUb < 0) {
                            stock.quantity += Number(stock.StckInTransitUb);
                        }
                        if (stock.StckInTransitEb < 0) {
                            stock.quantity += Number(stock.StckInTransitEb);
                        }

                        if (stock.StckInTransitUb > 0) {
                            stock.StckInTransitUsed += Number(stock.StckInTransitUb);
                        }
                        if (stock.StckInTransitEb > 0) {
                            stock.StckInTransitUsed += Number(stock.StckInTransitEb);
                        }

                        stock.quantity += Number(stock.StckInTransitUsed);

                        if (stock.quantity != 0 || Number(stock.ProdRemainQty) > 0) {
                            fp.stock[code + stock.PlantCode] = stock;

                            if (!t.stock_data[t.baseproduct.fip[i].id]) t.stock_data[t.baseproduct.fip[i].id] = { fip: {}, quantity: 0 };
                            t.stock_data[t.baseproduct.fip[i].id].fip[code + stock.PlantCode] = stock;
                            //trace("adding : " + stock.ProductCode + " : " + stock.Country);

                            if (t.countries.indexOf(stock.Country.toLowerCase()) == -1) {
                                //trace(fp.stock.Country.toLowerCase());
                                t.countries.push(stock.Country.toLowerCase());
                            }
                        }
                    }
                });
            }
        }

        this.waiting_stock = false;
        this.$forceUpdate();

        setTimeout(this.updateScrollBar.bind(this), 200);
    },
    updateScrollBar: function updateScrollBar() {
        $("#ct_palettes .nano").nanoScroller({ alwaysVisible: true });
    },
    setCountry: function setCountry(country) {
        this.country = country;
    },
    checkVisibility: function checkVisibility(stock) {
        if (this.country == "all") return true;

        if (this.country == stock.Country.toLowerCase()) return true;

        return false;
    },
    getCountry: function getCountry(country) {
        if (country == "all") {
            return app.Data.data.loc.interface.all;
        } else if (country == "us") {
            return "USA";
        } else {
            if (!app.Data.data.loc.global.countries[country]) return country.toUpperCase();
            return app.Data.data.loc.global.countries[country].label;
        }
    },
    getTotal: function getTotal() {
        var total = 0;

        var a = this.baseproduct.fip.length;
        for (var i = 0; i < a; i++) {
            var fp = this.baseproduct.fip[i];

            for (var str in fp.stock) {
                var stock = fp.stock[str];

                if (stock) {
                    if (this.country == "all") {
                        total += stock.quantity;
                    } else if (stock.Country.toLowerCase() == this.country) {
                        total += stock.quantity;
                    }
                }
            }
        }

        return total;
    },
    getTotalFromCode: function getTotalFromCode(code) {
        var total = 0;

        var a = this.baseproduct.fip.length;
        for (var i = 0; i < a; i++) {
            var fp = this.baseproduct.fip[i];

            for (var str in fp.stock) {
                var stock = fp.stock[str];

                if (stock && stock.ProductCode.indexOf(code) != -1) {
                    if (this.country == "all") {
                        total += stock.quantity;
                    } else if (stock.Country.toLowerCase() == this.country) {
                        total += stock.quantity;
                    }
                }
            }
        }

        return total;
    },
    checkBlockVisibility: function checkBlockVisibility(code) {
        var total = 0;

        var a = this.baseproduct.fip.length;
        for (var i = 0; i < a; i++) {
            var fp = this.baseproduct.fip[i];

            for (var str in fp.stock) {
                var stock = fp.stock[str];

                if (stock && stock.ProductCode.indexOf(code) != -1) {
                    if (this.country == "all") {
                        total++;
                    } else if (stock.Country.toLowerCase() == this.country) {
                        total++;
                    }
                }
            }
        }

        if (total == 0) return false;else return true;
    }
};