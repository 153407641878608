"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.InitApp = function () {
    function _class(callback) {
        _classCallCheck(this, _class);

        this.components;
        this.callback = callback;

        this.init();
    }

    _createClass(_class, [{
        key: "init",
        value: function init() {
            var configurl = "static/assets/json/config.json";

            try {
                configurl = catalog_files_path + "/static/assets/json/config.json";
            } catch (e) {
                configurl = "static/assets/json/config.json";
            }

            verallia.FileLoader.addFile("ct_config", configurl);
            verallia.FileLoader.load(this.checkURLData.bind(this), this.filesError.bind(this));
        }
    }, {
        key: "checkURLData",
        value: function checkURLData() {
            app.Data.data.config = verallia.FileLoader.get("ct_config");

            var url = window.location.hash;
            url = url.replace("#/", "");
            url = url.replace("#", "");
            url = verallia.StringUtils.decodeEntities(url);

            try {
                var hash = JSON.parse(url);
                if (hash.platform) app.Data.data.platform = hash.platform;
            } catch (e) {}

            trace("");
            trace("PLATFORM : " + app.Data.data.platform);
            trace("");

            if (app.Data.data.platform == "MyVerallia") {
                if (window.location.href.indexOf("vaqual") != -1) app.Data.data.MVEnv = "qual";

                try {
                    var test_mv_user_contactid = mv_user_contactid.toUpperCase();
                    console.log(test_mv_user_contactid);
                } catch (e) {
                    trace("no mv_user_contactid detected");
                    window.mv_user_contactid = "testUser";
                }
            }

            if (app.Data.data.platform == "VeralliaCom") {
                try {
                    var url_sup_tab = url.split("&");
                    var url_tab = url_sup_tab[0].split("/");
                    var config_tab = url_tab[0].split("-");

                    selected_country = config_tab[0];
                    selected_language = config_tab[1];
                } catch (e) {
                    selected_country = "fr";
                    selected_language = "fr";
                }
            }

            if (app.Data.data.config.platforms.indexOf(app.Data.data.platform) == -1) app.Data.data.platform = "ICT";

            if (url.indexOf("plsproduct_") != -1 || url.indexOf("ictproduct_") != -1) {
                if (url.indexOf("plsproduct_") != -1) app.Data.data.platform = "ICTPLS";else if (url.indexOf("ictproduct_") != -1) app.Data.data.platform = "ICT";
            }

            if (window.location.href.indexOf("localhost") != -1 || app.Data.data.mode == "app") {
                app.Data.data.config.assets_path = "static/assets/";
                app.Data.data.config.distant_assets_path = "static/assets/";
                app.Data.data.config.virtualglass_url = "http://localhost/Verallia/VirtualGlass/dist/";
                app.Data.data.config.data_directory = "app_data/";
                app.Data.data.config.data_directory_server_url = "http://localhost/Verallia/Catalog/dist/";
                app.Data.data.config.meteor_vg_service_path = "https://meteordev.corp.inpkg.net/api/assets/virtualglass/";
                app.Data.data.config.pls_suite_edit_url = "https://appsdev.corp.inpkg.net/suite/#sgeProductLaunchSheet-display&/sheet/";
                app.Data.data.config.products_docs_directory = app.Data.data.config.veralliacom_products_docs_directory;
            } else if (app.Data.data.platform == "VeralliaCom") {
                app.Data.data.config.assets_path = "static/assets/";
                app.Data.data.config.distant_assets_path = "static/assets/";
                app.Data.data.config.virtualglass_url = "http://localhost/Verallia/VirtualGlass/dist/";
                app.Data.data.config.data_directory = "app_data/";
                app.Data.data.config.data_directory_server_url = "http://localhost/Verallia/Catalog/dist/";
                app.Data.data.config.meteor_vg_service_path = "https://meteordev.corp.inpkg.net/api/assets/virtualglass/";
                app.Data.data.config.pls_suite_edit_url = "https://appsdev.corp.inpkg.net/suite/#sgeProductLaunchSheet-display&/sheet/";
                app.Data.data.config.products_docs_directory = app.Data.data.config.veralliacom_products_docs_directory;
            } else if (app.Data.data.platform == "MyVerallia") {
                app.Data.data.config.assets_path = catalog_files_path + "/static/assets/";
                app.Data.data.config.distant_assets_path = "static/assets/";
                app.Data.data.config.virtualglass_url = "https://" + location.host + location.pathname.toLowerCase().replace("/web_catalogvg", "/Web_VirtualGlassViewer");
                app.Data.data.config.data_directory = "app_data/";
                app.Data.data.config.products_docs_directory = app.Data.data.config.veralliacom_products_docs_directory;
                app.Data.data.config.products_docs_sharepoint_file_api = "https://eservices-aws.verallia.com/digital-api/api/ms-graph-api/file?path=TechnicalDrawings/";

                if (app.Data.data.MVEnv == "qual") {
                    app.Data.data.config.data_directory_server_url = "https://appsqual.corp.inpkg.net/catalog/";
                    //app.Data.data.config.meteor_static_path = "https://meteorqual.corp.inpkg.net/api/static-data/";
                    app.Data.data.config.meteor_static_path = "https://eservices-aws-qual.verallia.com/digital-api/api/static-data/";
                    app.Data.data.config.meteor_vg_service_path = "https://meteorqual.corp.inpkg.net/api/assets/virtualglass/";
                } else {
                    app.Data.data.config.data_directory_server_url = "https://apps.corp.inpkg.net/catalog/";
                    //app.Data.data.config.meteor_static_path = "https://meteor.corp.inpkg.net/api/static-data/";
                    app.Data.data.config.meteor_static_path = "https://eservices-aws.verallia.com/digital-api/api/static-data/";
                    app.Data.data.config.meteor_vg_service_path = "https://meteor.corp.inpkg.net/api/assets/virtualglass/";
                }
            } else if (window.location.href.indexOf("mobile.corp.inpkg.net") != -1) {
                app.Data.data.config.assets_path = "static/assets/";
                app.Data.data.config.distant_assets_path = "static/assets/";
                app.Data.data.config.virtualglass_url = "https://mobile.corp.inpkg.net/virtualglass/";
                app.Data.data.config.data_directory = "app_data/";
                app.Data.data.config.data_directory_server_url = "https://mobile.corp.inpkg.net/catalog/";
            } else if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1) {
                app.Data.data.config.assets_path = "static/assets/";
                app.Data.data.config.distant_assets_path = "https://appsdev.corp.inpkg.net/public/catalog/";
                app.Data.data.config.competitors_products_images_directory = "https://appsdev.corp.inpkg.net/public/catalog/images/products/";
                app.Data.data.config.competitors_products_docs_directory = "https://appsdev.corp.inpkg.net/public/catalog/glassdrawings/";
                app.Data.data.config.virtualglass_url = "https://appsdev.corp.inpkg.net/virtualglass/";
                app.Data.data.config.data_directory = "app_data/";
                app.Data.data.config.data_directory_server_url = "https://appsdev.corp.inpkg.net/catalog/";
                app.Data.data.config.meteor_static_path = "https://meteordev.corp.inpkg.net/api/static-data/";
                app.Data.data.config.meteor_vg_service_path = "https://meteordev.corp.inpkg.net/api/assets/virtualglass/";
                app.Data.data.config.pls_suite_edit_url = "https://appsdev.corp.inpkg.net/suite/#sgeProductLaunchSheet-display&/sheet/";
                app.Data.data.config.finished_products_stock_url = "https://ws.corp.inpkg.net:8011/services/extranet/products?finishedProducts=";
            } else if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1) {
                app.Data.data.config.assets_path = "static/assets/";
                app.Data.data.config.distant_assets_path = "https://appsqual.corp.inpkg.net/public/catalog/";
                app.Data.data.config.competitors_products_images_directory = "https://appsqual.corp.inpkg.net/public/catalog/images/products/";
                app.Data.data.config.competitors_products_docs_directory = "https://appsqual.corp.inpkg.net/public/catalog/glassdrawings/";
                app.Data.data.config.virtualglass_url = "https://appsqual.corp.inpkg.net/virtualglass/";
                app.Data.data.config.data_directory = "app_data/";
                app.Data.data.config.data_directory_server_url = "https://appsqual.corp.inpkg.net/catalog/";
                //app.Data.data.config.meteor_static_path = "https://meteorqual.corp.inpkg.net/api/static-data/";
                app.Data.data.config.meteor_static_path = "https://eservices-aws-qual.verallia.com/digital-api/api/static-data/";
                app.Data.data.config.meteor_vg_service_path = "https://meteorqual.corp.inpkg.net/api/assets/virtualglass/";
                app.Data.data.config.pls_suite_edit_url = "https://appsqual.corp.inpkg.net/suite/#sgeProductLaunchSheet-display&/sheet/";
                app.Data.data.config.finished_products_stock_url = "https://wsqual.corp.inpkg.net:8011/services/extranet/products?finishedProducts=";
            } else if (window.location.href.indexOf("apps.corp.inpkg.net") != -1) {
                app.Data.data.config.assets_path = "static/assets/";
                app.Data.data.config.distant_assets_path = "https://apps.corp.inpkg.net/public/catalog/";
                app.Data.data.config.competitors_products_images_directory = "https://apps.corp.inpkg.net/public/catalog/images/products/";
                app.Data.data.config.competitors_products_docs_directory = "https://apps.corp.inpkg.net/public/catalog/glassdrawings/";
                app.Data.data.config.virtualglass_url = "https://apps.corp.inpkg.net/virtualglass/";
                app.Data.data.config.data_directory = "app_data/";
                app.Data.data.config.data_directory_server_url = "https://apps.corp.inpkg.net/catalog/";
                //app.Data.data.config.meteor_static_path = "https://meteor.corp.inpkg.net/api/static-data/";
                app.Data.data.config.meteor_static_path = "https://eservices-aws.verallia.com/digital-api/api/static-data/";
                app.Data.data.config.meteor_vg_service_path = "https://meteor.corp.inpkg.net/api/assets/virtualglass/";
                app.Data.data.config.pls_suite_edit_url = "https://apps.corp.inpkg.net/suite/#sgeProductLaunchSheet-display&/sheet/";
                app.Data.data.config.finished_products_stock_url = "https://ws.corp.inpkg.net:8011/services/extranet/products?finishedProducts=";
            }

            this.loadFiles();
        }
    }, {
        key: "loadFiles",
        value: function loadFiles() {
            var a = app.Data.data.config.languages.length;
            for (var i = 0; i < a; i++) {
                verallia.FileLoader.addFile("loc_" + app.Data.data.config.languages[i], app.Data.data.config.assets_path + "json/loc/" + app.Data.data.config.languages[i] + ".json");
            }

            verallia.FileLoader.addFile("app_template", app.Data.data.config.assets_path + "html/app.html");
            verallia.FileLoader.addFile("global-overlay_template", app.Data.data.config.assets_path + "html/global_overlay.html");
            verallia.FileLoader.addFile("dialog_template", app.Data.data.config.assets_path + "html/dialog.html");
            verallia.FileLoader.addFile("burger_template", app.Data.data.config.assets_path + "html/burger.html");
            verallia.FileLoader.addFile("header_template", app.Data.data.config.assets_path + "html/header.html");
            verallia.FileLoader.addFile("filters_template", app.Data.data.config.assets_path + "html/filters.html");
            verallia.FileLoader.addFile("filter-values-ranges_template", app.Data.data.config.assets_path + "html/filter_values_ranges.html");
            verallia.FileLoader.addFile("products_template", app.Data.data.config.assets_path + "html/products.html");
            verallia.FileLoader.addFile("product-card_template", app.Data.data.config.assets_path + "html/product_card.html");
            verallia.FileLoader.addFile("product_template", app.Data.data.config.assets_path + "html/product.html");
            verallia.FileLoader.addFile("productpls_template", app.Data.data.config.assets_path + "html/productpls.html");
            verallia.FileLoader.addFile("finished-products-new_template", app.Data.data.config.assets_path + "html/finished_products_new.html");
            verallia.FileLoader.addFile("finished-products_template", app.Data.data.config.assets_path + "html/finished_products.html");
            verallia.FileLoader.addFile("finished-products-stocks_template", app.Data.data.config.assets_path + "html/finished_products_stocks.html");
            verallia.FileLoader.addFile("comparator_template", app.Data.data.config.assets_path + "html/comparator.html");
            verallia.FileLoader.addFile("samples_template", app.Data.data.config.assets_path + "html/samples.html");
            verallia.FileLoader.addFile("search_template", app.Data.data.config.assets_path + "html/search.html");
            verallia.FileLoader.load(this.filesLoaded.bind(this), this.filesError.bind(this));
        }
    }, {
        key: "filesError",
        value: function filesError() {
            trace("error loading catalog files !");
        }
    }, {
        key: "filesLoaded",
        value: function filesLoaded() {
            this.initLoc();
            this.initStorageData();
            this.init_data = new app.InitInternational(this.initApp.bind(this));
        }
    }, {
        key: "initApp",
        value: function initApp() {
            this.initVue();
            this.callback();
        }
    }, {
        key: "initLoc",
        value: function initLoc() {
            app.Data.data.global_loc = {};
            var a = app.Data.data.config.languages.length;
            for (var i = 0; i < a; i++) {
                app.Data.data.global_loc[app.Data.data.config.languages[i]] = verallia.FileLoader.get("loc_" + app.Data.data.config.languages[i]);
            }

            app.Data.data.config.language = "fr";
            app.Data.data.config.country = "fr";
            var saved_language = verallia.LocalStorageUtils.getKey("ct_language");

            if (app.Data.data.platform == "VeralliaCom") {
                if (!selected_language) selected_language = "fr";
                if (!selected_country) selected_country = "fr";

                saved_language = selected_language;
                app.Data.data.config.set_language = app.Data.data.config.countries_languages[selected_country];

                app.Data.data.config.country = selected_country.toUpperCase();
            } else if (app.Data.data.platform == "MyVerallia") {
                try {
                    app.Data.data.config.language = ct_myverallia_selected_Language.toLowerCase();
                    app.Data.data.config.country = ct_myverallia_selected_country.toUpperCase();
                    app.Data.data.config.myverallia_countries = JSON.parse(ct_myverallia_countries).co;
                } catch (e) {
                    app.Data.data.config.language = "fr";
                    app.Data.data.config.country = "FR";
                    app.Data.data.config.myverallia_countries = [{ co: "FR", lan: "fr", na: "France" }, { co: "SP", lan: "es", na: "Spain" }];
                }
            }

            if (saved_language && app.Data.data.config.languages[saved_language] != -1) {
                app.Data.data.config.language = saved_language;
            }

            app.Data.data.loc = app.Data.data.global_loc[app.Data.data.config.language];
        }
    }, {
        key: "initStorageData",
        value: function initStorageData() {
            try {
                app.Data.data.comparator_products = JSON.parse(verallia.LocalStorageUtils.getKey("ct_comparator" + app.Data.data.platform));
            } catch (e) {
                app.Data.data.comparator_products = {};
            }

            try {
                app.Data.data.samples_products = JSON.parse(verallia.LocalStorageUtils.getKey("ct_samples" + app.Data.data.platform));
            } catch (e) {
                app.Data.data.samples_products = {};
            }

            try {
                app.Data.data.products_order = verallia.LocalStorageUtils.getKey("ct_products_order" + app.Data.data.platform);
                if (!app.Data.data.products_order) app.Data.data.products_order = app.Data.data.config.filters.default_order;

                if (app.Data.data.loc.filters.orders[app.Data.data.products_order].platform && app.Data.data.loc.filters.orders[app.Data.data.products_order].platform != app.Data.data.platform) {
                    app.Data.data.products_order = app.Data.data.config.filters.default_order;
                }
            } catch (e) {
                app.Data.data.products_order = app.Data.data.config.filters.default_order;
            }

            if (app.Data.data.platform == "ICTPLS") app.Data.data.products_order = "date_decrease";

            try {
                var used_filters = verallia.LocalStorageUtils.getKey("ct_filters");
                app.Data.data.used_filters = JSON.parse(used_filters);
                if (!app.Data.data.used_filters) app.Data.data.used_filters = app.Data.data.config.filters.default_filters;
            } catch (e) {
                app.Data.data.used_filters = app.Data.data.config.filters.default_filters;
            }
        }
    }, {
        key: "initVue",
        value: function initVue() {
            app.Data.vue_data = { components: {} };

            // MAIN APP DATA
            app.Data.vue_data.main_vue = new app.MainVue();

            app.Data.vue_data.main_vue_data = {
                data: app.Data.vue_data.main_vue.getData,
                methods: app.Data.vue_data.main_vue,
                filters: app.AppFilters

                // COMPONENTS
            };this.components = [];
            this.components.push({ id: "app", class: app.App, props: [], watch: [{ var: "app.section", callback: "sectionChange" }] });
            this.components.push({ id: "global-overlay", class: app.GlobalOverlay, props: [], watch: [] });
            this.components.push({ id: "dialog", class: app.Dialog, props: [], watch: [] });
            this.components.push({ id: "header", class: app.Header, props: [], watch: [] });
            this.components.push({ id: "burger", class: app.Burger, props: [], watch: [] });
            this.components.push({ id: "filters", class: app.Filters, props: [], watch: [] });
            this.components.push({ id: "filter-values-ranges", class: app.FilterValuesRanges, props: ["target"], watch: [] });
            this.components.push({ id: "products", class: app.Products, props: [], watch: [] });
            this.components.push({ id: "product-card", class: app.ProductCard, props: ["baseproduct"], watch: [] });
            this.components.push({ id: "product", class: app.Product, props: ["baseproduct"], watch: [{ var: "app.product_section", callback: "sectionChange" }] });
            this.components.push({ id: "productpls", class: app.ProductPLS, props: ["baseproduct"], watch: [] });
            this.components.push({ id: "finished-products", class: app.FinishedProducts, props: ["product"], watch: [] });
            this.components.push({ id: "finished-products-new", class: app.FinishedProductsNew, props: ["product"], watch: [] });
            this.components.push({ id: "finished-products-stocks", class: app.FinishedProductsStocks, props: ["baseproduct"], watch: [] });
            this.components.push({ id: "comparator", class: app.Comparator, props: [], watch: [] });
            this.components.push({ id: "samples", class: app.Samples, props: [], watch: [] });
            this.components.push({ id: "search", class: app.Search, props: [], watch: [] });

            var a = this.components.length;
            for (var i = 0; i < a; i++) {
                app.Data.html_templates[this.components[i].id] = verallia.FileLoader.get(this.components[i].id + "_template");
                var c = this.components[i].class;
                app.Data.vue_data["cp_" + this.components[i].id] = c;

                var watch = {};
                var b = this.components[i].watch.length;
                for (var j = 0; j < b; j++) {
                    watch[this.components[i].watch[j].var] = app.Data.vue_data["cp_" + this.components[i].id][this.components[i].watch[j].callback];
                }

                var app_data = {
                    data: app.Data.vue_data["cp_" + this.components[i].id].getData,
                    methods: app.Data.vue_data["cp_" + this.components[i].id]
                };

                app.Data.vue_data.components[this.components[i].id] = Vue.extend({
                    mixins: [app.Data.vue_data.main_vue_data],
                    props: this.components[i].props,
                    template: app.Data.html_templates[this.components[i].id],
                    data: app_data.data,
                    methods: app_data.methods,
                    mounted: app.Data.vue_data["cp_" + this.components[i].id].mounted,
                    beforeUpdate: app.Data.vue_data["cp_" + this.components[i].id].update,
                    destroyed: app.Data.vue_data["cp_" + this.components[i].id].dispose,
                    watch: watch
                });
                Vue.component("cp-" + [this.components[i].id], app.Data.vue_data.components[this.components[i].id]);
            }

            //VUE
            Vue.use(Vuetify); //use vuetify

            app.Data.main_app = new Vue({
                el: '#catalog_app',
                data: app.Data.vue_data.main_vue_data.data,
                methods: app.Data.vue_data.main_vue_data.methods,
                filters: app.Data.vue_data.main_vue_data.filters
            });
        }
    }]);

    return _class;
}();