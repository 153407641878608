"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.PlatformUtils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: "setPlatformConfig",
        value: function setPlatformConfig() {
            app.Data.data.config.theme = "ICT";

            if (app.Data.data.platform == "ICT" || app.Data.data.platform == "ICTCompetitors" || app.Data.data.platform == "VirtualGlass") {
                app.Data.data.config.country = "international";
                app.Data.data.config.use_header = true;
                app.Data.data.config.use_burger = true;
                app.Data.data.config.use_search = true;
                app.Data.data.config.use_comparator = true;
                app.Data.data.config.use_samples = false;
                app.Data.data.config.use_virtualglass = true;
                app.Data.data.config.use_url = true;
                app.Data.data.config.clean_url = false;
                app.Data.data.config.product.use_cart = false;
                app.Data.data.config.product.use_contact_button = false;
                app.Data.data.config.product.display_countries = true;
                app.Data.data.config.filters.use_product_mode = false;
                app.Data.data.config.filters.use_myverallia_groups = false;
                app.Data.data.config.filters.use_order_filters = true;
                app.Data.data.config.filters.use_countries = true;
                app.Data.data.config.filters.use_ranges = true;
                app.Data.data.config.filters.use_colors = true;
                app.Data.data.config.filters.use_finish = true;
                app.Data.data.config.filters.use_vg = true;
                app.Data.data.config.filters.use_sl = true;
                app.Data.data.config.filters.use_type = false;
                app.Data.data.config.filters.use_develop_ready = true;
                app.Data.data.config.filters.use_vg_objects = false;
                app.Data.data.config.filters.use_premium = true;
                app.Data.data.config.filters.use_volume = true;
                app.Data.data.config.filters.use_height = true;
                app.Data.data.config.filters.use_weight = true;
                app.Data.data.config.filters.use_diameter = true;
                app.Data.data.config.filters.use_brands = false;
                app.Data.data.config.filters.use_pls_dates = false;
                app.Data.data.config.filters.use_pls_cdates = false;
                app.Data.data.config.filters.use_pls_clients = false;
                app.Data.data.config.filters.use_pls_salesoffice = false;
                app.Data.data.config.filters.use_pls_decorators = false;
                app.Data.data.config.filters.use_pls_decoration = false;
                app.Data.data.config.filters.use_pls_contents = false;
                app.Data.data.config.filters.use_pls_communication = false;
                app.Data.data.config.filters.use_pls_commercials = false;
                app.Data.data.config.filters.use_pls_yearbook = false;
                app.Data.data.config.filters.use_pls_countrypublication = false;

                if (window.location.href.indexOf("apps.corp.inpkg.net") != -1) app.Data.data.config.filters.use_premium = false;

                if (app.Data.data.platform == "VirtualGlass") {
                    app.Data.data.config.use_virtualglass = false;
                    app.Data.data.config.use_comparator = false;
                    app.Data.data.config.filters.use_vg = false;
                    app.Data.data.config.filters.use_vg_objects = true;
                    app.Data.data.config.filters.use_premium = false;
                    app.Data.data.config.use_url = true;
                    app.Data.data.config.use_burger = false;
                }
                if (app.Data.data.platform == "ICTCompetitors") {
                    app.Data.data.config.filters.use_brands = true;
                    app.Data.data.config.filters.use_premium = false;
                }

                if (app.Data.data.mode == "vignovin") {
                    app.Data.data.config.clean_url = true;
                }
            } else if (app.Data.data.platform == "ICTPLS") {
                app.Data.data.config.country = "international";
                app.Data.data.config.theme = "ICT";
                app.Data.data.config.use_header = true;
                app.Data.data.config.use_burger = true;
                app.Data.data.config.use_search = true;
                app.Data.data.config.use_comparator = false;
                app.Data.data.config.use_samples = false;
                app.Data.data.config.use_virtualglass = false;
                app.Data.data.config.use_url = true;
                app.Data.data.config.clean_url = false;
                app.Data.data.config.product.use_cart = false;
                app.Data.data.config.product.use_contact_button = false;
                app.Data.data.config.product.display_countries = false;
                app.Data.data.config.filters.use_product_mode = false;
                app.Data.data.config.filters.use_myverallia_groups = false;
                app.Data.data.config.filters.use_order_filters = true;
                app.Data.data.config.filters.use_countries = true;
                app.Data.data.config.filters.use_ranges = true;
                app.Data.data.config.filters.use_colors = false;
                app.Data.data.config.filters.use_finish = false;
                app.Data.data.config.filters.use_vg = false;
                app.Data.data.config.filters.use_sl = false;
                app.Data.data.config.filters.use_type = true;
                app.Data.data.config.filters.use_develop_ready = false;
                app.Data.data.config.filters.use_vg_objects = false;
                app.Data.data.config.filters.use_premium = false;
                app.Data.data.config.filters.use_volume = false;
                app.Data.data.config.filters.use_height = false;
                app.Data.data.config.filters.use_weight = false;
                app.Data.data.config.filters.use_diameter = false;
                app.Data.data.config.filters.use_brands = true;
                app.Data.data.config.filters.use_pls_dates = true;
                app.Data.data.config.filters.use_pls_cdates = true;
                app.Data.data.config.filters.use_pls_clients = true;
                app.Data.data.config.filters.use_pls_salesoffice = true;
                app.Data.data.config.filters.use_pls_decorators = true;
                app.Data.data.config.filters.use_pls_decoration = true;
                app.Data.data.config.filters.use_pls_contents = true;
                app.Data.data.config.filters.use_pls_communication = true;
                app.Data.data.config.filters.use_pls_commercials = true;
                app.Data.data.config.filters.use_pls_yearbook = true;
                app.Data.data.config.filters.use_pls_countrypublication = true;
            } else if (app.Data.data.platform == "MyVerallia") {
                app.Data.data.config.theme = "MyVerallia";
                //app.Data.data.config.country = "FR";

                app.Data.data.config.use_header = true;
                app.Data.data.config.use_burger = false;
                app.Data.data.config.use_search = false;
                app.Data.data.config.use_comparator = true;
                app.Data.data.config.use_samples = false;
                app.Data.data.config.use_virtualglass = true;
                app.Data.data.config.use_url = true;
                app.Data.data.config.clean_url = false;
                app.Data.data.config.product.use_cart = false;
                app.Data.data.config.product.use_contact_button = true;
                app.Data.data.config.product.display_countries = false;
                app.Data.data.config.filters.use_product_mode = true;
                app.Data.data.config.filters.use_myverallia_groups = false;
                app.Data.data.config.filters.use_order_filters = true;
                app.Data.data.config.filters.use_countries = false;
                app.Data.data.config.filters.use_ranges = true;
                app.Data.data.config.filters.use_colors = true;
                app.Data.data.config.filters.use_finish = true;
                app.Data.data.config.filters.use_vg = true;
                app.Data.data.config.filters.use_sl = true;
                app.Data.data.config.filters.use_type = true;
                app.Data.data.config.filters.use_develop_ready = false;
                app.Data.data.config.filters.use_vg_objects = false;
                app.Data.data.config.filters.use_premium = false;
                app.Data.data.config.filters.use_volume = true;
                app.Data.data.config.filters.use_height = true;
                app.Data.data.config.filters.use_weight = true;
                app.Data.data.config.filters.use_diameter = true;
                app.Data.data.config.filters.use_brands = false;
                app.Data.data.config.filters.use_pls_dates = false;
                app.Data.data.config.filters.use_pls_cdates = false;
                app.Data.data.config.filters.use_pls_clients = false;
                app.Data.data.config.filters.use_pls_salesoffice = false;
                app.Data.data.config.filters.use_pls_decorators = false;
                app.Data.data.config.filters.use_pls_decoration = false;
                app.Data.data.config.filters.use_pls_contents = false;
                app.Data.data.config.filters.use_pls_communication = false;
                app.Data.data.config.filters.use_pls_commercials = false;
                app.Data.data.config.filters.use_pls_yearbook = false;
                app.Data.data.config.filters.use_pls_countrypublication = false;

                if (app.Data.data.mode == "VirtualGlass") {
                    app.Data.data.config.use_search = true;
                    app.Data.data.config.use_virtualglass = false;
                    app.Data.data.config.filters.use_vg = false;
                }

                try {
                    if (mv_can_order == true || mv_can_order == "true") app.Data.data.config.product.use_cart = true;
                } catch (e) {
                    if (window.location.href.indexOf("localhost") != -1) {
                        app.Data.data.config.product.use_cart = true;
                        app.Data.data.config.use_search = true;
                    } else {
                        app.Data.data.config.product.use_cart = false;
                    }
                }

                /*try{
                    if(mv_can_access_virtualglass == false || mv_can_access_virtualglass  == "false"){
                        app.Data.data.config.use_virtualglass = false;
                        app.Data.data.config.filters.use_vg = false;
                    }
                }catch(e){
                    app.Data.data.config.use_virtualglass = false;
                    app.Data.data.config.filters.use_vg = false;
                }*/
            } else if (app.Data.data.platform == "VeralliaCom") {
                app.Data.data.config.theme = "ICT";
                //app.Data.data.config.country = "FR"; //set by automatic detection

                app.Data.data.config.use_header = true;
                app.Data.data.config.use_burger = false;
                app.Data.data.config.use_search = true;
                app.Data.data.config.use_comparator = true;
                app.Data.data.config.use_samples = false;
                app.Data.data.config.use_virtualglass = false;
                app.Data.data.config.use_url = true;
                app.Data.data.config.clean_url = true;
                app.Data.data.config.product.use_cart = false;
                app.Data.data.config.product.use_contact_button = true;
                app.Data.data.config.product.display_countries = false;
                app.Data.data.config.filters.use_product_mode = false;
                app.Data.data.config.filters.use_myverallia_groups = false;
                app.Data.data.config.filters.use_order_filters = true;
                app.Data.data.config.filters.use_countries = false;
                app.Data.data.config.filters.use_ranges = true;
                app.Data.data.config.filters.use_colors = true;
                app.Data.data.config.filters.use_finish = true;
                app.Data.data.config.filters.use_vg = false;
                app.Data.data.config.filters.use_sl = true;
                app.Data.data.config.filters.use_type = false;
                app.Data.data.config.filters.use_develop_ready = false;
                app.Data.data.config.filters.use_vg_objects = false;
                app.Data.data.config.filters.use_premium = false;
                app.Data.data.config.filters.use_volume = true;
                app.Data.data.config.filters.use_height = true;
                app.Data.data.config.filters.use_weight = true;
                app.Data.data.config.filters.use_diameter = true;
                app.Data.data.config.filters.use_brands = false;
                app.Data.data.config.filters.use_pls_dates = false;
                app.Data.data.config.filters.use_pls_cdates = false;
                app.Data.data.config.filters.use_pls_clients = false;
                app.Data.data.config.filters.use_pls_salesoffice = false;
                app.Data.data.config.filters.use_pls_decorators = false;
                app.Data.data.config.filters.use_pls_decoration = false;
                app.Data.data.config.filters.use_pls_contents = false;
                app.Data.data.config.filters.use_pls_communication = false;
                app.Data.data.config.filters.use_pls_commercials = false;
                app.Data.data.config.filters.use_pls_yearbook = false;
                app.Data.data.config.filters.use_pls_countrypublication = false;
            }

            try {
                if (app.Data.data.config.product.use_contact_button == true) {
                    var countries = ["fr", "sp", "pt", "de", "it", "ua", "br", "ar", "cl", "ru"];
                    if (countries.indexOf(app.Data.data.config.country.toLowerCase()) == -1) app.Data.data.config.product.use_contact_button = false;
                }
            } catch (e) {
                app.Data.data.config.product.use_contact_button = false;
            }
        }
    }]);

    return _class;
}();