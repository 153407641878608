"use strict";

app.Filters = {
    mounted: function mounted() {
        verallia.Core.addObject("filters", this);
        $(window).off("UPDATE_FILTERS_DATA").on("UPDATE_FILTERS_DATA", this.updateFiltersData.bind(this));
        $(window).off("SET_PRODUCT_MODE").on("SET_PRODUCT_MODE", this.setProductMode.bind(this));
        $(window).on(verallia.Event.RESIZE, this.resize.bind(this));

        this.filter_target;

        this.resize();
        setTimeout(this.resize.bind(this), 200);
    },
    update: function update() {},
    getData: function getData() {
        return {
            panel: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
            pls_start_date: false,
            pls_end_date: false,
            pls_start_cdate: false,
            pls_end_cdate: false,
            filter_block: null
        };
    },
    dispose: function dispose() {
        $(window).off("UPDATE_FILTERS_DATA");
        $(window).off(verallia.Event.RESIZE, this.resize.bind(this));
        $(document).off("mouseup");
    },
    resize: function resize() {
        $("#ct_filter_block .nano").nanoScroller();
        this.filterBlockResize();
    },

    updateDisplay: function updateDisplay() {},
    updateFiltersData: function updateFiltersData() {
        this.checkInput();
        if (app.Data.data.platform == "ICTPLS") this.checkDatesInput();
        this.checkFiltersVisibility();
    },
    resetFilters: function resetFilters() {
        if (app.Data.data.platform == "MyVerallia") {
            this.setProductMode(app.Data.data.product_mode);
            return;
        }
        app.Utils.resetFilters();
        verallia.Core.getObject("app").processCatalog();
    },
    applyFilters: function applyFilters() {
        this.updateFiltersData();
        if (app.Data.data.platform == "MyVerallia") app.Utils.updateAccountsVisibility();
        verallia.Core.getObject("app").processCatalog();

        this.resize();
        setTimeout(this.resize.bind(this), 200);
    },
    applyPLSFilters: function applyPLSFilters() {
        setTimeout(this.applyFilters.bind(this), 100);
    },
    applyGroupsFilters: function applyGroupsFilters() {
        app.Data.data.filters.myverallia_groups.selected_account = "all";
        this.applyFilters();
    },
    applyAccountsFilters: function applyAccountsFilters() {
        this.applyFilters();
    },
    checkInput: function checkInput() {},
    setProductMode: function setProductMode(mode) {
        app.Utils.resetFilters();
        this.applyFilters();
    },
    checkDatesInput: function checkDatesInput() {
        var start_date = app.Data.data.filters.pls_dates.dates[app.Data.data.filters.pls_dates.selected_min];
        var end_date = app.Data.data.filters.pls_dates.dates[app.Data.data.filters.pls_dates.selected_max];

        if (start_date.date.getTime() > end_date.date.getTime()) {
            app.Data.data.filters.pls_dates.selected_min = app.Data.data.filters.pls_dates.selected_max;
        } else if (end_date.date.getTime() < start_date.date.getTime()) {
            app.Data.data.filters.pls_dates.selected_max = app.Data.data.filters.pls_dates.selected_min;
        }

        if (app.Data.data.config.filters.use_pls_cdates == false) return;

        var start_cdate = app.Data.data.filters.pls_cdates.dates[app.Data.data.filters.pls_cdates.selected_min];
        var end_cdate = app.Data.data.filters.pls_cdates.dates[app.Data.data.filters.pls_cdates.selected_max];

        if (start_cdate.cdate.getTime() > end_cdate.cdate.getTime()) {
            app.Data.data.filters.pls_cdates.selected_min = app.Data.data.filters.pls_cdates.selected_max;
        } else if (end_cdate.cdate.getTime() < start_cdate.cdate.getTime()) {
            app.Data.data.filters.pls_cdates.selected_max = app.Data.data.filters.pls_cdates.selected_min;
        }
    },
    selectCountry: function selectCountry(key) {
        if (app.Data.data.filters.countries[key].selected == true) {
            app.Data.data.filters.countries[key].selected = false;
        } else {
            app.Data.data.filters.countries[key].selected = true;
        }

        this.applyFilters();
    },
    selectCountries: function selectCountries() {
        if (app.Data.data.config.filters.all_countries_selected == false) {
            for (var str in app.Data.data.filters.countries) {
                app.Data.data.filters.countries[str].selected = false;
            }
        } else {
            for (var str in app.Data.data.filters.countries) {
                app.Data.data.filters.countries[str].selected = true;
            }
        }

        this.applyFilters();
    },
    checkFiltersVisibility: function checkFiltersVisibility() {
        //COUNTRIES
        if (app.Data.data.config.filters.use_countries == true) {
            var a = 0;
            for (var str in app.Data.data.filters.countries) {
                var country = app.Data.data.filters.countries[str];

                if (country.selected == true) a++;
            }

            if (a == Object.keys(app.Data.data.filters.countries).length) app.Data.data.config.filters.all_countries_selected = true;else app.Data.data.config.filters.all_countries_selected = false;
        }

        //RANGES
        if (app.Data.data.config.filters.use_ranges == true) {
            for (var str in app.Data.data.filters.ranges) {
                var range = app.Data.data.filters.ranges[str];
                if (range.selected == false && Object.keys(range.sub).length > 0) {
                    for (var str2 in range.sub) {
                        range.sub[str2].selected = false;
                    }
                }
            }
        }

        //COLORS
        if (app.Data.data.config.filters.use_colors == true) {
            for (var str in app.Data.data.filters.colors) {
                var color = app.Data.data.filters.colors[str];
                if (color.selected == false && Object.keys(color.sub).length > 0) {
                    for (var str2 in color.sub) {
                        color.sub[str2].selected = false;
                    }
                }
            }
        }

        //FINISH
        if (app.Data.data.config.filters.use_finish == true) {
            for (var str in app.Data.data.filters.finish) {
                var finish = app.Data.data.filters.finish[str];
                if (finish.selected == false && Object.keys(finish.sub).length > 0) {
                    for (var str2 in finish.sub) {
                        finish.sub[str2].selected = false;
                    }
                }
            }
        }
    },

    getAllowedDates: function getAllowedDates(value) {
        if (app.Data.data.filters.pls_dates.dates[value]) return true;

        return false;
    },
    getAllowedCDates: function getAllowedCDates(value) {
        if (app.Data.data.filters.pls_cdates.dates[value]) return true;

        return false;
    },
    getSelectedAccounts: function getSelectedAccounts() {
        return 0;
    },
    getSelectedRanges: function getSelectedRanges() {
        var num = 0;
        for (var str in app.Data.data.filters.ranges) {
            var filter = app.Data.data.filters.ranges[str];
            if (filter.selected == true) {
                if (Object.keys(filter.sub).length > 0) {
                    var num2 = 0;
                    var subfilter_selected = false;
                    for (var str2 in filter.sub) {
                        var subfilter = filter.sub[str2];
                        if (subfilter.selected == true) {
                            num2++;
                            num++;
                        }
                    }

                    if (num2 == 0) num++;
                } else {
                    num++;
                }
            }
        }

        return num;
    },
    getSelectedColors: function getSelectedColors() {
        var colors = [];
        for (var str in app.Data.data.filters.colors) {
            var filter = app.Data.data.filters.colors[str];
            if (filter.selected == true) {
                if (Object.keys(filter.sub).length > 0) {
                    var num = 0;
                    var subfilter_selected = false;
                    for (var str2 in filter.sub) {
                        var subfilter = filter.sub[str2];
                        if (subfilter.selected == true) {
                            num++;
                            colors.push(subfilter.cc);
                        }
                    }

                    if (num == 0) colors.push(filter.cc);
                } else {
                    colors.push(filter.cc);
                }
            }
        }

        return colors;
    },
    getSelectedFinish: function getSelectedFinish() {
        var num = 0;
        for (var str in app.Data.data.filters.finish) {
            var filter = app.Data.data.filters.finish[str];
            if (filter.selected == true) {
                if (Object.keys(filter.sub).length > 0) {
                    var num2 = 0;
                    var subfilter_selected = false;
                    for (var str2 in filter.sub) {
                        var subfilter = filter.sub[str2];
                        if (subfilter.selected == true) {
                            num2++;
                            num++;
                        }
                    }

                    if (num2 == 0) num++;
                } else {
                    num++;
                }
            }
        }

        return num;
    },
    getSelectedBrands: function getSelectedBrands() {
        var num = 0;
        for (var str in app.Data.data.filters.brands) {
            var filter = app.Data.data.filters.brands[str];
            if (filter.selected == true) {
                num++;
            }
        }

        return num;
    },
    getSelectedPLSValues: function getSelectedPLSValues(pls_value) {
        var num = 0;
        if (pls_value == "pls_decorators" || pls_value == "pls_contents" || pls_value == "pls_communication") {
            for (var str in app.Data.data.filters[pls_value]) {
                var filter = app.Data.data.filters[pls_value][str];
                if (filter.selected == true) {
                    num++;
                }
            }
        } else {
            num = app.Data.data.filters[pls_value].selected.length;
        }

        return num;
    },
    getSelectedPublicationCountries: function getSelectedPublicationCountries() {
        var a = 0;

        for (var str in app.Data.data.filters.pls_countrypublication) {
            if (app.Data.data.filters.pls_countrypublication[str] == true) a++;
        }

        return a;
    },
    openFiltersSelection: function openFiltersSelection() {
        this.filter_block = "selector";
        this.filter_target = $(".CTHeaderAddBlock .v-icon");

        $(document).off("mouseup").on("mouseup", this.closeFilterBlock.bind(this));

        app.Data.data.products_overlay = true;
        setTimeout(this.resize.bind(this), 10);
        setTimeout(this.resize.bind(this), 50);
        setTimeout(this.resize.bind(this), 100);
    },
    toggleFilter: function toggleFilter(id) {
        var index = app.Data.data.used_filters.indexOf(id);
        if (index == -1) app.Data.data.used_filters.push(id);else app.Data.data.used_filters.splice(index, 1);

        verallia.LocalStorageUtils.setKey("ct_filters", JSON.stringify(app.Data.data.used_filters));

        setTimeout(this.filterBlockResize.bind(this), 10);
        setTimeout(this.filterBlockResize.bind(this), 50);
        setTimeout(this.filterBlockResize.bind(this), 100);

        this.applyFilters();
    },
    filterBlockResize: function filterBlockResize() {
        //FILTERS LIST POSITION
        try {
            var h = $("#ct_filters_list").height();
            var p = $("#ct_filters_list").offset().top - $("#ct_container").offset().top;
            var hh = $("#ct_filters_list").offset().top - $("#ct_container").offset().top;

            var dist = 93;
            var dist_sup = 0;
            if (h + p > dist) {
                hh = h + p - 30 - 35;
            } else {
                hh = 93;
                dist_sup = 35;
            }

            if (app.Data.data.isMobile == false) {
                $("#ct_header").height(hh - dist_sup);
                $("#ct_products").css("margin-top", hh + 35 + 58);
                $("#ct_products").height($(window).height() - hh - 70 - 25 - $("#ct_container").offset().top);
            } else {
                //$("#ct_header").height(50);
                $("#ct_header").height($(window).height() - 50);
                $("#ct_products").css("margin-top", 50);
                $("#ct_products").height($(window).height() - 50);
            }

            if (!this.filter_block) {
                //console.log("filter_block");
                //console.log(this.filter_block);
                $("#ct_filter_block").hide();
                return;
            }

            //FILTER BLOCK POSITION

            var supx = -25;
            var posx = 0;
            var posy = 0;
            var width = 460;
            var filter_target_height = 40;
            var margins = 30 * 2;
            if (this.filter_block != "selector") {
                supx = 0;
                posx = $(".CTHeaderBlocksContainer").offset().left;
                posy = $(".CTHeaderBlocksContainer").offset().top;
                width = 360;
                filter_target_height = $(this.filter_target).height() + 25;
            }

            if (width + margins > $(window).width() - 20) width = $(window).width() - 20 - margins;

            $("#ct_filter_block").width(width);
            if (app.Data.data.isMobile == false) {
                $("#ct_filter_block").css("top", $(this.filter_target).offset().top + filter_target_height);
                $("#ct_filter_block").css("left", $(this.filter_target).offset().left + supx);
                $("#ct_filter_block .CTFilterTriangle").show();
            } else {
                $("#ct_filter_block").css("top", 150);
                $("#ct_filter_block").css("left", $(this.filter_target).offset().left + supx);
                $("#ct_filter_block .CTFilterTriangle").hide();
            }

            $("#ct_filter_block .CTFilterTriangle").css("margin-left", 0);

            var current_pos = $("#ct_filter_block").offset().left;
            var diff;

            if (current_pos + width + margins > $(window).width() - 10) {
                diff = current_pos + width - ($(window).width() - 10);

                $("#ct_filter_block").css("left", $(this.filter_target).offset().left + supx - diff - margins);

                var tr_pos = diff + margins;
                if (tr_pos > width - 20) tr_pos = width - 20;
                $("#ct_filter_block .CTFilterTriangle").css("margin-left", tr_pos);
            }

            $("#ct_filter_block").show();
            $("#ct_filter_block").css("display", "table");
        } catch (e) {
            //console.log(e);
            ///console.log("ERROR");
        }
    },
    openFilterOptions: function openFilterOptions(id, index) {
        this.filter_block = id;
        this.filter_target = $($(".CTHeaderFilterBlock")[index]);

        $(document).off("mouseup").on("mouseup", this.closeFilterBlock.bind(this));

        app.Data.data.products_overlay = true;
        setTimeout(this.resize.bind(this), 10);
        setTimeout(this.resize.bind(this), 50);
        setTimeout(this.resize.bind(this), 100);
    },
    closeFilterBlock: function closeFilterBlock(e) {
        if (e) e.stopPropagation();

        if (this.filter_block != "selector") {
            this.applyFilters();
        }

        if (!this.filter_block) {
            $(document).off("mouseup");
            return;
        }

        if (!e) {
            this.filter_block = null;
            app.Data.data.products_overlay = false;
            this.filterBlockResize();

            $(document).off("mouseup");

            return;
        }

        if (!$(".v-menu__content").is(e.target) && $(".v-menu__content").has(e.target).length === 0) {} else {
            return;
        }

        if (!$("#ct_filters").is(e.target) && $("#ct_filters").has(e.target).length === 0) {
            this.filter_block = null;
            app.Data.data.products_overlay = false;
            this.filterBlockResize();

            $(document).off("mouseup");
        }
    },

    removeFilter: function removeFilter(id) {
        var index = app.Data.data.used_filters.indexOf(id);
        if (index != -1) app.Data.data.used_filters.splice(index, 1);

        verallia.LocalStorageUtils.setKey("ct_filters", JSON.stringify(app.Data.data.used_filters));

        this.filter_block = null;
        app.Data.data.products_overlay = false;

        setTimeout(this.filterBlockResize.bind(this), 10);
        setTimeout(this.filterBlockResize.bind(this), 50);
        setTimeout(this.filterBlockResize.bind(this), 100);

        $(document).off("mouseup");

        this.applyFilters();
    },
    openComparator: function openComparator() {
        app.Data.data.comparator = true;
    },
    unselectChip: function unselectChip(filter_id, value) {
        trace(filter_id);
        app.Data.data.filters["pls_" + filter_id].selected.splice(value, 1);

        this.applyPLSFilters();
    },

    checkCountryVisibility: function checkCountryVisibility(country) {
        if (app.Data.data.loc.global.countries[country].onlyfor) {
            var tab = app.Data.data.loc.global.countries[country].onlyfor;

            if (tab.indexOf(app.Data.data.platform) != -1) return true;else return false;
        }

        return true;
    }
};