"use strict";

function showOnlyEasyRenderProducts(value) {
    if (app.Data.data.platform != "VirtualGlass") return;

    if (value === true || value === false) app.Data.data.only_easyrender_products = value;else app.Data.data.only_easyrender_products = false;

    app.Data.data.baseproduct = null;
    app.Data.data.section = "products";
    $(window).trigger("UPDATE_PRODUCTS_DATA");
    $("#ct_products_list").nanoScroller({ scroll: "top" });
    verallia.Core.getObject("app").updateHash();
}
function setLanguage(id) {
    app.Data.data.loc = app.Data.data.global_loc[id];
    app.Data.data.config.language = id;
}