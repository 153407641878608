"use strict";

app.Dialog = {
    mounted: function mounted() {
        $(window).on(verallia.Event.DIALOG_OPEN, this.openDialog.bind(this));
        $(window).on(verallia.Event.DIALOG_CLOSE, this.closeDialog.bind(this));
    },
    update: function update() {},
    getData: function getData() {
        return {
            status: false,
            max_width: "700px",
            title_icon: null,
            title: "Title",
            content: "Content",
            button1: { label: "OK", icon: "back", callback: null },
            button2: null,
            button3: null,
            persistent: false,
            loading: false
        };
    },
    dispose: function dispose() {
        $(window).off(verallia.Event.DIALOG_OPEN, this.openDialog.bind(this));
        $(window).off(verallia.Event.DIALOG_CLOSE, this.closeDialog.bind(this));
    },
    openDialog: function openDialog(e, data) {
        this.title_icon = null;
        this.max_width = "700px";
        this.title = "Title";
        this.content = "Content";
        this.button1 = null;
        this.button2 = null;
        this.button3 = null;
        this.persistent = false;
        this.loading = false;

        if (data.title_icon) this.title_icon = data.title_icon;
        if (data.max_width) this.max_width = data.max_width;
        if (data.title) this.title = data.title;
        if (data.content) this.content = data.content;
        if (data.button1) this.button1 = data.button1;
        if (data.button2) this.button2 = data.button2;
        if (data.button3) this.button3 = data.button3;
        if (data.persistent != null && data.persistent != undefined) this.persistent = data.persistent;
        if (data.loading != null && data.loading != undefined) this.loading = data.loading;

        this.status = true;
    },
    closeDialog: function closeDialog() {
        this.status = false;
    },
    clickButton: function clickButton(index) {
        this.closeDialog();

        if (this["button" + index].callback) this["button" + index].callback();
    }
};