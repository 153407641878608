"use strict";

app.App = {
    mounted: function mounted() {
        verallia.Core.addObject("app", this);

        $(window).on(verallia.Event.START_WORKING, this.startWorking.bind(this));
        $(window).on(verallia.Event.STOP_WORKING, this.stopWorking.bind(this));
        $(window).on(verallia.Event.APP_READY, this.appReady.bind(this));
        $(window).on(verallia.Event.UPDATE, this.updateApp.bind(this));
        $(window).on(verallia.Event.HASH_CHANGE, this.hashCHange.bind(this));
        $(window).on(verallia.Event.DESTROY, this.destroy.bind(this));

        //$(window).on(verallia.Event.RESIZE, this.resize.bind(this));
        //$('#section_container').resize(this.resize.bind(this));
    },
    getData: function getData() {
        return {};
    },
    updateApp: function updateApp() {},
    dispose: function dispose() {
        $(window).off(verallia.Event.START_WORKING);
        $(window).off(verallia.Event.STOP_WORKING);
        $(window).off(verallia.Event.APP_READY);
        $(window).off(verallia.Event.UPDATE);
        $(window).off(verallia.Event.HASH_CHANGE);
        $(window).off(verallia.Event.DESTROY);
    },
    destroy: function destroy() {
        this.$destroy();
    },
    hashCHange: function hashCHange() {
        if (app.Data.data.check_hashchange == false || app.Data.data.config.use_url == false) return;

        this.checkHash();
    },
    checkHash: function checkHash() {
        var hash = window.location.hash;

        if (hash && hash != "") {
            try {
                hash = verallia.StringUtils.decodeEntities(hash);
                hash = hash.replace("#/", "");

                if (hash.indexOf("plsproduct_") != -1 || hash.indexOf("ictproduct_") != -1) {
                    app.Utils.setDataFromHashID(hash);
                } else if (app.Data.data.config.clean_url == false) {
                    app.Utils.setDataFromHash(JSON.parse(hash));
                } else if (app.Data.data.config.clean_url == true) {
                    app.Utils.setDataFromHash(hash);
                }
            } catch (e) {
                trace("error on data from hash, proceed without taking account on hash data.");
            }
        }

        this.processCatalog();
    },
    processCatalog: function processCatalog() {
        $(window).trigger("UPDATE_FILTERS_DATA");
        $(window).trigger("UPDATE_PRODUCTS_DATA");
        if (app.Data.data.baseproduct) $(window).trigger("UPDATE_PRODUCT_DATA");

        if (app.Data.data.baseproduct) app.Data.data.section = "product";else app.Data.data.section = "products";

        if (app.Data.data.config.use_url == true) this.updateHash();
    },

    updateHash: function updateHash() {
        if (app.Data.data.config.use_url == false) return;

        app.Data.data.check_hashchange = false;

        //if product, the we keep history so the back button in the browser will close the product
        if (app.Data.data.baseproduct) verallia.WindowUtils.setHash(app.Utils.generateHashFromCatalog(), true);else verallia.WindowUtils.setHash(app.Utils.generateHashFromCatalog(), false);

        setTimeout(startHashChangeDetection, 200);
        function startHashChangeDetection() {
            app.Data.data.check_hashchange = true;
        }
    },
    appReady: function appReady() {
        if (app.Data.data.app_ready == true) return;

        app.Data.data.app_ready = true;

        if (app.Data.data.config.use_url == true) setTimeout(this.checkHash.bind(this), 200);else setTimeout(this.processCatalog.bind(this), 200);
    },
    startWorking: function startWorking() {
        app.Data.data.working = true;
    },
    stopWorking: function stopWorking() {
        app.Data.data.working = false;
    },
    sectionChange: function sectionChange(value, old_value) {
        if (value == "product") $(window).trigger("UPDATE_PRODUCT_DATA");
    },
    resize: function resize() {}
};