"use strict";

app.Burger = {
    mounted: function mounted() {},
    update: function update() {},
    getData: function getData() {
        return {};
    },
    dispose: function dispose() {},
    setLanguage: function setLanguage(id) {
        app.Data.data.loc = app.Data.data.global_loc[id];
        app.Data.data.config.language = id;

        verallia.LocalStorageUtils.setKey("ct_language", id);
    },
    setCatalog: function setCatalog(id) {
        $(window).trigger(verallia.Event.START_WORKING);
        verallia.WindowUtils.setHash("#/", false);
        window.platform = app.Data.data.platform = id;
        $(window).trigger(verallia.Event.RESTART);
    }
};