"use strict";

app.Samples = {
    mounted: function mounted() {
        $("#ct_samples_products input").on("propertychange change keyup input paste focus focusout", this.checkQuantities.bind(this));
    },
    update: function update() {},
    getData: function getData() {
        return {};
    },
    dispose: function dispose() {},
    closeSamples: function closeSamples() {
        app.Data.data.samples = false;
    },

    removeFromSamples: function removeFromSamples(id) {
        this.$delete(app.Data.data.samples_products, id);

        verallia.LocalStorageUtils.setKey("ct_samples" + app.Data.data.platform, JSON.stringify(app.Data.data.samples_products));

        if (Object.keys(app.Data.data.samples_products).length == 0) this.closeSamples();
    },
    openContact: function openContact() {},

    getSamplesSData: function getSamplesSData() {
        return; // TO CHECK

    },
    removeQuantity: function removeQuantity(p) {
        p.quantity--;
        this.checkQuantities();
    },
    addQuantity: function addQuantity(p) {
        p.quantity++;
        this.checkQuantities();
    },
    checkQuantities: function checkQuantities() {
        for (var str in app.Data.data.samples_products) {
            var p = app.Data.data.samples_products[str];

            p.quantity = String(p.quantity).replace(/[^\d]/, "");
            if (p.quantity == "" || !p.quantity) p.quantity = 1;

            if (parseInt(p.quantity) < 1) p.quantity = 1;else if (parseInt(p.quantity) > 99) p.quantity = 99;

            p.quantity = parseInt(p.quantity);
        }
    }
};