"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var app = app || {};

app.Main = function () {
    function _class() {
        _classCallCheck(this, _class);

        this.init_application;
        this.application;

        $(window).on(verallia.Event.RESTART, this.restart.bind(this));

        this.init();
    }

    _createClass(_class, [{
        key: "init",
        value: function init() {
            if (window.location.href.indexOf("localhost") == -1 && window.location.protocol == "http:") {
                window.location.href = "https://" + location.host + location.pathname + window.location.hash.replace("/#", "#");
                return;
            }

            if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1 || window.location.href.indexOf("appsqual.corp.inpkg.net") != -1 || window.location.href.indexOf("apps.corp.inpkg.net") != -1) {
                this.SSO = new app.SSO(this.loggedIn.bind(this), this.loggedOut.bind(this));
            } else {
                this.loggedIn();
            }
        }
    }, {
        key: "loggedIn",
        value: function loggedIn() {
            //if(app.Data.data.platform == "MyVerallia"){
            try {
                app.Data.data.access_token = sfdc_ws_token;
            } catch (e) {
                app.Data.data.access_token = "XXXXXX";
            }
            //}

            if (window.user) {
                var user = {};
                user.sgid = window.user.id;
                user.id = verallia.StringUtils.makeURLString(verallia.StringUtils.replace(window.user.attributes[0].mail.toLowerCase(), "@", "ABC"));
                user.id = verallia.StringUtils.replace(user.id, "ABC", "_");
                user.email = window.user.attributes[0].mail;
                user.name = window.user.attributes[1].sn;
                user.given_name = window.user.attributes[2].givenName;
                app.Data.data.user = user;
            } else {
                if (window.location.href.indexOf("force") != -1) {
                    var user = {};
                    user.id = verallia.StringUtils.makeURLString(verallia.StringUtils.replace(mv_user_email.toLowerCase(), "@", "ABC"));
                    user.id = verallia.StringUtils.replace(user.id, "ABC", "_");
                    user.email = mv_user_email;
                    user.name = mv_user_last_name;
                    user.given_name = mv_user_first_name;
                    app.Data.data.user = user;
                }
            }

            trace("user : ");
            trace(app.Data.data.user);

            try {
                app.Data.data.mode = mode;
            } catch (e) {}

            try {
                app.Data.data.platform = platform;
            } catch (e) {}

            if (!app.Data.data.mode) app.Data.data.mode = verallia.WindowUtils.getUrlParam("mode") || "render";
            if (!app.Data.data.platform) app.Data.data.platform = verallia.WindowUtils.getUrlParam("platform") || "ICT";

            if (app.Data.data.mode == "VirtualGlass") {
                if (app.Data.data.platform == "MyVerallia") {
                    $(".main-navbar").hide();
                }
            }

            this.initApplication();
        }
    }, {
        key: "loggedOut",
        value: function loggedOut() {}
    }, {
        key: "initApplication",
        value: function initApplication() {
            $("#ct_loader").addClass("CTAnimatedLoader").show();
            this.init_application = new app.InitApp(this.start.bind(this));
        }
    }, {
        key: "start",
        value: function start() {
            this.application = new app.Application();
        }
    }, {
        key: "restart",
        value: function restart() {
            $("#ct_loader").fadeIn(500, this.beginRestart.bind(this));
            $(window).trigger(verallia.Event.DESTROY);
        }
    }, {
        key: "beginRestart",
        value: function beginRestart() {
            app.Data.reset();
            this.application.dispose();
            $("#ct_loader").removeClass("CTAnimatedLoader");
            $("#catalog_app").hide();
            $("#catalog_app").empty();
            $("#catalog_app").append("<v-app><cp-app></cp-app></v-app>");
            this.initApplication();
        }
    }]);

    return _class;
}();

$(checkApp);

function checkApp() {
    //if(verallia.NodeUtils.isNode() == true || verallia.CordovaUtils.isCordova() == true) return;

    var ua = window.navigator.userAgent.toLowerCase();
    var msie = ua.indexOf('msie');
    var trident = ua.indexOf('trident');

    if (msie != -1 || trident != -1) {
        var showMessage = function showMessage() {
            alert(message);
        };

        setTimeout(showMessage, 1000);

        var messages = {};
        messages.en = "This page cannot be seen on Internet Explorer.\n\nPlease use Chrome or Edge or another browser.";
        messages.fr = "Cette page ne peut pas être visionnée sur ce navigateur.\n\nMerci d'utiliser Google Chrome, Edge ou un autre navigateur.";
        messages.es = "Esta página no se puede ver en Internet Explorer.\n\nUtilice Chrome o Edge u otro navegador.";
        messages.de = "Diese Seite kann im Internet Explorer nicht angezeigt werden.\n\nBitte verwenden Sie Chrome oder Edge oder einen anderen Browser.";
        messages.pt = "Esta página não pode ser vista no Internet Explorer.\n\nUse Chrome, Edge ou outro navegador.";
        messages.it = "Questa pagina non può essere visualizzata su Internet Explorer.\n\nSi prega di utilizzare Chrome o Edge o un altro browser.";
        messages.pl = "Ta strona nie jest widoczna w przeglądarce Internet Explorer.\n\nUżyj Chrome, Edge lub innej przeglądarki.";
        messages.ru = "Эту страницу нельзя увидеть в Internet Explorer.\n\nПожалуйста, используйте Chrome, Edge или другой браузер.";
        messages.uk = "Цю сторінку не можна побачити в Internet Explorer.\n\nВикористовуйте Chrome, Edge або інший веб-переглядач.";

        var message = messages.en;

        try {
            if (window.location.href.indexOf("verallia.com") != -1) {
                var lang = "en";
                if (window.location.href.indexOf("-fr") != -1) lang = "fr";else if (window.location.href.indexOf("-en") != -1) lang = "en";else if (window.location.href.indexOf("-de") != -1) lang = "de";else if (window.location.href.indexOf("-es") != -1) lang = "es";else if (window.location.href.indexOf("-pt") != -1) lang = "pt";else if (window.location.href.indexOf("-it") != -1) lang = "it";else if (window.location.href.indexOf("-pl") != -1) lang = "pl";else if (window.location.href.indexOf("-ru") != -1) lang = "ru";else if (window.location.href.indexOf("-uk") != -1) lang = "uk";
            } else if (app.Data.data.platform == "MyVerallia") {
                lang = ct_myverallia_selected_Language.toLowerCase();
            }

            message = messages[lang];
        } catch (e) {}

        if (!message) message = messages.en;

        return;
    }

    init();
}
function init() {
    app.Data.init();
    var main_app = new app.Main();
}

function startApp(params) {
    window.app_params = params;

    init();
}

//var platform = "VirtualGlass";
var platform = "VeralliaCom";
//var platform = "MyVerallia";
var selected_country = null;
var selected_language = null;
//var mode = "vignovin";