"use strict";

app.Comparator = {
    mounted: function mounted() {},
    update: function update() {},
    getData: function getData() {
        return {};
    },
    dispose: function dispose() {},
    closeComparator: function closeComparator() {
        app.Data.data.comparator = false;
    },

    removeFromComparator: function removeFromComparator(id) {
        this.$delete(app.Data.data.comparator_products, id);

        verallia.LocalStorageUtils.setKey("ct_comparator" + app.Data.data.platform, JSON.stringify(app.Data.data.comparator_products));

        if (Object.keys(app.Data.data.comparator_products).length == 0) this.closeComparator();
    },
    openContact: function openContact() {
        var country = app.Data.data.config.country.toLowerCase();
        var language = app.Data.data.config.language.toLowerCase();
        var url = app.Data.data.config.myverallia_contact_url[country][language];

        url += "?compare=1";

        for (var str in app.Data.data.comparator_products) {
            var p = app.Data.data.baseproducts[str];

            url += "&products[]=" + p.pr[app.Data.data.comparator_products[str].index].cb + " - " + p.la + " - " + p.pr[app.Data.data.comparator_products[str].index].cl;
        }

        verallia.WindowUtils.openURL(url, "_blank");
    },

    getComparatorSData: function getComparatorSData() {
        var sdata = "";

        var a = Object.keys(app.Data.data.comparator_products).length;
        var i = 0;
        for (var str in app.Data.data.comparator_products) {
            var p = app.Data.data.baseproducts[str];
            if (p) {
                sdata += p.la + " - " + p.pr[app.Data.data.comparator_products[str].index].cb;
                if (i != a - 1) sdata += " | ";

                i++;
            }
        }

        return sdata;
    }
};