"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.PalettesStockRequest = function () {
    function _class(product, callback) {
        _classCallCheck(this, _class);

        this.product = product;
        this.callback = callback;

        this.init();
    }

    _createClass(_class, [{
        key: "init",
        value: function init() {
            var red_threshold = null;
            var yellow_threshold = null;

            if (app.Data.data.platform == "MyVerallia") {
                try {
                    red_threshold = ct_stock_red_threshold;
                    yellow_threshold = ct_stock_yellow_threshold;
                } catch (e) {}
            } else if (window.location.href.indexOf("localhost") != -1) {
                red_threshold = 25;
                yellow_threshold = 20;
                app.Data.data.stock_check = true;
            }

            if (!red_threshold || red_threshold == "null" || red_threshold == "0" || !yellow_threshold || yellow_threshold == "null" || yellow_threshold == "0") {
                app.Data.data.stock_check = false;
                return;
            }

            app.Data.data.stock_check = true;

            var str = "";
            var a = this.product.fip.length;

            if (a == 1) {
                str = '{ "item":{ "Products" : "' + this.product.fip[0].cf + '" }}';
            } else {
                str = '{ "item":[';

                for (var i = 0; i < a; i++) {
                    if (i < a - 1) str += '{ "Products" : "' + this.product.fip[i].cf + '"}, ';else str += '{ "Products" : "' + this.product.fip[i].cf + '"}';
                }

                str += ']}';
            }

            var str2 = this.product.ai;
            if (str2 == "VICASAMBV") str2 = "S10";
            if (str2 == "VETRI") str2 = "S50";

            //trace(app.Utils.getAreaIDFromChannel(product_datas.pr[product_index].ai));
            //var dat = '{"ZbuExtStockStdGetinfo" : { "ICompanyCode":"' + product_datas.pr[product_index].ai + '", "ILang": "EN", "IProducts": { ' + str + ' }, "IRedThreshold": "' + red_threshold + '", "IStkStdLight": "", "IYellowThreshold": "' + yellow_threshold + '"}}';
            var dat = '{"ZbuExtStockStdGetinfo" : { "ICompanyCode":"' + str2 + '", "ILang": "EN", "IProducts":' + str + ', "IRedThreshold": "' + red_threshold + '", "IStkStdLight": "", "IYellowThreshold": "' + yellow_threshold + '"}}';

            //jQuery.post( "https://eservicesqual.verallia.com/stock", dat, updateStock, "json");

            //trace("sending datas ----------");
            //trace(JSON.parse(dat));

            $.ajax({
                //url: "http://wsqual.corp.inpkg.net/services/stock",
                url: "https://eservicesqual.verallia.com/stock",
                data: dat,
                method: "post",
                dataType: 'json',
                contentType: 'application/json',
                success: this.stockReponse.bind(this),
                error: this.failStock.bind(this)
            });
        }
    }, {
        key: "stockReponse",
        value: function stockReponse(datas) {
            if (open == false) return;

            //trace("stock service response ok:");
            //trace(datas);


            var tab = [];

            datas.ZbuExtStockStdGetinfoResponse.EStocksStd.item = verallia.ObjectUtils.makeArrayFromObject(datas.ZbuExtStockStdGetinfoResponse.EStocksStd.item);

            var a = datas.ZbuExtStockStdGetinfoResponse.EStocksStd.item.length;
            for (var i = 0; i < a; i++) {
                var b = this.product.fip.length;
                for (var j = 0; j < b; j++) {
                    if (datas.ZbuExtStockStdGetinfoResponse.EStocksStd.item[i].SgProductId == this.product.fip[j].cf) {
                        //trace(datas.ZbuExtStockStdGetinfoResponse.EStocksStd.item[i].SgProductId + " : " + datas.ZbuExtStockStdGetinfoResponse.EStocksStd.item[i].TrafficLight);

                        if (datas.ZbuExtStockStdGetinfoResponse.EStocksStd.item[i].TrafficLight.toLowerCase() == "g") tab[i] = true;else tab[i] = false;
                    }
                }
            }

            this.callback(this.product, tab);
        }
    }, {
        key: "failStock",
        value: function failStock() {
            this.callback(null);
        }
    }]);

    return _class;
}();