"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.InitInternational = function () {
    function _class(callback) {
        _classCallCheck(this, _class);

        this.callback = callback;

        this.catalog_data;
        this.catalog_data_filters_us;
        this.catalog_data_ru;
        this.catalog_data_general;
        this.catalog_data_myproducts;
        this.ranges_data;
        this.countries_data;
        this.groups_data;
        this.catalog_competitors_data;
        this.catalog_addons_vg_specialities;
        this.rendercorp_gd_folders;
        this.mapping_colors;
        this.mapping_finish;
        this.models3d_list;

        this.new_vg = false;
        this.addons = null;
        this.sl_products = null;
        this.pf_exclude = null;

        if (app.Data.data.platform == "MyVerallia") {
            verallia.FileLoader.addFile("vg_sf_countries", "https://eservices-aws.verallia.com/digital-api/static/virtualglass2/json/vg_sf_countries.json", null, "GET", "service_meteor", true);
            verallia.FileLoader.load(this.init.bind(this), this.filesError.bind(this));
        } else {
            this.init();
        }
    }

    _createClass(_class, [{
        key: "init",
        value: function init() {
            //LOCAL
            if (window.location.href.indexOf("localhost") != -1 || app.Data.data.mode == "app") {
                if (app.Data.data.platform == "ICT" || app.Data.data.platform == "ICTCompetitors" || app.Data.data.platform == "VirtualGlass" || app.Data.data.platform == "VeralliaCom") {
                    verallia.FileLoader.addFile("catalog_data", app.Data.data.config.assets_path + "json/ct_myverallia_international.json");
                    if (app.Data.data.platform == "VeralliaCom" && app.Data.data.config.country.toLowerCase() == "us") {
                        verallia.FileLoader.addFile("catalog_data_filters_us", app.Data.data.config.assets_path + "json/ct_filter_products_us.json");
                    } else if (app.Data.data.platform == "VeralliaCom" && app.Data.data.config.country.toLowerCase() == "ru") {
                        verallia.FileLoader.addFile("catalog_data_ru", app.Data.data.config.assets_path + "json/ct_myverallia_ru.json");
                    }

                    if (app.Data.data.platform == "ICT") verallia.FileLoader.addFile("ct_addons_vg_specialities", app.Data.data.config.assets_path + "json/CATALOG_ADDONS_SPECIALTIES.json");
                } else if (app.Data.data.platform == "ICTPLS") {
                    verallia.FileLoader.addFile("catalog_data", app.Data.data.config.assets_path + "json/ct_myverallia_pls.json");
                } else if (app.Data.data.platform == "MyVerallia") {
                    verallia.FileLoader.addFile("catalog_data_general", app.Data.data.config.assets_path + "json/myverallia/ct_myverallia_product_list.json");
                    verallia.FileLoader.addFile("catalog_data_myproducts", app.Data.data.config.assets_path + "json/myverallia/ct_myverallia_myproduct_list.json");
                    verallia.FileLoader.addFile("ranges_data", app.Data.data.config.assets_path + "json/myverallia/ct_myverallia_ranges.json");
                    verallia.FileLoader.addFile("groups_data", app.Data.data.config.assets_path + "json/myverallia/ct_myverallia_groups.json");
                    verallia.FileLoader.addFile("countries_data", app.Data.data.config.assets_path + "json/myverallia/ct_myverallia_countries.json");
                }

                if (app.Data.data.platform == "ICTCompetitors") verallia.FileLoader.addFile("catalog_competitors_data", app.Data.data.config.distant_assets_path + "json/ct_myverallia_competitors.json");
                if (app.Data.data.platform == "VirtualGlass") {
                    verallia.FileLoader.addFile("rendercorp_gd_folders", app.Data.data.config.assets_path + "json/rendercorp_gd_folders.json");
                    verallia.FileLoader.addFile("ct_addons_vg_specialities", app.Data.data.config.assets_path + "json/CATALOG_ADDONS_SPECIALTIES.json");
                }

                verallia.FileLoader.addFile("stock_icons", app.Data.data.config.assets_path + "csv/stock_icons.csv");
                verallia.FileLoader.addFile("models3d_list", app.Data.data.config.assets_path + "json/bin_list.json");
                verallia.FileLoader.addFile("mapping_colors", app.Data.data.config.assets_path + "json/mapping_colors.json");
                verallia.FileLoader.addFile("mapping_finish", app.Data.data.config.assets_path + "json/mapping_finish.json");

                //MOBILE CORP
            } else if (window.location.href.indexOf("mobile.corp.inpkg.net") != -1) {

                //if(app.Data.data.platform == "ICT" || app.Data.data.platform == "ICTCompetitors" || app.Data.data.platform == "VirtualGlass") verallia.FileLoader.addFile("catalog_data", "https://mobile.corp.inpkg.net/ICT/static/assets/json/prod/ct_myverallia_international_V2.json");
                if (app.Data.data.platform == "ICT" || app.Data.data.platform == "ICTCompetitors" || app.Data.data.platform == "VirtualGlass") verallia.FileLoader.addFile("catalog_data", "https://assets.verallia.com/catalog/ct_myverallia_international.json");else if (app.Data.data.platform == "ICTPLS") verallia.FileLoader.addFile("catalog_data", "https://meteor.corp.inpkg.net/api/product-launch-sheets?format=catalog", null, "get", "service", true);
                if (app.Data.data.platform == "ICTCompetitors") verallia.FileLoader.addFile("catalog_competitors_data", app.Data.data.config.distant_assets_path + "json/ct_myverallia_competitors.json");
                if (app.Data.data.platform == "VirtualGlass") {
                    verallia.FileLoader.addFile("rendercorp_gd_folders", "https://render.corp.inpkg.net/files/EasyRender/DATABASE/scan.php?path=BOTTLES/", null, "post", "service", true);
                    //verallia.FileLoader.addFile("rendercorp_gd_folders", app.Data.data.config.assets_path + "json/rendercorp_gd_folders.json");
                    verallia.FileLoader.addFile("ct_addons_vg_specialities", app.Data.data.config.meteor_static_path + "CATALOG_ADDONS_SPECIALTIES", null, "get", "service", true);
                }

                verallia.FileLoader.addFile("mapping_colors", app.Data.data.config.assets_path + "json/mapping_colors.json");
                verallia.FileLoader.addFile("mapping_finish", app.Data.data.config.assets_path + "json/mapping_finish.json");
                //test

                //MYVERALLIA / SALESFORCE
            } else if (app.Data.data.platform == "MyVerallia") {
                verallia.FileLoader.addFile("mapping_colors", app.Data.data.config.assets_path + "json/mapping_colors.json");
                verallia.FileLoader.addFile("mapping_finish", app.Data.data.config.assets_path + "json/mapping_finish.json");

                try {
                    var vg_menu = $('.menu-container a[href*="Web_VirtualGlass"]').parent().attr("style");
                    var vg_access = false;
                    if (vg_menu == "display:block") vg_access = true;

                    if (mv_can_access_virtualglass == true || mv_can_access_virtualglass == "true" || vg_access == true) {
                        this.new_vg = true;
                    }
                } catch (e) {}

                if (this.new_vg == false) {
                    verallia.FileLoader.addFile("models3d_list", "https://assets.verallia.com/virtualglass/models.json", null, "get", "service", true);
                } else {
                    var url = "https://assets.verallia.com/qual/virtualglass/models.json";
                    if (app.Data.data.MVEnv == "prod") url = "https://assets.verallia.com/virtualglass/models.json";
                    verallia.FileLoader.addFile("models3d_list", url);
                }

                if (app.Data.data.mode == "VirtualGlass") {
                    if (app.Data.data.MVEnv == "qual") {
                        //verallia.FileLoader.addFile("rendercorp_gd_folders", "https://renderqual.corp.inpkg.net/files/EasyRender/DATABASE/scan.php?path=BOTTLES/", null, "post", "service", true);
                        verallia.FileLoader.addFile("rendercorp_gd_folders", "https://eservices-aws-qual.verallia.com/render/database/scan.php?path=BOTTLES/", null, "post", "service", true);
                        verallia.FileLoader.addFile("ct_addons_vg_specialities", "https://eservices-aws-qual.verallia.com/digital-api/static/virtualglass2/json/CATALOG_ADDONS_SPECIALTIES.json", null, "GET", "service_meteor", true);
                    } else {
                        //verallia.FileLoader.addFile("rendercorp_gd_folders", "https://render.corp.inpkg.net/files/EasyRender/DATABASE/scan.php?path=BOTTLES/", null, "post", "service", true);
                        verallia.FileLoader.addFile("rendercorp_gd_folders", "https://eservices-aws.verallia.com/render/database/scan.php?path=BOTTLES/", null, "post", "service", true);
                        verallia.FileLoader.addFile("ct_addons_vg_specialities", "https://eservices-aws.verallia.com/digital-api/static/virtualglass2/json/CATALOG_ADDONS_SPECIALTIES.json", null, "GET", "service_meteor", true);
                    }
                    //verallia.FileLoader.addFile("ct_addons_vg_specialities", app.Data.data.config.meteor_static_path + "CATALOG_ADDONS_SPECIALTIES", null, "get", "service", true);
                }

                if (app.Data.data.MVEnv == "qual") {
                    verallia.FileLoader.addFile("stock_icons", app.Data.data.config.assets_server + "static/catalog/CatalogSupplyData_qual.csv");
                } else {
                    verallia.FileLoader.addFile("stock_icons", app.Data.data.config.assets_server + "static/catalog/CatalogSupplyData_prod.csv");
                }

                //APPSDEV / APPSQUAL / APPS
            } else if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1 || window.location.href.indexOf("appsqual.corp.inpkg.net") != -1 || window.location.href.indexOf("apps.corp.inpkg.net") != -1) {
                /*var form_data = new FormData();
                form_data.append("path", "models3D");
                verallia.FileLoader.addFile("models3d_list", app.Data.data.config.meteor_vg_service_path + "scan", form_data, "POST", "service_meteor");*/

                var url = "https://assets.verallia.com/dev/virtualglass/models.json";
                if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1) url = "https://assets.verallia.com/qual/virtualglass/models.json";else if (window.location.href.indexOf("apps.corp.inpkg.net") != -1) url = "https://assets.verallia.com/virtualglass/models.json";

                verallia.FileLoader.addFile("models3d_list", url);

                var pls_json_url = "https://meteor.corp.inpkg.net/api/product-launch-sheets?format=catalog";
                if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1) pls_json_url = "https://meteordev.corp.inpkg.net/api/product-launch-sheets?format=catalog";else if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1) pls_json_url = "https://meteorqual.corp.inpkg.net/api/product-launch-sheets?format=catalog";

                if (app.Data.data.platform == "ICT" || app.Data.data.platform == "ICTCompetitors" || app.Data.data.platform == "VirtualGlass") {
                    if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1) {
                        verallia.FileLoader.addFile("catalog_data", app.Data.data.config.assets_path + "json/ct_myverallia_international.json");
                    } else {
                        //verallia.FileLoader.addFile("catalog_data", "https://assets.verallia.com/catalog/ct_myverallia_international.json");
                    }
                } else if (app.Data.data.platform == "ICTPLS") {
                    verallia.FileLoader.addFile("catalog_data", pls_json_url, null, "get", "service", true);
                }

                if (app.Data.data.platform == "ICTCompetitors") verallia.FileLoader.addFile("catalog_competitors_data", app.Data.data.config.distant_assets_path + "json/ct_myverallia_competitors.json");
                if (app.Data.data.platform == "VirtualGlass") {

                    if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1 || window.location.href.indexOf("appsqual.corp.inpkg.net") != -1) {
                        //verallia.FileLoader.addFile("rendercorp_gd_folders", "https://renderqual.corp.inpkg.net/files/EasyRender/DATABASE/scan.php?path=BOTTLES/", null, "post", "service", true);
                        verallia.FileLoader.addFile("rendercorp_gd_folders", "https://eservices-aws-qual.verallia.com/render/database/scan.php?path=BOTTLES/", null, "post", "service", true);
                        if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1) verallia.FileLoader.addFile("ct_addons_vg_specialities", "https://eservices-aws-qual.verallia.com/digital-api/static/virtualglass2/DEV/json/CATALOG_ADDONS_SPECIALTIES.json", null, "GET", "service_meteor", true);else verallia.FileLoader.addFile("ct_addons_vg_specialities", "https://eservices-aws-qual.verallia.com/digital-api/static/virtualglass2/json/CATALOG_ADDONS_SPECIALTIES.json", null, "GET", "service_meteor", true);
                    } else {
                        //verallia.FileLoader.addFile("rendercorp_gd_folders", "https://render.corp.inpkg.net/files/EasyRender/DATABASE/scan.php?path=BOTTLES/", null, "post", "service", true);
                        verallia.FileLoader.addFile("rendercorp_gd_folders", "https://eservices-aws.verallia.com/render/database/scan.php?path=BOTTLES/", null, "post", "service", true);
                        verallia.FileLoader.addFile("ct_addons_vg_specialities", "https://eservices-aws.verallia.com/digital-api/static/virtualglass2/json/CATALOG_ADDONS_SPECIALTIES.json", null, "GET", "service_meteor", true);
                    }

                    //verallia.FileLoader.addFile("rendercorp_gd_folders", app.Data.data.config.assets_path + "json/rendercorp_gd_folders.json");

                    //verallia.FileLoader.addFile("ct_addons_vg_specialities", app.Data.data.config.meteor_static_path + "CATALOG_ADDONS_SPECIALTIES", null, "get", "service", true);
                } else if (app.Data.data.platform == "ICT") {
                    //verallia.FileLoader.addFile("ct_addons_vg_specialities", app.Data.data.config.meteor_static_path + "CATALOG_ADDONS_SPECIALTIES", null, "get", "service", true);
                    if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1) verallia.FileLoader.addFile("ct_addons_vg_specialities", "https://eservices-aws-qual.verallia.com/digital-api/static/virtualglass2/DEV/json/CATALOG_ADDONS_SPECIALTIES.json", null, "GET", "service_meteor", true);else if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1) verallia.FileLoader.addFile("ct_addons_vg_specialities", "https://eservices-aws-qual.verallia.com/digital-api/static/virtualglass2/json/CATALOG_ADDONS_SPECIALTIES.json", null, "GET", "service_meteor", true);else verallia.FileLoader.addFile("ct_addons_vg_specialities", "https://eservices-aws.verallia.com/digital-api/static/virtualglass2/json/CATALOG_ADDONS_SPECIALTIES.json", null, "GET", "service_meteor", true);
                }

                if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1) {
                    verallia.FileLoader.addFile("stock_icons", app.Data.data.config.assets_server + "static/catalog/CatalogSupplyData_dev.csv");
                } else if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1) {
                    verallia.FileLoader.addFile("stock_icons", app.Data.data.config.assets_server + "static/catalog/CatalogSupplyData_qual.csv");
                } else if (window.location.href.indexOf("apps.corp.inpkg.net") != -1) {
                    verallia.FileLoader.addFile("stock_icons", app.Data.data.config.assets_server + "static/catalog/CatalogSupplyData_prod.csv");
                }

                verallia.FileLoader.addFile("mapping_colors", app.Data.data.config.assets_path + "json/mapping_colors.json");
                verallia.FileLoader.addFile("mapping_finish", app.Data.data.config.assets_path + "json/mapping_finish.json");

                //VERALLIACOM
            } else if (app.Data.data.platform == "VeralliaCom") {
                //verallia.FileLoader.addFile("catalog_data", "https://assets.verallia.com/catalog/ct_myverallia_international.json");
                verallia.FileLoader.addFile("mapping_colors", app.Data.data.config.assets_path + "json/mapping_colors.json");
                verallia.FileLoader.addFile("mapping_finish", app.Data.data.config.assets_path + "json/mapping_finish.json");
                if (app.Data.data.config.country.toLowerCase() == "us") verallia.FileLoader.addFile("catalog_data_filters_us", app.Data.data.config.assets_path + "json/ct_filter_products_us.json");else if (app.Data.data.config.country.toLowerCase() == "ru") verallia.FileLoader.addFile("catalog_data_ru", app.Data.data.config.assets_path + "json/ct_myverallia_ru.json");

                verallia.FileLoader.addFile("stock_icons", app.Data.data.config.assets_server + "static/catalog/CatalogSupplyData_prod.csv");
            }

            if (app.Data.data.platform == "ICT") {
                verallia.FileLoader.addFile("premium", app.Data.data.config.assets_path + "json/premiums.json");
            }

            //verallia.FileLoader.addFile("stock_icons", app.Data.data.config.assets_path + "csv/stock_icons.csv");
            verallia.FileLoader.addFile("addons", app.Data.data.config.assets_path + "json/addons.json");

            verallia.FileLoader.load(this.loadCatalogData.bind(this), this.filesError.bind(this));
        }
    }, {
        key: "loadCatalogData",
        value: function loadCatalogData() {
            if (app.Data.data.platform == "VeralliaCom") {
                verallia.FileLoader.addFile("catalog_data", "https://assets.verallia.com/catalog/ct_myverallia_international.json");
                verallia.FileLoader.load(this.filesLoaded.bind(this), this.loadBackupCatalogData.bind(this));
            } else if (window.location.href.indexOf("appsdev.corp.inpkg.net") != -1 || window.location.href.indexOf("appsqual.corp.inpkg.net") != -1 || window.location.href.indexOf("apps.corp.inpkg.net") != -1) {
                verallia.FileLoader.addFile("catalog_data", "https://assets.verallia.com/catalog/ct_myverallia_international.json");
                verallia.FileLoader.load(this.filesLoaded.bind(this), this.loadBackupCatalogData.bind(this));
            } else {
                this.filesLoaded();
            }
        }
    }, {
        key: "loadBackupCatalogData",
        value: function loadBackupCatalogData() {
            trace("Error loading catalog data file, loading backup...");

            verallia.FileLoader.addFile("catalog_data", "static/assets/json/ct_myverallia_international.json");

            verallia.FileLoader.load(this.filesLoaded.bind(this), this.filesError.bind(this));
        }
    }, {
        key: "filesLoaded",
        value: function filesLoaded() {
            if (app.Data.data.platform == "MyVerallia") app.Data.data.vg_sf_countries = verallia.FileLoader.get("vg_sf_countries");

            app.PlatformUtils.setPlatformConfig();
            app.Data.data.mto_data = {};
            if (app.Data.data.platform == "ICT") this.extractStockIconsData();
            this.initCatalogData();
            this.callback();
        }
    }, {
        key: "filesError",
        value: function filesError() {
            trace("files error !");
        }
    }, {
        key: "extractStockIconsData",
        value: function extractStockIconsData() {
            // NO LONGER EXISTS // app.Data.data.mto_data = verallia.FileLoader.get("addons").mto_data;


            try {
                var stock_icons = Papa.parse(verallia.FileLoader.get("stock_icons"));
                var a = stock_icons.data.length;
                for (var i = 0; i < a; i++) {
                    var data = stock_icons.data[i];
                    if (data[0].charAt(0) == "8") {
                        app.Data.data.stock_icons[data[0]] = data[3].toLowerCase();

                        var txt = data[3];
                        var tab = txt.split("\n").join("<br/>");

                        app.Data.data.mto_data[data[0]] = { text: tab, color: data[2].toLowerCase() };
                        //console.log(data[4]);
                        //if(data[5] && data[5] != "") app.Data.data.mto_data[data[0]].kcols = data[5];
                        //if(data[4] && data[4] != "") app.Data.data.mto_data[data[0]].weeks = data[4];
                    }
                }
            } catch (e) {}

            //trace(app.Data.data.stock_icons);
        }
    }, {
        key: "initCatalogData",
        value: function initCatalogData() {
            this.addons = verallia.FileLoader.get("addons");
            //if(window.location.href.indexOf("appsdev.corp.inpkg.net") != -1 || window.location.href.indexOf("appsqual.corp.inpkg.net") != -1 || window.location.href.indexOf("localhost") != -1) this.addons.sl_products = this.addons.sl_productsNEW;
            this.addons.sl_products = this.addons.sl_productsNEW;
            this.sl_products = this.addons.sl_products;
            this.sl_products = [];

            //extract SL list from stock icons data (new SL list)
            var stock_icons = Papa.parse(verallia.FileLoader.get("stock_icons"));
            var a = stock_icons.data.length;
            for (var i = 0; i < a; i++) {
                var data = stock_icons.data[i];
                if (data[0].charAt(0) == "8") {
                    this.sl_products.push(data[0]);
                }
            }

            this.pf_exclude = app.Data.data.pf_exclude = verallia.FileLoader.get("addons").pf_exclude;

            if (app.Data.data.platform == "ICT") this.premium = app.Data.data.premium = verallia.FileLoader.get("premium").premium_products;

            app.Data.data.displayMissingMarketsProcucts = false;
            app.Data.data.missingMarketsProducts = [];
            app.Data.data.baseproducts = {};
            this.catalog_data = verallia.FileLoader.get("catalog_data");
            this.mapping_colors = verallia.FileLoader.get("mapping_colors");
            this.mapping_finish = verallia.FileLoader.get("mapping_finish");
            this.models3d_list = verallia.FileLoader.get("models3d_list");

            try {
                this.models3d_list = JSON.parse(this.models3d_list);
            } catch (e) {
                if (this.models3d_list) this.models3d_list = this.models3d_list;
            }

            //VERALLIACOM
            if (app.Data.data.platform == "VeralliaCom") {
                if (app.Data.data.config.country.toLowerCase() == "us") {
                    this.catalog_data_filters_us = verallia.FileLoader.get("catalog_data_filters_us");
                    this.catalog_data.catalog.US = { ra: this.catalog_data.catalog.international.ra, mps: { mp: [] } };
                    this.filterUSProducts();
                } else if (app.Data.data.config.country.toLowerCase() == "ru") {
                    this.catalog_data_ru = verallia.FileLoader.get("catalog_data_ru");
                    this.catalog_data.catalog.RU = this.catalog_data_ru.catalog.RU;
                }
                app.Data.data.config.countries = [app.Data.data.config.country.toLowerCase()];
            }

            //MYVERALLIA
            if (app.Data.data.platform == "MyVerallia") {
                if (window.location.href.indexOf("localhost") != -1 || app.Data.data.mode == "app") {
                    this.catalog_data_general = verallia.FileLoader.get("catalog_data_general");
                    this.catalog_data_myproducts = verallia.FileLoader.get("catalog_data_myproducts");
                    this.ranges_data = verallia.FileLoader.get("ranges_data");
                    this.countries_data = verallia.FileLoader.get("countries_data");
                    this.groups_data = verallia.FileLoader.get("groups_data");
                } else {
                    this.catalog_data_general = JSON.parse(ct_myverallia_productlist_url);
                    this.catalog_data_myproducts = JSON.parse(ct_myverallia_myproductlist_url);
                    this.ranges_data = JSON.parse(ct_myverallia_ranges_url);
                    this.countries_data = JSON.parse(ct_myverallia_countries);
                    this.groups_data = JSON.parse(ct_myverallia_groups_url);
                }

                //check cointreau & gothic flute account users

                try {
                    if (this.addons.cointeau_access.indexOf(mv_user_contactid) != -1) {
                        trace("adding countreau for user ID " + mv_user_contactid);

                        this.catalog_data_general.mps.mp.push(this.addons.vitamine_cointreau);
                    }
                } catch (e) {}

                try {
                    if (this.addons.gothic_access.indexOf(mv_user_contactid) != -1) {
                        trace("adding flute gothic for user ID " + mv_user_contactid);

                        this.catalog_data_general.mps.mp.push(this.addons.flute_gothic);
                    }
                } catch (e) {}

                var a = this.catalog_data_myproducts.mps.mp.length;
                for (var i = 0; i < a; i++) {
                    var p = this.catalog_data_myproducts.mps.mp[i];
                    p.myproduct = true;
                    //p.id = p.id + "_" + verallia.StringUtils.generateUID();
                    p.id = p.id + "_p";
                }

                this.catalog_data_general.mps.mp = this.catalog_data_general.mps.mp.concat(this.catalog_data_myproducts.mps.mp);

                this.catalog_data = { catalog: { international: {} } };
                this.catalog_data.catalog.international.co = this.countries_data.co;
                //app.Data.data.config.country = "FR";
                this.catalog_data.catalog[app.Data.data.config.country] = { mps: { mp: this.catalog_data_general.mps.mp }, ra: this.ranges_data.ra };
            }

            //ICTPLS
            if (app.Data.data.platform == "ICTPLS") {
                this.catalog_data = { catalog: this.catalog_data.data };
            }

            //ICTCOMPETITORS
            if (app.Data.data.platform == "ICTCompetitors") {
                this.catalog_competitors_data = verallia.FileLoader.get("catalog_competitors_data");
                this.addCompetitorsBaseproducts();
            }

            //VIRTUALGLASS
            if (app.Data.data.platform == "VirtualGlass" || app.Data.data.mode == "VirtualGlass") {
                //this.rendercorp_gd_folders = verallia.FileLoader.get("rendercorp_gd_folders");
                if (window.location.href.indexOf("localhost") != -1 || app.Data.data.mode == "app") this.rendercorp_gd_folders = verallia.FileLoader.get("rendercorp_gd_folders");else this.rendercorp_gd_folders = JSON.parse(verallia.FileLoader.get("rendercorp_gd_folders"));

                if (window.location.href.indexOf("localhost") != -1 || app.Data.data.mode == "app") this.catalog_addons_vg_specialities = verallia.FileLoader.get("ct_addons_vg_specialities").data;else this.catalog_addons_vg_specialities = verallia.FileLoader.get("ct_addons_vg_specialities").data;

                this.addEasyRenderBaseproducts();
            } else if (app.Data.data.platform == "ICT") {
                if (window.location.href.indexOf("localhost") != -1 || app.Data.data.mode == "app") this.catalog_addons_vg_specialities = verallia.FileLoader.get("ct_addons_vg_specialities").data;else this.catalog_addons_vg_specialities = verallia.FileLoader.get("ct_addons_vg_specialities").data;

                this.addEasyRenderBaseproducts();
            }

            // MAPPING FOR INTERNATIONAL CATALOG (Germany and Portugal subranges)
            if (app.Data.data.platform != "MyVerallia") {
                var ranges_mapping = {};
                var a = this.catalog_data.catalog.international.ra.length;
                for (var i = 0; i < a; i++) {
                    if (this.catalog_data.catalog.international.ra[i].la.toLowerCase() == "oils & vinegars") {
                        ranges_mapping.oils = this.catalog_data.catalog.international.ra[i];
                    } else if (this.catalog_data.catalog.international.ra[i].la.toLowerCase() == "still wines") {
                        ranges_mapping.still_wines = this.catalog_data.catalog.international.ra[i];

                        var b = this.catalog_data.catalog.international.ra[i].sr.length;
                        for (var j = 0; j < b; j++) {
                            if (this.catalog_data.catalog.international.ra[i].sr[j].la.toLowerCase() == "port wines and cherry") {
                                ranges_mapping.port_wines_cherry = this.catalog_data.catalog.international.ra[i].sr[j];
                            }
                        }
                    }
                }
            }

            //CREATE FILTERS
            app.Data.data.filters = {};
            app.Data.data.filters.height = { min: 10000, max: 0, selected_min: 0, selected_max: 0 };
            app.Data.data.filters.diameter = { min: 10000, max: 0, selected_min: 0, selected_max: 0 };
            app.Data.data.filters.weight = { min: 10000, max: 0, selected_min: 0, selected_max: 0 };
            app.Data.data.filters.volume = { min: 10000, max: 0, selected_min: 0, selected_max: 0 };
            app.Data.data.filters.vg = false;
            app.Data.data.filters.sl = false;
            app.Data.data.filters.type = { standard: false, special: false };
            app.Data.data.filters.develop_ready = false;
            app.Data.data.filters.vg_objects = false;
            app.Data.data.filters.premium = false;
            app.Data.data.filters.brands = {};
            app.Data.data.filters.pls_dates = { min: null, max: null, selected_min: null, selected_max: null, dates: {} };
            app.Data.data.filters.pls_cdates = { min: null, max: null, selected_min: null, selected_max: null, dates: {} };
            app.Data.data.filters.pls_clients = { elements: [], selected: [] };
            app.Data.data.filters.pls_salesoffice = { elements: [], selected: [] };
            app.Data.data.filters.pls_decorators = {};
            app.Data.data.filters.pls_contents = {};
            app.Data.data.filters.pls_commercials = { elements: [], selected: [] };
            app.Data.data.filters.pls_communication = {};
            app.Data.data.filters.pls_decoration = false;
            app.Data.data.filters.pls_type_standard = false;
            app.Data.data.filters.pls_yearbook = false;
            app.Data.data.filters.pls_countrypublication = {};
            for (var str in app.Data.data.config.company_code) {
                app.Data.data.filters.pls_countrypublication[str] = false;
            }

            //CREATE COUNTRIES
            app.Data.data.filters.countries = {};
            var a = app.Data.data.config.countries.length;
            for (var i = 0; i < a; i++) {
                app.Data.data.filters.countries[app.Data.data.config.countries[i]] = { id: app.Data.data.config.countries[i], selected: true };
            }

            //CREATE RANGES
            app.Data.data.filters.ranges = {};
            var ranges;
            //trace(this.catalog_data);
            if (app.Data.data.config.country != "international") ranges = this.catalog_data.catalog[app.Data.data.config.country.toUpperCase()].ra;else ranges = this.catalog_data.catalog.international.ra;

            var a = ranges.length;
            for (var i = 0; i < a; i++) {
                var range = ranges[i];
                if (range.la == "toignore" || range.la == "Selective Line") continue;

                app.Data.data.filters.ranges[range.id] = { id: range.id, label: range.la, sub: {}, selected: false };

                if (app.Data.data.platform == "MyVerallia" && range.ids == "true") {
                    app.Data.data.filters.ranges[range.id].default_selected = true;
                    app.Data.data.filters.ranges[range.id].selected = true;
                }

                if (range.sr) {
                    var b = range.sr.length;
                    for (var j = 0; j < b; j++) {
                        if (!app.Data.data.filters.ranges[range.id].sub[range.sr[j].id]) {
                            app.Data.data.filters.ranges[range.id].sub[range.sr[j].id] = { id: range.sr[j].id, label: range.sr[j].la, selected: false };
                        }
                    }
                }
            }

            //CREATE GROUPS / ACCOUNTS FOR MYVERALLIA
            if (app.Data.data.platform == "MyVerallia") {
                app.Data.data.filters.myverallia_groups = this.groups_data;
                app.Data.data.filters.myverallia_groups.gr.unshift({ id: "all", na: app.Data.data.loc.interface.all_groups });
                app.Data.data.filters.myverallia_groups.cwg.unshift({ id: "all", sn: app.Data.data.loc.interface.all_accounts });
                app.Data.data.filters.myverallia_groups.selected_group = "all";
                app.Data.data.filters.myverallia_groups.selected_account = "all";

                var a = app.Data.data.filters.myverallia_groups.cwg.length;
                for (var i = 0; i < a; i++) {
                    app.Data.data.filters.myverallia_groups.cwg[i].disabled = true;
                }
            }

            //CREATE COLORS MAPPING
            app.Data.data.filters.colors = {};
            var a = this.mapping_colors.colors.length;
            for (var i = 0; i < a; i++) {
                var color = this.mapping_colors.colors[i];
                color.selected = false;
                color.sub = {};
                app.Data.data.filters.colors[color.cs] = color;
            }

            //CREATE FINISH MAPPING
            app.Data.data.filters.finish = {};
            var a = this.mapping_finish.finish.length;
            for (var i = 0; i < a; i++) {
                var finish = this.mapping_finish.finish[i];
                finish.selected = false;
                finish.sub = {};
                app.Data.data.filters.finish[finish.id] = finish;
            }

            //EXTRACT PRODUCTS AND MERGE PRODUCTS WITH SAME ID
            var a = app.Data.data.config.countries.length;
            for (var i = 0; i < a; i++) {
                var country = app.Data.data.config.countries[i];

                if (!this.catalog_data.catalog[country.toUpperCase()]) continue;

                var country_products = this.catalog_data.catalog[country.toUpperCase()].mps.mp;

                var b = country_products.length;
                var b = country_products.length;
                trace("products in " + country + " = " + b);
                for (var j = 0; j < b; j++) {
                    var baseproduct = country_products[j];

                    if (app.Data.data.platform == "ICTPLS") {
                        //baseproduct.id = baseproduct.id + verallia.StringUtils.generateUID();

                        //fake contents to remove
                        var content = ["Eau", "Vin", "Coca", "Bière"];
                        baseproduct.pls.content = content[Math.round(Math.random() * 3)];
                        if (baseproduct.pls.filling) baseproduct.pls.content = baseproduct.pls.filling;else baseproduct.pls.content = "Empty";

                        //if(!baseproduct.la || baseproduct.la == ""){
                        baseproduct.la = baseproduct.pls.baseProductCustomName;
                        //}

                        if (!baseproduct.pls.salesGroupFirstName || baseproduct.pls.salesGroupFirstName == undefined) baseproduct.pls.salesGroupFirstName = "";
                        if (!baseproduct.pls.salesGroupLastName || baseproduct.pls.salesGroupLastName == undefined) baseproduct.pls.salesGroupLastName = "";

                        if (baseproduct.pls.salesGroupFirstName == "") baseproduct.pls.commercial = baseproduct.pls.salesGroupLastName;else if (baseproduct.pls.salesGroupFirstName != "" && baseproduct.pls.salesGroupLastName == "") baseproduct.pls.commercial = baseproduct.pls.salesGroupFirstName;else if (baseproduct.pls.salesGroupFirstName == "" && baseproduct.pls.salesGroupLastName == "") baseproduct.pls.commercial = "";else baseproduct.pls.commercial = baseproduct.pls.salesGroupFirstName + " " + baseproduct.pls.salesGroupLastName;

                        if (!baseproduct.pls.images) baseproduct.pls.images = [];
                        if (!baseproduct.pls.isDecorated) baseproduct.pls.isDecorated = false;
                        if (!baseproduct.pls.decoration) baseproduct.pls.decoration = "";
                    } else if (app.Data.data.platform == "ICT") {
                        baseproduct.ac = baseproduct.ch;
                    }

                    baseproduct.pr = verallia.ObjectUtils.makeArrayFromObject(baseproduct.pr);
                    if (baseproduct.pr.length == 0) {
                        /*if(country.toLowerCase() == "fr"){
                            trace(baseproduct.la);
                        }*/
                        continue;
                    }

                    baseproduct.special = false;
                    if (baseproduct.from_int_json != false) baseproduct.from_int_json = true;

                    baseproduct.country = country;
                    baseproduct.countries = [country];

                    baseproduct.index = baseproduct.selected_index = 0;
                    baseproduct.colors = [];
                    if (!baseproduct.gd || baseproduct.gd == "null") {
                        //trace(baseproduct.la + " has no gd");
                        baseproduct.gd = "null";
                    }
                    if (!baseproduct.br || baseproduct.br == "" || baseproduct.br == "null") baseproduct.br = "Verallia";

                    if (app.Data.data.platform != "ICTPLS") {
                        if (baseproduct.gd.indexOf(".PDF") != -1) baseproduct.gd = baseproduct.gd.replace(".PDF", "");
                        if (baseproduct.gd.indexOf(".pdf") != -1) baseproduct.gd = baseproduct.gd.replace(".pdf", "");

                        if (baseproduct.vg == "true") baseproduct.vg = true;else baseproduct.vg = false;
                        baseproduct.vg = this.checkVGFlagFromModels3DList(baseproduct);
                    }

                    //force specialities to be used in qual and prod
                    if (baseproduct.force_vg == true) {
                        if (window.location.href.indexOf("appsqual.corp.inpkg.net") != -1 || window.location.href.indexOf("apps.corp.inpkg.net") != -1) {
                            baseproduct.vg = true;
                        }
                    }
                    if (app.Data.data.platform == "VirtualGlass" || app.Data.data.mode == "VirtualGlass") {
                        if (this.checkRenderGDExistance(baseproduct) == false) continue;
                    }

                    //trace(baseproduct.gd + " " + baseproduct.easyrender);

                    var c = baseproduct.pr.length;
                    for (var k = 0; k < c; k++) {
                        this.checkProductArea(baseproduct, baseproduct.pr[k]);
                        baseproduct.pr[k].countries = [baseproduct.country];
                        baseproduct.pr[k].country = baseproduct.country;
                        if (baseproduct.country != "ru") baseproduct.pr[k].cb = baseproduct.pr[k].cb.substring(2);
                        baseproduct.pr[k].decoration = false;
                        baseproduct.pr[k].favorite = false;

                        baseproduct.pr[k].salesOrg = [baseproduct.pr[k].so];

                        //TEMP PREMIUM FILTER
                        if (app.Data.data.config.filters.use_premium == true) {
                            if (app.Data.data.premium.indexOf(baseproduct.pr[k].cb) != -1) {
                                baseproduct.pr[k].premium = true;
                            }
                        }

                        if (app.Data.data.platform != "ICTPLS") {
                            //TEMP SOLUTION TO FLAG PRODUCTS TO SELECTIVE LINE
                            if (this.sl_products) {
                                if (this.sl_products.indexOf(baseproduct.pr[k].cb) != -1) {
                                    //trace(baseproduct.pr[k].cb + " is sl");

                                    baseproduct.pr[k].mg = 'NAAC';
                                } else {
                                    if (baseproduct.pr[k].mg == 'NAAC') baseproduct.pr[k].mg = 'NAAA';
                                }
                            }
                        } else {}

                        if (baseproduct.pr[k].sp) {
                            if (baseproduct.pr[k].sp == "true" || baseproduct.pr[k].sp == true) baseproduct.pr[k].sp = baseproduct.pr[k].special = baseproduct.special = true;else baseproduct.pr[k].sp = baseproduct.pr[k].special = baseproduct.special = false;
                        } else {
                            baseproduct.pr[k].special = false;
                        }

                        if (!baseproduct.pr[k].mg) {
                            baseproduct.pr[k].mg = "null";
                        } else if (baseproduct.pr[k].mg.toLowerCase() == "naac") {
                            baseproduct.pr[k].br = "Selective Line";
                        }

                        if (app.Data.data.platform == "ICT") {
                            if (baseproduct.pr[k].br == "Selective Line") {
                                baseproduct.br = baseproduct.pr[k].br = "Verallia";
                            }
                        } else if (app.Data.data.platform == "ICT" || app.Data.data.platform == "ICTCompetitors") {
                            if (baseproduct.pr[k].br == "VOA" || baseproduct.pr[k].br == "Selective Line") {
                                baseproduct.br = baseproduct.pr[k].br = "Verallia";
                            }
                        } else if (app.Data.data.platform == "ICTPLS") {
                            baseproduct.id = baseproduct.pls._id;
                            baseproduct.br = baseproduct.pr[k].br = baseproduct.pls.brand;

                            //A RETIRER UNE FOIS CORRIG2 DANS LES DATA
                            if (baseproduct.pls.launchCountryName == "ES") baseproduct.pls.launchCountryName = "SP";

                            baseproduct.country = baseproduct.pr[k].country = baseproduct.pls.launchCountryName.toLowerCase();
                            baseproduct.countries = baseproduct.pr[k].countries = [baseproduct.country];
                            if (baseproduct.pls.brand.toLowerCase() == "selective line") baseproduct.pr[0].mg = "NAAC";
                        }

                        //MYVERALLIA
                        if (app.Data.data.platform == "MyVerallia") {
                            baseproduct.pr[k].fip = verallia.ObjectUtils.makeArrayFromObject(baseproduct.pr[k].fip);
                            baseproduct.pr[k].selected_line = 0;

                            var d = baseproduct.pr[k].fip.length;
                            for (var l = 0; l < d; l++) {
                                baseproduct.pr[k].fip[l].cf = baseproduct.pr[k].fip[l].cf.substring(2);
                                baseproduct.pr[k].fip[l].quantity = 1;

                                if (baseproduct.myproduct == true) {
                                    if (baseproduct.pr[k].fip[l].mg.toLowerCase() == "naab" || baseproduct.pr[k].fip[l].mg.toLowerCase() == "naad" || baseproduct.pr[k].fip[l].mg.toLowerCase() == "nabb") {

                                        baseproduct.pr[k].fip[l].decoration = true;
                                        baseproduct.pr[k].decoration = true;
                                    } else {
                                        baseproduct.pr[k].fip[l].decoration = false;
                                    }

                                    if (baseproduct.myproduct == true) {
                                        baseproduct.pr[k].fip[l].ac = verallia.ObjectUtils.makeArrayFromObject(baseproduct.pr[k].fip[l].ac);
                                        var e = baseproduct.pr[k].fip[l].ac.length;
                                        for (var m = 0; m < e; m++) {
                                            var f = app.Data.data.filters.myverallia_groups.cwg.length;
                                            for (var n = 0; n < f; n++) {
                                                if (app.Data.data.filters.myverallia_groups.cwg[n].id == baseproduct.pr[k].fip[l].ac[m].id) {
                                                    baseproduct.pr[k].fip[l].favorite = true;
                                                    baseproduct.pr[k].favorite = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            for (var m = 0; m < baseproduct.pr[k].fip.length; m++) {
                                var index = this.pf_exclude.indexOf(baseproduct.pr[k].fip[m].cf);
                                var is_speciality = false;
                                var pf_speciality = baseproduct.pr[k].fip[m].stsp;
                                if (pf_speciality) {
                                    if (pf_speciality.toLowerCase() == "st" || pf_speciality.toLowerCase() == "sr") is_speciality = false;else is_speciality = true;
                                }

                                //if(is_speciality == true) trace(baseproduct.pr[k].fip[m].cf + " is speciality");

                                if (index != -1 || is_speciality == true && !baseproduct.myproduct) {
                                    //trace("removing " + baseproduct.pr[k].fip[m].cf);
                                    baseproduct.pr[k].fip.splice(m, 1);
                                }
                            }
                        }
                    }

                    baseproduct.ga = verallia.ObjectUtils.makeArrayFromObject(baseproduct.ga);
                    baseproduct.fo = verallia.ObjectUtils.makeArrayFromObject(baseproduct.fo);

                    if (app.Data.data.platform != "MyVerallia" && app.Data.data.platform != "VeralliaCom") {
                        baseproduct.gaI = verallia.ObjectUtils.makeArrayFromObject(baseproduct.gaI);
                        baseproduct.foI = verallia.ObjectUtils.makeArrayFromObject(baseproduct.foI);
                        baseproduct.gaC = verallia.ObjectUtils.copy(baseproduct.ga);
                        baseproduct.foC = verallia.ObjectUtils.copy(baseproduct.fo);
                        baseproduct.ga = baseproduct.gaI;
                        baseproduct.fo = baseproduct.foI;
                    }

                    if (!baseproduct.fi || baseproduct.fi == "" || baseproduct.fi == "null") baseproduct.fi = "empty";
                    if (!baseproduct.di || baseproduct.di == "" || baseproduct.di == "null" || baseproduct.di == "0.00") baseproduct.di = "123";
                    if (!baseproduct.he || baseproduct.he == "" || baseproduct.he == "null" || baseproduct.he == "0.00") baseproduct.he = "10";
                    if (!baseproduct.we || baseproduct.we == "" || baseproduct.we == "null" || baseproduct.we == "0.00") baseproduct.we = "10";
                    if (!baseproduct.fh || baseproduct.fh == "" || baseproduct.fh == "null" || baseproduct.fh == "0.00") baseproduct.fh = "10";
                    if (!baseproduct.oc || baseproduct.oc == "" || baseproduct.oc == "null" || baseproduct.oc == "0.00") baseproduct.oc = "10";

                    baseproduct.vo = parseInt(baseproduct.vo);
                    baseproduct.oc = parseInt(baseproduct.oc);
                    baseproduct.di = parseInt(baseproduct.di);
                    baseproduct.he = Math.round(parseInt(baseproduct.he));
                    baseproduct.we = Math.round(parseInt(baseproduct.we));
                    baseproduct.fh = parseInt(baseproduct.fh);
                    baseproduct.oc = parseInt(baseproduct.oc);

                    //if(Number(baseproduct.vo) >= 15000) trace(baseproduct.vo + "ML     :     " + baseproduct.la);


                    // MAPPING FOR INTERNATIONAL CATALOG (Germany and Portugal subranges)
                    if (app.Data.data.config.country == "international") {
                        if (country == "de") {
                            var c = baseproduct.foC.length;
                            for (var k = 0; k < c; k++) {
                                if (baseproduct.foC[k].la.toLowerCase() == "oil/ vinegar") {
                                    baseproduct.ga = [ranges_mapping.oils];
                                }
                            }
                        } else if (country == "pt") {
                            var c = baseproduct.gaC.length;
                            for (var k = 0; k < c; k++) {
                                if (baseproduct.gaC[k].la.toLowerCase() == "port wine") {
                                    baseproduct.ga = [ranges_mapping.still_wines];
                                    baseproduct.fo = [ranges_mapping.port_wines_cherry];
                                }
                            }
                        }
                    }

                    this.createNumberValuesOnBaseproduct(baseproduct);

                    if (!app.Data.data.baseproducts[baseproduct.id]) {
                        app.Data.data.baseproducts[baseproduct.id] = baseproduct;
                        app.Data.data.baseproducts_id[baseproduct.id] = {};
                        if (baseproduct.gd && app.Data.data.platform != "ICTPLS") app.Data.data.baseproducts_gd[app.Utils.getGDWithoutVersion(baseproduct).toUpperCase()] = {};
                    } else {
                        //if(country == "fr") trace(baseproduct.id + " already exists");
                        if (app.Data.data.baseproducts[baseproduct.id].countries.indexOf(country) == -1) app.Data.data.baseproducts[baseproduct.id].countries.push(country);
                        //if(app.Data.data.config.country == "international"){
                        if (app.Data.data.platform != "ICTPLS") this.mergeSameBaseProducts(baseproduct);
                        //}
                    }
                }
            }

            if (app.Data.data.platform == "VirtualGlass" && window.location.href.indexOf("appsqual.corp.inpkg.net") == -1 && window.location.href.indexOf("apps.corp.inpkg.net") == -1) {
                //ONLY FOR TEST AND DEV PURPOSES, CLEAN DATA ON QUAL AND PROD
                this.addDummyProductsFromRenderCorp();
            }

            //EXTRACT FILTERS FROM BASEPRODUCTS AND PRODUCTS
            for (var str in app.Data.data.baseproducts) {
                var baseproduct = app.Data.data.baseproducts[str];

                //EXTRACT HEIGHT
                if (app.Data.data.config.filters.use_height == true) {
                    if (parseInt(baseproduct.he) < app.Data.data.filters.height.min) {
                        app.Data.data.filters.height.min = app.Data.data.filters.height.selected_min = parseInt(baseproduct.he);
                    }
                    if (parseInt(baseproduct.he) > app.Data.data.filters.height.max) {
                        app.Data.data.filters.height.max = app.Data.data.filters.height.selected_max = parseInt(baseproduct.he);
                    }
                }

                //EXTRACT DIAMETER
                if (app.Data.data.config.filters.use_diameter == true) {
                    if (parseInt(baseproduct.di) < app.Data.data.filters.diameter.min) app.Data.data.filters.diameter.min = app.Data.data.filters.diameter.selected_min = parseInt(baseproduct.di);
                    if (parseInt(baseproduct.di) > app.Data.data.filters.diameter.max) app.Data.data.filters.diameter.max = app.Data.data.filters.diameter.selected_max = parseInt(baseproduct.di);
                }

                //EXTRACT WEIGHT
                if (app.Data.data.config.filters.use_weight == true) {
                    if (parseInt(baseproduct.we) < app.Data.data.filters.weight.min) app.Data.data.filters.weight.min = app.Data.data.filters.weight.selected_min = parseInt(baseproduct.we);
                    if (parseInt(baseproduct.we) > app.Data.data.filters.weight.max) app.Data.data.filters.weight.max = app.Data.data.filters.weight.selected_max = parseInt(baseproduct.we);
                }

                //EXTRACT VOLUME
                if (app.Data.data.config.filters.use_volume == true) {
                    if (parseInt(baseproduct.vo) / 10 < app.Data.data.filters.volume.min) app.Data.data.filters.volume.min = app.Data.data.filters.volume.selected_min = parseInt(baseproduct.vo) / 10;
                    if (parseInt(baseproduct.vo) / 10 > app.Data.data.filters.volume.max) app.Data.data.filters.volume.max = app.Data.data.filters.volume.selected_max = parseInt(baseproduct.vo) / 10;

                    if (app.Data.data.config.filters.values_checkbox.volume) {
                        app.Data.data.config.filters.values_checkbox.volume[0].values[0] = app.Data.data.filters.volume.min;
                        app.Data.data.config.filters.values_checkbox.volume[app.Data.data.config.filters.values_checkbox.volume.length - 1].values[1] = app.Data.data.filters.volume.max;
                    }
                }

                //EXTRACT FINISH
                if (app.Data.data.config.filters.use_finish == true) {
                    this.addFinishToFilters(baseproduct);
                }

                //EXTRACT BRANDS
                if (app.Data.data.config.filters.use_brands == true) {
                    this.addBrandToFilters(baseproduct);
                }

                //EXTRACT PLS FILTERS
                if (app.Data.data.platform == "ICTPLS") {
                    //EXTRACT PLS DATES
                    if (app.Data.data.config.filters.use_pls_dates == true) this.addDateToFilters(baseproduct);

                    //EXTRACT PLS CREATION DATES
                    if (app.Data.data.config.filters.use_pls_cdates == true) this.addCDateToFilters(baseproduct);

                    //EXTRACT PLS CLIENTS
                    if (app.Data.data.config.filters.use_pls_clients == true) this.addClientToFilters(baseproduct);

                    //EXTRACT PLS SALESOFFICE
                    if (app.Data.data.config.filters.use_pls_salesoffice == true) this.addSalesOfficeToFilters(baseproduct);

                    //EXTRACT PLS DECORATORS
                    if (app.Data.data.config.filters.use_pls_decorators == true) this.addDecoratorToFilters(baseproduct);

                    //EXTRACT PLS CONTENTS
                    if (app.Data.data.config.filters.use_pls_contents == true) this.addContentsToFilters(baseproduct);

                    //EXTRACT PLS COMMUNICATION
                    if (app.Data.data.config.filters.use_pls_communication == true) this.addCommunicationToFilters(baseproduct);

                    //EXTRACT PLS COMMERCIALS
                    if (app.Data.data.config.filters.use_pls_commercials == true) {
                        this.addCommercialsToFilters(baseproduct);
                        app.Data.data.filters.pls_commercials;
                    }
                }

                var a = baseproduct.pr.length;
                for (var i = 0; i < a; i++) {
                    var product = baseproduct.pr[i];

                    //EXTRACT COLORS
                    if (app.Data.data.config.filters.use_colors == true) {
                        this.addColorToFilters(product, baseproduct.country);
                    }
                }
            }

            if (app.Data.data.platform == "ICT") {
                var prod = app.Utils.getAllMetaProducts();
                for (var str in prod) {
                    var p = prod[str];

                    if (p.ga.length == 0) {
                        app.Data.data.missingMarketsProducts.push(p.la + " : " + p.br + " - " + app.Data.data.loc.global.countries[p.country].label);
                        for (var g = 0; g < p.pr.length; g++) {
                            app.Data.data.missingMarketsProducts.push(p.pr[g].cb + " : " + p.pr[g].cl_en + " - " + p.pr[g].cl);
                        }

                        app.Data.data.missingMarketsProducts.push("<br/>");
                    }
                }

                if (app.Data.data.missingMarketsProducts.length > 0) {
                    trace("app.Data.data.missingMarketsProducts");
                    trace(app.Data.data.missingMarketsProducts);
                    app.Data.data.displayMissingMarketsProcucts = true;
                }
            }

            delete app.Data.data.filters.colors.mapping;
            delete app.Data.data.filters.finish.mapping;

            //trace(Object.keys(app.Data.data.baseproducts).length);
        }
    }, {
        key: "checkProductArea",
        value: function checkProductArea(baseproduct, product) {
            if (!product.ai || product.ai == "") {
                if (baseproduct.ch && baseproduct.ch != "") {
                    product.ai = app.Data.data.config.company_code[baseproduct.ch];
                }
            }
        }
    }, {
        key: "mergeSameBaseProducts",
        value: function mergeSameBaseProducts(baseproduct) {
            if (baseproduct.competitor == true || baseproduct.develop_ready == true) return;

            var original_baseproduct = app.Data.data.baseproducts[baseproduct.id];

            var a = original_baseproduct.pr.length;
            for (var i = 0; i < a; i++) {
                this.mergeSameProducts(original_baseproduct, original_baseproduct.pr[i], baseproduct, i);
            }
        }
    }, {
        key: "mergeSameProducts",
        value: function mergeSameProducts(original_baseproduct, original_product, baseproduct, original_bp_index) {
            if (baseproduct.competitor == true || original_baseproduct.competitor == true) [];

            var productOwnerBP = null;

            var exist = [];
            var a = baseproduct.pr.length;
            for (var i = 0; i < a; i++) {
                if (original_product.cs == baseproduct.pr[i].cs) {
                    if (baseproduct.pr[i].po && app.Data.data.platform == "ICT") {

                        //this checks the procuct owner of a baseproduct
                        var co = app.Utils.getCountryFromCompanyCode(baseproduct.pr[i].po);

                        if (co && co == baseproduct.pr[i].country) {
                            productOwnerBP = baseproduct.pr[i];
                        }
                    }

                    if (original_product.countries.indexOf(baseproduct.country) == -1) {
                        original_product.countries.push(baseproduct.country);
                    }

                    if (original_product.salesOrg.indexOf(baseproduct.pr[i].so) == -1) {
                        original_product.salesOrg.push(baseproduct.pr[i].so);
                    }

                    if (productOwnerBP) {
                        // trace("");
                        // trace("");
                        // trace("");
                        // trace("productOwner");
                        original_baseproduct.pr[original_bp_index] = productOwnerBP;
                        // trace(productOwnerBP);
                        // trace(original_product);
                        // trace("");
                        // trace("");
                        // trace("");
                    }
                } else {
                    var exists = false;
                    var b = original_baseproduct.pr.length;
                    for (var j = 0; j < b; j++) {
                        if (original_baseproduct.pr[j].cs == baseproduct.pr[i].cs) {
                            exists = true;
                        }
                    }

                    if (exists == false) {
                        original_baseproduct.pr.push(baseproduct.pr[i]);
                    }
                }
            }
        }
    }, {
        key: "addColorToFilters",
        value: function addColorToFilters(product) {
            for (var str in app.Data.data.filters.colors) {
                var color = app.Data.data.filters.colors[str];

                if (color.mapping[product.country.toLowerCase()] && color.mapping[product.country.toLowerCase()][product.cs] && !color.sub[product.cs]) {
                    if (!window.colors) window.colors = {};

                    if (!window.colors[product.cs]) {
                        window.colors[product.cs] = {};
                        window.colors[product.cs].hexa = product.cc;
                        window.colors[product.cs].label = product.cl_en.toLowerCase();
                    }

                    if (!window.colors[product.cs]["label_" + product.country]) window.colors[product.cs]["label_" + product.country] = product.cl;

                    if (app.Data.data.platform == "MyVerallia" || app.Data.data.platform == "VeralliaCom") {
                        color.sub[product.cs] = { cs: product.cs, label_en: product.cl_en.toLowerCase(), label: product.cl.toLowerCase(), cc: product.cc, selected: false };
                    } else {
                        color.sub[product.cs] = { cs: product.cs, label_en: product.cl_en.toLowerCase(), label: product.cl_en.toLowerCase(), cc: product.cc, selected: false };
                    }
                }
            }
        }
    }, {
        key: "addFinishToFilters",
        value: function addFinishToFilters(baseproduct) {
            for (var str in app.Data.data.filters.finish) {
                var finish = app.Data.data.filters.finish[str];
                if (finish.mapping[baseproduct.country.toLowerCase()]) {
                    var a = finish.mapping[baseproduct.country.toLowerCase()].length;
                    for (var i = 0; i < a; i++) {
                        if (finish.mapping[baseproduct.country.toLowerCase()][i].toLowerCase() == baseproduct.fi.toLowerCase() && !finish.sub[baseproduct.fi.toLowerCase()]) {
                            finish.sub[baseproduct.fi.toLowerCase()] = { id: baseproduct.fi.toLowerCase(), label: baseproduct.fi, selected: false };
                        }
                    }
                }
            }
        }
    }, {
        key: "addBrandToFilters",
        value: function addBrandToFilters(baseproduct) {
            if (!app.Data.data.filters.brands[baseproduct.br]) {
                app.Data.data.filters.brands[baseproduct.br] = { id: baseproduct.br, label: baseproduct.br, selected: false };
            }
        }
    }, {
        key: "addDateToFilters",
        value: function addDateToFilters(baseproduct) {
            var temp_tab = [];
            if (!baseproduct.pls.launchDate) baseproduct.pls.launchDate = "2014-05-26T22:00:00.000Z";
            temp_tab = baseproduct.pls.launchDate.split("T");
            var product_date = new Date(temp_tab[0]);

            var year = product_date.getFullYear();
            var month = product_date.getMonth() + 1;
            if (month < 10) month = "0" + month;
            var date_day = product_date.getDate();
            if (date_day < 10) date_day = "0" + date_day;
            baseproduct.pls.formatted_date = year + "-" + month + "-" + date_day;
            baseproduct.pls.date = new Date(baseproduct.pls.formatted_date);
            baseproduct.date = baseproduct.pls.date.getTime();

            app.Data.data.filters.pls_dates.dates[baseproduct.pls.formatted_date] = { date: new Date(baseproduct.pls.formatted_date), formatted_date: baseproduct.pls.formatted_date };

            if (!app.Data.data.filters.pls_dates.min) {
                app.Data.data.filters.pls_dates.min = app.Data.data.filters.pls_dates.selected_min = baseproduct.pls.formatted_date;
            } else {
                var date = new Date(app.Data.data.filters.pls_dates.min);
                if (date.getTime() > baseproduct.pls.date.getTime()) {
                    app.Data.data.filters.pls_dates.min = app.Data.data.filters.pls_dates.selected_min = baseproduct.pls.formatted_date;
                }
            }

            if (!app.Data.data.filters.pls_dates.max) {
                app.Data.data.filters.pls_dates.max = app.Data.data.filters.pls_dates.selected_max = baseproduct.pls.formatted_date;
            } else {
                var date = new Date(app.Data.data.filters.pls_dates.max);
                if (date.getTime() < baseproduct.pls.date.getTime()) {
                    app.Data.data.filters.pls_dates.max = app.Data.data.filters.pls_dates.selected_max = baseproduct.pls.formatted_date;
                }
            }
        }
    }, {
        key: "addCDateToFilters",
        value: function addCDateToFilters(baseproduct) {
            var temp_tab = [];
            if (!baseproduct.pls.creationDate) baseproduct.pls.creationDate = "2014-05-26T22:00:00.000Z";
            temp_tab = baseproduct.pls.creationDate.split("T");
            var product_date = new Date(temp_tab[0]);

            var year = product_date.getFullYear();
            var month = product_date.getMonth() + 1;
            if (month < 10) month = "0" + month;
            var date_day = product_date.getDate();
            if (date_day < 10) date_day = "0" + date_day;
            baseproduct.pls.formatted_cdate = year + "-" + month + "-" + date_day;
            baseproduct.pls.cdate = new Date(baseproduct.pls.formatted_cdate);
            baseproduct.cdate = baseproduct.pls.cdate.getTime();

            app.Data.data.filters.pls_cdates.dates[baseproduct.pls.formatted_cdate] = { cdate: new Date(baseproduct.pls.formatted_cdate), formatted_cdate: baseproduct.pls.formatted_cdate };

            if (!app.Data.data.filters.pls_cdates.min) {
                app.Data.data.filters.pls_cdates.min = app.Data.data.filters.pls_cdates.selected_min = baseproduct.pls.formatted_cdate;
            } else {
                var date = new Date(app.Data.data.filters.pls_cdates.min);
                if (date.getTime() > baseproduct.pls.cdate.getTime()) {
                    app.Data.data.filters.pls_cdates.min = app.Data.data.filters.pls_cdates.selected_min = baseproduct.pls.formatted_cdate;
                }
            }

            if (!app.Data.data.filters.pls_cdates.max) {
                app.Data.data.filters.pls_cdates.max = app.Data.data.filters.pls_cdates.selected_max = baseproduct.pls.formatted_cdate;
            } else {
                var date = new Date(app.Data.data.filters.pls_cdates.max);
                if (date.getTime() < baseproduct.pls.cdate.getTime()) {
                    app.Data.data.filters.pls_cdates.max = app.Data.data.filters.pls_cdates.selected_max = baseproduct.pls.formatted_cdate;
                }
            }
        }
    }, {
        key: "addClientToFilters",
        value: function addClientToFilters(baseproduct) {
            var client = verallia.StringUtils.makeURLString(baseproduct.pls.customerName);
            client = verallia.StringUtils.replace(client, "-", "");

            var a = app.Data.data.filters.pls_clients.elements.length;
            for (var i = 0; i < a; i++) {
                if (app.Data.data.filters.pls_clients.elements[i].id == client) return;
            }

            //if(app.Data.data.filters.pls_clients.values.indexOf(baseproduct.pls.customerName) != -1) return;
            //if(app.Data.data.filters.pls_clients[client]) return;

            app.Data.data.filters.pls_clients.elements.push({ id: client, label: baseproduct.pls.customerName, selected: false });
            //app.Data.data.filters.pls_clients.values.push(baseproduct.pls.customerName);
        }
    }, {
        key: "addSalesOfficeToFilters",
        value: function addSalesOfficeToFilters(baseproduct) {
            var sale_office = verallia.StringUtils.makeURLString(baseproduct.pls.salesOfficeDescription);
            sale_office = verallia.StringUtils.replace(sale_office, "-", "");

            var a = app.Data.data.filters.pls_salesoffice.elements.length;
            for (var i = 0; i < a; i++) {
                if (app.Data.data.filters.pls_salesoffice.elements[i].id == sale_office) return;
            }

            //if(app.Data.data.filters.pls_salesoffice[sale_office]) return;

            app.Data.data.filters.pls_salesoffice.elements.push({ id: sale_office, label: baseproduct.pls.salesOfficeDescription, selected: false });
            //app.Data.data.filters.pls_salesoffice.values.push(baseproduct.pls.salesOfficeDescription);
        }
    }, {
        key: "addDecoratorToFilters",
        value: function addDecoratorToFilters(baseproduct) {
            if (!baseproduct.pls.decoration || baseproduct.pls.decoration == "") return;

            var decorator = verallia.StringUtils.makeURLString(baseproduct.pls.decoration);
            decorator = verallia.StringUtils.replace(decorator, "-", "");

            if (app.Data.data.filters.pls_decorators[decorator]) return;

            app.Data.data.filters.pls_decorators[decorator] = { id: decorator, label: baseproduct.pls.decoration, sub: {}, selected: false };
            //app.Data.data.filters.pls_decorators.values.push(baseproduct.pls.decoration);
        }
    }, {
        key: "addContentsToFilters",
        value: function addContentsToFilters(baseproduct) {
            if (!baseproduct.pls.content || baseproduct.pls.content == "") return;

            var content = verallia.StringUtils.makeURLString(baseproduct.pls.content);
            content = verallia.StringUtils.replace(content, "-", "");

            if (app.Data.data.filters.pls_contents[content]) return;

            app.Data.data.filters.pls_contents[content] = { id: content, label: baseproduct.pls.content, sub: {}, selected: false };
            //app.Data.data.filters.pls_decorators.values.push(baseproduct.pls.decoration);
        }
    }, {
        key: "addCommunicationToFilters",
        value: function addCommunicationToFilters(baseproduct) {
            if (!baseproduct.pls.communication || baseproduct.pls.communication == "") return;

            var communication = verallia.StringUtils.makeURLString(baseproduct.pls.communication);
            communication = verallia.StringUtils.replace(communication, "-", "");

            if (app.Data.data.filters.pls_communication[communication]) return;

            app.Data.data.filters.pls_communication[communication] = { id: communication, label: baseproduct.pls.communication, sub: {}, selected: false };
            //app.Data.data.filters.pls_decorators.values.push(baseproduct.pls.decoration);
        }
    }, {
        key: "addCommercialsToFilters",
        value: function addCommercialsToFilters(baseproduct) {
            if (!baseproduct.pls.commercial || baseproduct.pls.commercial == "" || baseproduct.pls.commercial == " ") return;

            var commercial = verallia.StringUtils.makeURLString(baseproduct.pls.commercial);
            commercial = verallia.StringUtils.replace(commercial, "-", "");

            var a = app.Data.data.filters.pls_commercials.elements.length;
            for (var i = 0; i < a; i++) {
                if (app.Data.data.filters.pls_commercials.elements[i].id == commercial) return;
            }

            app.Data.data.filters.pls_commercials.elements.push({ id: commercial, label: baseproduct.pls.commercial, selected: false });

            /*if(!baseproduct.pls.commercial || baseproduct.pls.commercial == "") return;
              var commercial = verallia.StringUtils.makeURLString(baseproduct.pls.commercial);
            commercial = verallia.StringUtils.replace(commercial, "-", "");
              if(app.Data.data.filters.pls_commercials[commercial]) return;
              app.Data.data.filters.pls_commercials[commercial] = { id:commercial, label:baseproduct.pls.commercial, sub:{}, selected:false };
            //app.Data.data.filters.pls_decorators.values.push(baseproduct.pls.decoration);*/
        }
    }, {
        key: "addCompetitorsBaseproducts",
        value: function addCompetitorsBaseproducts() {
            var a = this.catalog_competitors_data.FR.mps.mp.length;
            for (var i = 0; i < a; i++) {
                var baseproduct = this.catalog_competitors_data.FR.mps.mp[i];

                baseproduct.gaI = verallia.ObjectUtils.makeArrayFromObject(baseproduct.gaI);
                baseproduct.foI = verallia.ObjectUtils.makeArrayFromObject(baseproduct.foI);
                baseproduct.ga = baseproduct.gaI;
                baseproduct.fo = baseproduct.foI;
                baseproduct.pr = verallia.ObjectUtils.makeArrayFromObject(baseproduct.pr);

                baseproduct.vg = false;
                baseproduct.gd = "null";
                baseproduct.ac = "Z0";
                baseproduct.ch = "Z0";
                baseproduct.di = String(baseproduct.di);
                baseproduct.vo = String(baseproduct.vo);
                baseproduct.we = String(baseproduct.we);
                baseproduct.he = String(baseproduct.he);
                baseproduct.fh = "10.00";
                baseproduct.oc = "10.00";
                baseproduct.fi = "empty";
                baseproduct.competitor = true;
                baseproduct.br = baseproduct.pr[0].br;

                this.createNumberValuesOnBaseproduct(baseproduct);

                var b = baseproduct.pr.length;
                for (var j = 0; j < baseproduct.pr.length; j++) {
                    baseproduct.pr[j].fip = [];
                    baseproduct.pr[j].vg = false;
                    baseproduct.pr[j].id = baseproduct.pr[j].cb;
                    baseproduct.pr[j].cb = "Z0" + baseproduct.pr[j].cb;
                    baseproduct.pr[j].cc = "ffffff";
                    baseproduct.pr[j].cs = "01";
                    baseproduct.pr[j].cl_en = baseproduct.pr[j].cl;
                    baseproduct.pr[j].mg = "NABA";
                    baseproduct.pr[j].ai = "SGE";

                    var c = this.catalog_data.catalog.FR.mps.mp.length;
                    for (var k = 0; k < c; k++) {
                        var p = this.catalog_data.catalog.FR.mps.mp[k];
                        verallia.ObjectUtils.makeArrayFromObject(p.pr);
                        for (var l = 0; l < p.pr.length; l++) {
                            if (baseproduct.pr[j].cl == p.pr[l].cl) {
                                baseproduct.pr[j].cc = p.pr[l].cc;
                                baseproduct.pr[j].cs = p.pr[l].cs;
                                break;
                            }
                        }
                    }
                }

                var b = this.catalog_data.catalog.international.ra.length;
                for (var j = 0; j < b; j++) {
                    var range = this.catalog_data.catalog.international.ra[j];

                    var c = baseproduct.ga.length;
                    for (var k = 0; k < c; k++) {
                        if (baseproduct.ga[k].la == range.la) {
                            baseproduct.ga[k].id = range.id;
                        }
                    }

                    if (range.sr && range.sr.length > 0) {
                        var c = baseproduct.fo.length;
                        for (var k = 0; k < c; k++) {
                            var d = range.sr.length;
                            for (var l = 0; l < d; l++) {
                                if (baseproduct.fo[k].la == range.sr[l].la) {
                                    baseproduct.fo[k].id = range.sr[l].id;
                                }
                            }
                        }
                    }
                }

                this.catalog_data.catalog.FR.mps.mp.push(baseproduct);
            }
        }
    }, {
        key: "checkRenderGDExistance",
        value: function checkRenderGDExistance(baseproduct) {
            var gd_without_version = app.Utils.getGDWithoutVersion(baseproduct).toUpperCase();

            if (this.rendercorp_gd_folders[gd_without_version] && this.rendercorp_gd_folders[gd_without_version]._root_files && this.rendercorp_gd_folders[gd_without_version]._root_files.indexOf(gd_without_version + "_ST.igmesh") != -1) {
                baseproduct.easyrender = true;
                return true;
            } else {
                baseproduct.easyrender = false;
                //delete baseproduct.easyrender;
                if (baseproduct.vg == true) return true;
                return false;
            }
        }
    }, {
        key: "addEasyRenderBaseproducts",
        value: function addEasyRenderBaseproducts() {
            //trace("adding specialities from:");
            //trace(this.catalog_addons_vg_specialities);

            var c = 0;
            var develop_ready_extrude = ["2305_P", "2306_P", "2276_P", "2273_P", "2536_P", "2536_P"];

            var a = app.Data.data.config.countries.length;
            for (var i = 0; i < a; i++) {
                var country = app.Data.data.config.countries[i].toLowerCase();

                if (!this.catalog_addons_vg_specialities.catalog[country.toUpperCase()]) continue;

                var country_products = this.catalog_addons_vg_specialities.catalog[country.toUpperCase()].mps.mp;
                var b = country_products.length;
                for (var j = 0; j < b; j++) {
                    var baseproduct = country_products[j];

                    if (baseproduct.gd.toLowerCase().indexOf("b") == 0) {
                        baseproduct.vg_object = true;
                        if (app.Data.data.platform == "ICT") continue;
                    } else {
                        if (develop_ready_extrude.indexOf(baseproduct.gd) == -1) baseproduct.develop_ready = true;
                    }

                    if (app.Data.data.platform == "VirtualGlass" || app.Data.data.platform == "ICT") {
                        baseproduct.easyrender = true;
                        baseproduct.force_vg = true;
                        baseproduct.from_int_json = false;
                        baseproduct.vg = "true";
                    }

                    //trace("adding speciality GD to catalog : " + baseproduct.gd);
                    //trace(baseproduct);

                    if (!this.catalog_data.catalog[country.toUpperCase()]) {
                        this.catalog_data.catalog[country.toUpperCase()] = { mps: { mp: [] } };
                    }

                    this.catalog_data.catalog[country.toUpperCase()].mps.mp.push(baseproduct);

                    c++;
                }
            }

            trace("added " + c + " products from specialities addons");
        }
    }, {
        key: "addDummyProductsFromRenderCorp",
        value: function addDummyProductsFromRenderCorp() {
            for (var str in this.rendercorp_gd_folders) {
                if (str == "_root_files") continue;

                //if(this.rendercorp_gd_folders[str] && this.rendercorp_gd_folders[str]._root_files && this.rendercorp_gd_folders[str]._root_files.indexOf(str + "_ST.igmesh") != -1){
                if (!app.Data.data.baseproducts_gd[str] && this.rendercorp_gd_folders[str] && this.rendercorp_gd_folders[str]._root_files && this.rendercorp_gd_folders[str]._root_files.indexOf(str + "_ST.igmesh") != -1) {
                    //trace("ok");
                    var baseproduct = {};
                    baseproduct.from_int_json = false;
                    baseproduct.easyrender = true;
                    baseproduct.id = verallia.StringUtils.generateUID();
                    baseproduct.gd = str;
                    baseproduct.la = "x : " + str;
                    baseproduct.vo = "25ml";
                    baseproduct.we = "85.00g";
                    baseproduct.he = "350mm";
                    baseproduct.di = "28.20mm";
                    baseproduct.gds = str;
                    baseproduct.fi = "Couronne";
                    baseproduct.fis = "G292";
                    baseproduct.bi = "null";
                    baseproduct.ac = "true";
                    baseproduct.ch = "Z0";
                    baseproduct.vg = true;
                    baseproduct.special = false;
                    baseproduct.fh = "63.00";
                    baseproduct.oc = "0";
                    baseproduct.gaI = [];
                    baseproduct.foI = [];
                    baseproduct.country = "fr";
                    baseproduct.countries = ["fr"];
                    baseproduct.ga = [{
                        "la": "Still wines",
                        "id": "1"
                    }];
                    baseproduct.fo = [{
                        "la": "Others",
                        "id": "6",
                        "pi": "1",
                        "pl": "Still wines"
                    }];
                    baseproduct.pr = [{
                        "id": verallia.StringUtils.generateUID(),
                        "cb": str,
                        "cl": "Blanc",
                        "cl_en": "Flint",
                        "cc": "ffffff",
                        "cs": "00",
                        "br": "Verallia",
                        "mg": "NAAA",
                        "ai": "SGE",
                        "fip": [],
                        "country": "fr",
                        "countries": ["fr"]
                    }];

                    this.createNumberValuesOnBaseproduct(baseproduct);

                    app.Data.data.baseproducts[baseproduct.id] = baseproduct;
                    app.Data.data.baseproducts_id[baseproduct.id] = {};
                    app.Data.data.baseproducts_gd[str] = {};

                    baseproduct.vg = this.checkVGFlagFromModels3DList(baseproduct);
                }
            }
        }
    }, {
        key: "createNumberValuesOnBaseproduct",
        value: function createNumberValuesOnBaseproduct(baseproduct) {
            baseproduct.value_he = parseInt(baseproduct.he);
            baseproduct.value_we = parseInt(baseproduct.we);
            baseproduct.value_di = parseInt(baseproduct.di);
            baseproduct.value_vo = parseInt(baseproduct.vo);
            baseproduct.value_fh = parseInt(baseproduct.fh);
            baseproduct.value_oc = parseInt(baseproduct.oc);
        }
    }, {
        key: "checkVGFlagFromModels3DList",
        value: function checkVGFlagFromModels3DList(baseproduct) {
            if (this.models3d_list) {

                //if(this.models3d_list.indexOf(baseproduct.gd.toLowerCase() + ".bin") != -1) return true;
                if (this.models3d_list.indexOf(app.Utils.getGDWithoutVersion(baseproduct).toLowerCase()) != -1) return true;
                return false;
            }

            return baseproduct.vg;
        }
    }, {
        key: "filterUSProducts",
        value: function filterUSProducts() {
            this.catalog_data_filters_us;
            this.catalog_data.catalog.US = { ra: this.catalog_data.catalog.international.ra, mps: { mp: [] } };

            var a = app.Data.data.config.countries.length;
            for (var i = 0; i < a; i++) {
                var country = app.Data.data.config.countries[i];

                if (country.toLowerCase() == "us") continue;
                if (!this.catalog_data.catalog[country.toUpperCase()]) continue;

                var country_products = this.catalog_data.catalog[country.toUpperCase()].mps.mp;

                var b = country_products.length;
                for (var j = 0; j < b; j++) {
                    var baseproduct = country_products[j];

                    baseproduct.ga = baseproduct.gaI = verallia.ObjectUtils.makeArrayFromObject(baseproduct.gaI);
                    baseproduct.fo = baseproduct.foI = verallia.ObjectUtils.makeArrayFromObject(baseproduct.foI);

                    baseproduct.pr = verallia.ObjectUtils.makeArrayFromObject(baseproduct.pr);

                    var added = false;
                    var remove_list = [];
                    var c = baseproduct.pr.length;
                    for (var k = 0; k < c; k++) {
                        baseproduct.pr[k].cb_temp = baseproduct.pr[k].cb.substring(2);

                        if (this.catalog_data_filters_us[baseproduct.pr[k].cb_temp]) {
                            if (added == false) {
                                added = true;
                                this.catalog_data.catalog.US.mps.mp.push(baseproduct);
                            }
                        } else {
                            remove_list.push(k);
                        }

                        delete baseproduct.pr[k].cb_temp;
                    }

                    c = remove_list.length;
                    for (var k = 0; k < c; k++) {
                        baseproduct.pr.splice(remove_list[k], 1);
                    }
                }
            }
        }
    }]);

    return _class;
}();