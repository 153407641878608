"use strict";

app.FinishedProducts = {
    mounted: function mounted() {
        $(window).off("UPDATE_PRODUCT_PALETTES_DATA").on("UPDATE_PRODUCT_PALETTES_DATA", this.updatePalettesData.bind(this));
        $("#cart_container input").on("propertychange change keyup input paste focus focusout", this.checkQuantity.bind(this));
        $(window).on(verallia.Event.RESIZE, this.resize.bind(this));
        this.resize();
        setTimeout(this.resize.bind(this), 200);
    },
    update: function update() {
        //this.product.selected_line = 0;
    },
    getData: function getData() {
        return {
            cart_position: "right",
            offsetX: 0,
            offsetY: 0,
            palettes_check: false,
            palettes_data: null,
            cartVisible: false,
            basketVisible: true
        };
    },
    dispose: function dispose() {
        $(window).off("UPDATE_PRODUCT_PALETTES_DATA", this.updatePalettesData.bind(this));
        $("#cart_container input").off("propertychange change keyup input paste focus focusout", this.checkQuantity.bind(this));
        //$(window).off("resize", this.resize.bind(this));
    },
    updatePalettesData: function updatePalettesData() {
        this.basketVisible = true;
        setTimeout(this.checkStock.bind(this), 200);
        setTimeout(this.resize.bind(this), 200);
    },

    checkStock: function checkStock() {
        this.palettes_check = false;
        this.palettes_data = null;

        if (this.product.fip.length > 0) {
            var red_threshold = null;
            var yellow_threshold = null;

            if (app.Data.data.platform == "MyVerallia") {
                try {
                    red_threshold = ct_stock_red_threshold;
                    yellow_threshold = ct_stock_yellow_threshold;
                } catch (e) {}
            } else if (window.location.href.indexOf("localhost") != -1) {
                red_threshold = 25;
                yellow_threshold = 20;
            }

            if (!red_threshold || red_threshold == "null" || !yellow_threshold || yellow_threshold == "null") {
                return;
            } else {
                this.palettes_check = true;

                if (app.Data.stock_request && app.Data.stock_request.product.id == this.product.id) {} else {
                    app.Data.stock_request = new app.PalettesStockRequest(verallia.ObjectUtils.copy(this.product), this.displayStock.bind(this));
                }
            }
        }
    },
    resize: function resize() {
        if (this.product.fip.length <= 0) return;

        try {
            if (app.Data.data.isMobile == false) {
                if ($("#ct_palettes_table").offset().left + $("#ct_palettes_table").width() + 270 > $(window).width()) {
                    this.offsetX = $("#ct_palettes_table").width() - 300;
                    this.offsetY = 41 * (this.product.selected_line + 1) - 130;
                    this.cart_position = "top";
                } else {
                    this.offsetX = $("#ct_palettes_table").width();
                    this.offsetY = 41 * (this.product.selected_line + 1) - 50;
                    this.cart_position = "right";
                }
            } else {
                this.offsetX = 0;
                this.offsetY = 0;
                this.cart_position = "none";
            }
        } catch (e) {}
    },

    checkVisibility: function checkVisibility(key) {
        if (app.Data.data.isMobile == false) return true;

        if (key != "cf" && key != "hp" && key != "wg" && key != "fc") return false;
        return true;
    },
    selectLine: function selectLine(index) {
        this.basketVisible = true;
        this.product.selected_line = index;
        this.checkQuantity();
        this.$forceUpdate();
        this.resize();

        verallia.Core.getObject("app").updateHash();
    },
    addQuantity: function addQuantity() {
        if (parseInt(this.product.fip[this.product.selected_line].quantity) < 99) {
            this.product.fip[this.product.selected_line].quantity++;
        }

        this.checkQuantity();
    },
    removeQuantity: function removeQuantity() {
        if (parseInt(this.product.fip[this.product.selected_line].quantity) > 1) {
            this.product.fip[this.product.selected_line].quantity--;
        }

        this.checkQuantity();
    },
    checkQuantity: function checkQuantity() {
        this.product.fip[this.product.selected_line].quantity = String(this.product.fip[this.product.selected_line].quantity).replace(/[^\d]/, "");
        if (this.product.fip[this.product.selected_line].quantity == "") this.product.fip[this.product.selected_line].quantity = 1;

        if (parseInt(this.product.fip[this.product.selected_line].quantity) < 1) this.product.fip[this.product.selected_line].quantity = 1;else if (parseInt(this.product.fip[this.product.selected_line].quantity) > 99) this.product.fip[this.product.selected_line].quantity = 99;

        this.product.fip[this.product.selected_line].quantity = parseInt(this.product.fip[this.product.selected_line].quantity);
    },

    openCart: function openCart() {
        this.cartVisible = true;
    },
    openPlan: function openPlan() {
        var url_data_prefixe = "";

        try {
            url_data_prefixe = url_prefixe;
        } catch (e) {}

        if (url_data_prefixe != "/myvoa") url_data_prefixe = "";

        var fip = this.product.fip[this.product.selected_line];
        var url = url_data_prefixe + "/apex/Web_ViewDocumentFile?distribChannel=" + fip.fc + "&objType=PALLET&objNum=" + fip.cf + "&salesorg=" + this.product.so;
        verallia.WindowUtils.openURL(url, "_blank");
    },
    addToCart: function addToCart() {
        if (app.Data.data.platform == "MyVerallia") {
            ct_cart_product_id = this.product.id;
            ct_cart_finished_product_id = this.product.fip[this.product.selected_line].fc + this.product.fip[this.product.selected_line].cf;
            ct_cart_quantity = this.product.fip[this.product.selected_line].quantity;

            window.AddToCart();
        }
    },

    displayStock: function displayStock(product, result) {
        app.Data.stock_request = null;

        if (!product) {
            this.palettes_check = false;
            return;
        }
        if (this.product.id != product.id) return;

        this.palettes_data = result;
    },
    closeBasket: function closeBasket() {
        this.basketVisible = !this.basketVisible;
    }
};