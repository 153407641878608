"use strict";

app.Header = {
    mounted: function mounted() {
        $(window).off("COMPARATOR_UPDATE").on("COMPARATOR_UPDATE", this.comparatorUpdate.bind(this));
    },
    update: function update() {},
    getData: function getData() {
        return {
            missingMarkets: false
        };
    },
    dispose: function dispose() {},
    getTitle: function getTitle() {
        if (app.Data.data.platform == 'VeralliaCom') {
            return app.Data.data.loc.interface.title_veralliacom;
        } else if (app.Data.data.platform == 'MyVerallia') {
            return app.Data.data.loc.interface.title_myverallia + " " + app.Data.data.loc.global.countries[app.Data.data.config.country.toLowerCase()].label;
        } else if (app.Data.data.platform == 'ICTPLS') {
            return app.Data.data.loc.interface.title_pls;
        } else {
            return app.Data.data.loc.interface.title_ict;
        }
    },
    openMobileFilters: function openMobileFilters() {
        app.Data.data.mobileFilters = !app.Data.data.mobileFilters;
    },
    comparatorUpdate: function comparatorUpdate() {
        //$("#ct_comparator_button").css("");
    },
    openComparator: function openComparator() {
        app.Data.data.comparator = true;
    },
    openSamples: function openSamples() {
        app.Data.data.samples = true;
    },
    closeProduct: function closeProduct() {
        app.Data.data.baseproduct = null;
        app.Data.data.section = "products";
        verallia.Core.getObject("app").updateHash();
        verallia.Core.getObject("filters").updateDisplay();
    },
    openBurger: function openBurger() {
        app.Data.data.burger = true;
    },

    toggleProductMode: function toggleProductMode() {
        if (app.Data.data.product_mode == "myproducts") {
            app.Data.data.product_mode = "general";
            app.Data.data.config.filters.use_type = false;
            app.Data.data.config.filters.use_myverallia_groups = false;
        } else if (app.Data.data.product_mode == "general") {
            app.Data.data.product_mode = "myproducts";
            app.Data.data.config.filters.use_type = true;

            //à remettre une fois le filtre groupes corrigé
            //app.Data.data.config.filters.use_myverallia_groups = true;
        }

        $(window).trigger("SET_PRODUCT_MODE");
    },
    applyFilterOrder: function applyFilterOrder(key) {
        if (!key) return;

        app.Data.data.products_order = key;
        verallia.LocalStorageUtils.setKey("ct_products_order" + app.Data.data.platform, key);

        var values = [];
        values.push(app.Data.data.loc.filters.orders[key].value1);
        values.push(app.Data.data.loc.filters.orders[key].value2);

        var normal_order = true;
        if (app.Data.data.loc.filters.orders[key].decrease == true) normal_order = false;
        app.Data.data.filtered_baseproducts_array.sort(verallia.ArrayUtils.sortArray([values[0], values[1]], normal_order));

        app.Data.data.product_page = 1;
        $("#ct_products_list").nanoScroller({ scroll: "top" });
    },
    setLanguage: function setLanguage(id) {
        app.Data.data.loc = app.Data.data.global_loc[id];
        app.Data.data.config.language = id;

        verallia.LocalStorageUtils.setKey("ct_language", id);

        verallia.Core.getObject("app").updateHash();
    },
    getComparatorSData: function getComparatorSData() {
        var sdata = "";

        var a = Object.keys(app.Data.data.comparator_products).length;
        var i = 0;
        for (var str in app.Data.data.comparator_products) {
            var p = app.Data.data.baseproducts[str];
            if (p) {
                sdata += p.la + " - " + p.pr[app.Data.data.comparator_products[str].index].cb;
                if (i != a - 1) sdata += " | ";

                i++;
            }
        }

        return sdata;
    },
    getSamplesSData: function getSamplesSData() {
        return; // TO CHECK

        return sdata;
    },
    openMVCatalog: function openMVCatalog(country) {
        var hash = '#/{"platform":"MyVerallia"}';
        if (app.Data.data.product_mode == "myproducts") hash = '#/{"platform":"MyVerallia","filters":{"product_mode":"myproducts"}}';

        var url = window.location.protocol + "//" + location.host + location.pathname + "?country=" + country.toUpperCase() + hash;
        trace("opening url : " + url);
        verallia.WindowUtils.openURL(url, "_self");
        //window.location.href = ;
    },
    openMissingMarketsList: function openMissingMarketsList() {
        this.missingMarkets = true;
    }
};