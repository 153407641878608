"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.PLSUtils = function () {
    function _class() {
        _classCallCheck(this, _class);

        this.baseproduct;
        this.wo;
    }

    _createClass(_class, null, [{
        key: "openPLSProductPDF",
        value: function openPLSProductPDF(baseproduct) {
            this.baseproduct = verallia.ObjectUtils.copy(baseproduct);

            if (verallia.WindowUtils.isIE() == false) this.wo = window.open("", "_blank");

            if (baseproduct.pls.images[0] && baseproduct.pls.images[0].urlL && baseproduct.pls.images[0].urlL != "") {
                this.convertImgToDataURLviaCanvas(this.baseproduct.pls.images[0].urlL, this.convertionCallback.bind(this));
            } else {
                this.createPDF();
            }
        }
    }, {
        key: "convertionCallback",
        value: function convertionCallback(base64Image) {
            base64Image = base64Image.replace("data:image/png;base64,", "");
            this.baseproduct.pls.images[0].item = base64Image;
            this.createPDF();
        }
    }, {
        key: "convertImgToDataURLviaCanvas",
        value: function convertImgToDataURLviaCanvas(url, callback, outputFormat) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function () {
                var canvas = document.createElement('CANVAS');
                var ctx = canvas.getContext('2d');
                var dataURL;
                canvas.height = this.height;
                canvas.width = this.width;
                ctx.drawImage(this, 0, 0);
                dataURL = canvas.toDataURL(outputFormat);
                callback(dataURL);
                canvas = null;
            };
            img.src = url;
        }
    }, {
        key: "convertFileToDataURLviaFileReader",
        value: function convertFileToDataURLviaFileReader(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        }
    }, {
        key: "createPDF",
        value: function createPDF() {
            $.ajax({
                //url: "http://wsqual.corp.inpkg.net/services/stock",
                url: "https://ws.corp.inpkg.net:8011/services2/veralliaws/ZBU_BC_GET_PLS_FORM",
                data: JSON.stringify({ language: "en", plsData: this.baseproduct.pls }),
                method: "post",
                dataType: 'json',
                contentType: 'application/json',
                success: this.plsPDFOK.bind(this),
                error: this.plsPDFKO.bind(this)
            });
        }
    }, {
        key: "plsPDFOK",
        value: function plsPDFOK(data) {
            this.downloadFile("pls.pdf", data.PdfContent);
        }
    }, {
        key: "plsPDFKO",
        value: function plsPDFKO() {
            trace("pls product extract KO");
        }
    }, {
        key: "downloadFile",
        value: function downloadFile(filename, content) {
            if (verallia.WindowUtils.isIE() == false) {
                var objbuilder = '';
                objbuilder += '<object width="100%" height="100%" data="data:application/pdf;base64,';
                objbuilder += content;
                objbuilder += '"type="application/pdf" class="internal">';
                objbuilder += '<embed src="data:application/pdf;base64, ';
                objbuilder += content;
                objbuilder += '" type="application/pdf" />';
                objbuilder += '</object>';

                this.wo.document.title = "My Title";
                this.wo.document.write('<html><body style="margin:0; padding:0;">');
                this.wo.document.write(objbuilder);
            } else {
                download("data:application/pdf;base64," + content, filename, "application/pdf");
            }
        }
    }, {
        key: "openPLSProductPPT",
        value: function openPLSProductPPT(baseproduct) {
            this.baseproduct = verallia.ObjectUtils.copy(baseproduct);

            if (baseproduct.pls.images && this.baseproduct.pls.images[0]) {
                verallia.FileLoader.addFile("pls_image_load", this.baseproduct.pls.images[0].urlL);
            } else {
                verallia.FileLoader.addFile("pls_image_load", app.Data.data.config.assets_path + "static/assets/images/interface/default_bottle.jpg");
            }

            verallia.FileLoader.load(this.plsImagesLoaded.bind(this), this.plsImagesError.bind(this));
        }
    }, {
        key: "plsImagesLoaded",
        value: function plsImagesLoaded() {
            var pptx = new PptxGenJS();
            var slide = pptx.addNewSlide();
            slide.addText(this.baseproduct.pls.baseProductName, { x: 2, y: 0.3, fontSize: 18, color: '363636' });

            var image = verallia.FileLoader.get("pls_image_load");
            var pr = image.height / image.width;
            var inch_value = 1.3;

            if (this.baseproduct.pls.images && this.baseproduct.pls.images[0]) {
                slide.addImage({ path: this.baseproduct.pls.images[0].urlL, x: 0.3, y: 0.3, w: inch_value, h: inch_value * pr });
            } else {
                slide.addImage({ path: app.Data.data.config.assets_path + "static/assets/images/interface/default_bottle.jpg", x: 0.3, y: 0.3, w: inch_value, h: inch_value * pr });
            }

            pptx.save(this.baseproduct.pls.baseProductName);
        }
    }, {
        key: "plsImagesError",
        value: function plsImagesError() {
            trace("error loading pls images for ppt generation");
        }
    }]);

    return _class;
}();