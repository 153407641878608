'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

app.Utils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: 'checkMTO',
        value: function checkMTO(baseproduct, index) {
            if (app.Data.data.platform == 'MyVerallia' || app.Data.data.platform == 'ICT') {
                //if(app.Data.data.mto_data[baseproduct.pr[index].cb]) return true;

                if (baseproduct.pr[index].pme == 'MTO' || baseproduct.pr[index].pme == 'MTM') return true;

                /*if(baseproduct.pr[index].pme == 'MTO'){
                    return true;
                }else if(baseproduct.pr[index].pme == 'MTM' && baseproduct.pr[index].special == false){
                    var a = baseproduct.fo.length;
                    //checker ici avec les id du json de myverallia qui sont dynamiques
                    for(var i=0; i<a; i++){
                        if(baseproduct.fo[i].la.toLowerCase() == "regional" && baseproduct.fo[i].id == "4") return true;
                    }
                }*/
            }

            return false;
        }
    }, {
        key: 'getGDWithoutVersion',
        value: function getGDWithoutVersion(baseproduct) {
            if (!baseproduct.gd) baseproduct.gd = "";

            var gd_without_version = baseproduct.gd;

            if (gd_without_version.toLowerCase().indexOf("ps_") != -1) return gd_without_version.substring(0, gd_without_version.length - 2);

            var de_plan = false;

            for (var i = 0; i < 10; i++) {
                if (gd_without_version.indexOf(i + "-") == 0) {
                    de_plan = true;
                    break;
                }
            }

            if (gd_without_version.indexOf("77") == 0 || gd_without_version.indexOf("50") == 0 || gd_without_version.indexOf("76") == 0 || gd_without_version.indexOf("11") == 0) {
                var tab = [];
                tab = gd_without_version.split("_");
                if (tab.length > 2) {
                    gd_without_version = tab[0] + "_" + tab[1];
                    return gd_without_version;
                }

                if (gd_without_version.length > 5 && gd_without_version.indexOf("-") == -1) {
                    gd_without_version = gd_without_version.slice(0, -1);
                    return gd_without_version;
                }
            } else if (baseproduct.country == "fr" && de_plan == false) {
                var tab = [];
                tab = gd_without_version.split("_");

                if (tab.length > 2) {
                    gd_without_version = tab[0] + "_" + tab[1];
                } else {
                    if (baseproduct.ac == "Z4" || baseproduct.ac == "Z0") {
                        if (gd_without_version.indexOf("77") == 0 || gd_without_version.indexOf("50") == 0 || gd_without_version.indexOf("76") == 0 || gd_without_version.indexOf("11") == 0) {
                            if (gd_without_version.length > 5) {
                                gd_without_version = gd_without_version.slice(0, -1);
                                return gd_without_version;
                            }
                        }
                    }
                }
            } else if (baseproduct.country == "sp" || baseproduct.country == "ua" || baseproduct.country == "pt") {
                if (gd_without_version.indexOf("Z-") != -1) {
                    var tab = [];
                    tab = gd_without_version.split("-");
                    if (tab.length == 3) {
                        gd_without_version = tab[0] + "-" + tab[1];
                    }
                } else {
                    if (baseproduct.country == "sp" || baseproduct.country == "pt") {
                        //if(gd_without_version.length == 6 && gd_without_version.indexOf("_") == -1 && gd_without_version.charAt(5) == "W"){
                        if (gd_without_version.length == 6 && gd_without_version.charAt(4) == "W") {
                            gd_without_version = gd_without_version.substring(0, gd_without_version.length - 1);
                        }
                    }
                }
            } else if (baseproduct.country == "de" || de_plan == true) {
                var tab = [];
                tab = gd_without_version.split("-");
                if (tab.length == 3) {
                    gd_without_version = tab[0] + "-" + tab[1];
                }
            } else if (baseproduct.country == "ar") {
                if (gd_without_version.length == 10) gd_without_version = gd_without_version.substring(0, gd_without_version.length - 1);
            } else if (baseproduct.country == "cl") {
                var tab = [];
                tab = gd_without_version.split("_");
                if (tab.length == 4) {
                    gd_without_version = tab[0] + "_" + tab[1] + "_" + tab[2];
                }
            }

            return gd_without_version;
        }
    }, {
        key: 'setDataFromHashID',
        value: function setDataFromHashID(hash) {
            var tab = hash.split("_");

            if (tab[1]) {
                var id_product = tab[1];

                app.Data.data.baseproduct = app.Data.data.baseproducts[id_product];

                if (!app.Data.data.baseproduct) {
                    for (var str in app.Data.data.baseproducts) {
                        var baseproduct = app.Data.data.baseproducts[str];

                        var a = baseproduct.pr.length;
                        for (var i = 0; i < a; i++) {
                            if (baseproduct.pr[i].cb == id_product) {
                                app.Data.data.baseproduct = baseproduct;
                                app.Data.data.product_section = 1;

                                return;
                            }
                        }
                    }
                }
            }
        }
    }, {
        key: 'setDataFromHash',
        value: function setDataFromHash(hash) {

            if (app.Data.data.config.clean_url == false) {

                if (hash.product) {
                    try {
                        if (hash.product.id) {
                            var baseproduct = app.Data.data.baseproducts[hash.product.id];
                            var a = baseproduct.pr.length;
                            for (var i = 0; i < a; i++) {
                                if (baseproduct.pr[i].cb == hash.product.cb) {
                                    app.Data.data.baseproduct = baseproduct;
                                    app.Data.data.baseproduct.selected_index = i;

                                    if (app.Data.data.platform == "ICT" && hash.product.section) {
                                        if (app.Data.data.baseproduct.pr[app.Data.data.baseproduct.selected_index].mg.toLowerCase() == 'naac') {
                                            app.Data.data.product_section = hash.product.section;
                                        }
                                    }
                                }
                            }
                        } else {
                            for (var str in app.Data.data.baseproducts) {
                                var baseproduct = app.Data.data.baseproducts[str];
                                var a = baseproduct.pr.length;
                                for (var i = 0; i < a; i++) {
                                    if (baseproduct.pr[i].cb == hash.product.cb) {
                                        app.Data.data.baseproduct = baseproduct;
                                        app.Data.data.baseproduct.selected_index = i;

                                        if (app.Data.data.platform == "ICT" && hash.product.section) {
                                            if (app.Data.data.baseproduct.pr[app.Data.data.baseproduct.selected_index].mg.toLowerCase() == 'naac') {
                                                app.Data.data.product_section = hash.product.section;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        app.Data.data.baseproduct = null;
                    }

                    if (app.Data.data.platform == "MyVerallia") {
                        try {
                            if (hash.product.fip && baseproduct.pr[app.Data.data.baseproduct.selected_index].fip[hash.product.fip]) {
                                baseproduct.pr[app.Data.data.baseproduct.selected_index].selected_line = hash.product.fip;
                            }
                        } catch (e) {}
                    }
                } else {
                    app.Data.data.baseproduct = null;
                }

                //MYVERALLIA PRODUCT MODE AND GROUPS / ACCOUNTS
                if (app.Data.data.platform == "MyVerallia") {
                    try {
                        if (hash.product_mode == "myproducts") app.Data.data.product_mode = hash.product_mode;else app.Data.data.product_mode = "general";

                        //remettre une fois le filtre groups corrigé
                        //if(app.Data.data.product_mode == "myproducts") app.Data.data.config.filters.use_myverallia_groups = true;
                    } catch (e) {
                        hash.product_mode = "general";
                        app.Data.data.config.filters.use_myverallia_groups = false;
                    }
                }

                if (hash.filters) {

                    //COUNTRIES
                    if (app.Data.data.config.filters.use_countries == true && app.Data.data.used_filters.indexOf("countries") != -1) {
                        try {
                            if (Object.keys(hash.filters.countries).length == 0) {
                                for (var str in app.Data.data.filters.countries) {
                                    app.Data.data.filters.countries[str].selected = false;
                                }
                                app.Data.data.config.filters.all_countries_selected = false;
                            } else {
                                var a = 0;
                                for (var str in app.Data.data.filters.countries) {
                                    if (hash.filters.countries[str]) {
                                        app.Data.data.filters.countries[str].selected = true;
                                        a++;
                                    } else {
                                        app.Data.data.filters.countries[str].selected = false;
                                    }
                                }

                                if (a == Object.keys(app.Data.data.filters.countries).length) {
                                    app.Data.data.config.filters.all_countries_selected = true;
                                } else {
                                    app.Data.data.config.filters.all_countries_selected = false;
                                }
                            }
                        } catch (e) {
                            for (var str in app.Data.data.filters.countries) {
                                app.Data.data.filters.countries[str].selected = true;
                            }
                            app.Data.data.config.filters.all_countries_selected = true;
                        }
                    }

                    //MYVERALLIA GROUPS / ACCOUNTS
                    if (app.Data.data.platform == "MyVerallia") {
                        try {
                            if (hash.filters.product_mode == "myproducts") app.Data.data.product_mode = hash.filters.product_mode;else app.Data.data.product_mode = "general";

                            //a remettre une fois le filtre groupes corrigé
                            //if(app.Data.data.product_mode == "myproducts") app.Data.data.config.filters.use_myverallia_groups = true;

                            if (app.Data.data.product_mode == "myproducts") {
                                //a remettre une fois le filtre groupes corrigé
                                //app.Data.data.config.filters.use_myverallia_groups = true;

                                app.Data.data.filters.myverallia_groups.selected_group = hash.filters.selected_group;
                                app.Data.data.filters.myverallia_groups.selected_account = hash.filters.selected_account;

                                if (!app.Data.data.filters.myverallia_groups.selected_group) app.Data.data.filters.myverallia_groups.selected_group = "all";
                                if (!app.Data.data.filters.myverallia_groups.selected_account) app.Data.data.filters.myverallia_groups.selected_account = "all";

                                var a = app.Data.data.filters.myverallia_groups.gr.length;
                                var b = 0;
                                for (var i = 0; i < a; i++) {
                                    if (app.Data.data.filters.myverallia_groups.selected_group == app.Data.data.filters.myverallia_groups.gr[i].id) {
                                        b++;
                                    }
                                }

                                if (b == 0) {
                                    app.Data.data.filters.myverallia_groups.selected_group = "all";
                                    app.Data.data.filters.myverallia_groups.selected_account = "all";
                                }

                                var a = app.Data.data.filters.myverallia_groups.cwg.length;
                                var b = 0;
                                for (var i = 0; i < a; i++) {
                                    if (app.Data.data.filters.myverallia_groups.selected_account == app.Data.data.filters.myverallia_groups.cwg[i].id) {
                                        b++;
                                    }
                                }

                                if (b == 0) app.Data.data.filters.myverallia_groups.selected_account = "all";
                            }
                        } catch (e) {
                            hash.product_mode = "general";
                            app.Data.data.config.filters.use_myverallia_groups = false;

                            app.Data.data.filters.myverallia_groups.selected_group = "all";
                            app.Data.data.filters.myverallia_groups.selected_account = "all";
                        }

                        this.updateAccountsVisibility();
                    }

                    //RANGES
                    if (app.Data.data.config.filters.use_ranges == true && hash.filters.ranges && app.Data.data.used_filters.indexOf("ranges") != -1) {
                        try {
                            for (var str in hash.filters.ranges) {
                                if (app.Data.data.filters.ranges[str]) {
                                    app.Data.data.filters.ranges[str].selected = true;

                                    var range = hash.filters.ranges[str];
                                    if (Object.keys(range).length > 0) {
                                        for (var str2 in range) {
                                            if (app.Data.data.filters.ranges[str].sub[str2]) {
                                                app.Data.data.filters.ranges[str].sub[str2].selected = true;
                                            }
                                        }
                                    }
                                }
                            }
                        } catch (e) {}
                    }

                    //BRANDS
                    if (app.Data.data.config.filters.use_brands == true && hash.filters.brands && app.Data.data.used_filters.indexOf("brands") != -1) {
                        try {
                            for (var str in hash.filters.brands) {
                                if (app.Data.data.filters.brands[str]) {
                                    app.Data.data.filters.brands[str].selected = true;
                                }
                            }
                        } catch (e) {}
                    }

                    //COLORS
                    if (app.Data.data.config.filters.use_colors == true && hash.filters.colors && app.Data.data.used_filters.indexOf("colors") != -1) {
                        try {
                            for (var str in hash.filters.colors) {
                                if (app.Data.data.filters.colors[str]) {
                                    app.Data.data.filters.colors[str].selected = true;

                                    var color = hash.filters.colors[str];
                                    if (Object.keys(color).length > 0) {
                                        for (var str2 in color) {
                                            if (app.Data.data.filters.colors[str].sub[str2]) {
                                                app.Data.data.filters.colors[str].sub[str2].selected = true;
                                            }
                                        }
                                    }
                                }
                            }
                        } catch (e) {}
                    }

                    //FINISH
                    if (app.Data.data.config.filters.use_finish == true && hash.filters.finish && app.Data.data.used_filters.indexOf("finish") != -1) {
                        try {
                            for (var str in hash.filters.finish) {
                                if (app.Data.data.filters.finish[str]) {
                                    app.Data.data.filters.finish[str].selected = true;

                                    var color = hash.filters.finish[str];
                                    if (Object.keys(color).length > 0) {
                                        for (var str2 in color) {
                                            if (app.Data.data.filters.finish[str].sub[str2]) {
                                                app.Data.data.filters.finish[str].sub[str2].selected = true;
                                            }
                                        }
                                    }
                                }
                            }
                        } catch (e) {}
                    }

                    //VG
                    if (app.Data.data.config.filters.use_vg == true && app.Data.data.used_filters.indexOf("vg") != -1) {
                        try {
                            if (hash.filters.vg === true || hash.filters.vg === false) app.Data.data.filters.vg = hash.filters.vg;
                        } catch (e) {}
                    }

                    //SL
                    if (app.Data.data.config.filters.use_sl == true && app.Data.data.used_filters.indexOf("sl") != -1) {
                        try {
                            if (hash.filters.sl === true || hash.filters.sl === false) app.Data.data.filters.sl = hash.filters.sl;
                        } catch (e) {}
                    }

                    //PREMIUM
                    if (app.Data.data.config.filters.use_premium == true && app.Data.data.used_filters.indexOf("premium") != -1) {
                        try {
                            if (hash.filters.premium === true || hash.filters.premium === false) app.Data.data.filters.premium = hash.filters.premium;
                        } catch (e) {}
                    }

                    //DEVELOP_READY
                    if (app.Data.data.config.filters.use_develop_ready == true && app.Data.data.used_filters.indexOf("develop_ready") != -1) {
                        try {
                            if (hash.filters.develop_ready === true || hash.filters.develop_ready === false) app.Data.data.filters.develop_ready = hash.filters.develop_ready;
                        } catch (e) {}
                    }

                    //VG_OBJECTS
                    if (app.Data.data.config.filters.use_vg_objects == true && app.Data.data.used_filters.indexOf("vg_objects") != -1) {
                        try {
                            if (hash.filters.vg_objects === true || hash.filters.vg_objects === false) app.Data.data.filters.vg_objects = hash.filters.vg_objects;
                        } catch (e) {}
                    }

                    //TYPE
                    if (app.Data.data.config.filters.use_type == true && app.Data.data.used_filters.indexOf("type") != -1) {
                        try {
                            if (hash.filters.type) {
                                if (hash.filters.type.standard === true || hash.filters.type.standard === false) app.Data.data.filters.type.standard = hash.filters.type.standard;
                                if (hash.filters.type.special === true || hash.filters.type.special === false) app.Data.data.filters.type.special = hash.filters.type.special;
                            }
                        } catch (e) {}
                    }

                    //VALUES RANGES
                    var a = app.Data.data.config.filters.values_ranges.length;
                    for (var i = 0; i < a; i++) {
                        var value = app.Data.data.config.filters.values_ranges[i];

                        if (app.Data.data.config.filters["use_" + value] == true && app.Data.data.used_filters.indexOf(value) != -1) {
                            if (hash.filters[value]) {
                                try {
                                    var min = parseInt(hash.filters[value][0]);
                                    var max = parseInt(hash.filters[value][1]);

                                    if (min >= app.Data.data.filters[value].min && min <= app.Data.data.filters[value].max && min <= max) {
                                        app.Data.data.filters[value].selected_min = min;
                                    } else {
                                        app.Data.data.filters[value].selected_min = app.Data.data.filters[value].min;
                                    }

                                    if (max <= app.Data.data.filters[value].max && max >= app.Data.data.filters[value].min && max >= min) {
                                        app.Data.data.filters[value].selected_max = max;
                                    } else {
                                        app.Data.data.filters[value].selected_max = app.Data.data.filters[value].max;
                                    }

                                    if (app.Data.data.config.filters.values_checkbox[value]) {
                                        if (app.Data.data.filters[value].selected_min > app.Data.data.filters[value].min || app.Data.data.filters[value].selected_max < app.Data.data.filters[value].max) {
                                            var b = app.Data.data.config.filters.values_checkbox[value].length;
                                            for (var j = 0; j < b; j++) {
                                                var values_data = app.Data.data.config.filters.values_checkbox[value][j];
                                                if (app.Data.data.filters[value].selected_min >= values_data.values[0] && app.Data.data.filters[value].selected_min < values_data.values[1]) {
                                                    values_data.selected = true;
                                                } else if (app.Data.data.filters[value].selected_max > values_data.values[0] && app.Data.data.filters[value].selected_max < values_data.values[1]) {
                                                    values_data.selected = true;
                                                } else if (app.Data.data.filters[value].selected_min <= values_data.values[0] && app.Data.data.filters[value].selected_max >= values_data.values[1]) {
                                                    values_data.selected = true;
                                                } else {
                                                    values_data.selected = false;
                                                }
                                            }
                                        }
                                    }
                                } catch (e) {
                                    app.Data.data.filters[value].selected_min = app.Data.data.filters[value].min;
                                    app.Data.data.filters[value].selected_max = app.Data.data.filters[value].max;

                                    if (app.Data.data.config.filters.values_checkbox[value]) {
                                        var b = app.Data.data.config.filters.values_checkbox[value].length;
                                        for (var j = 0; j < b; j++) {
                                            app.Data.data.config.filters.values_checkbox[value][j].selected = false;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    //PLS
                    if (app.Data.data.platform == "ICTPLS") {
                        //PLS DECORATION
                        if (app.Data.data.config.filters.use_pls_decoration == true && app.Data.data.used_filters.indexOf("pls_decoration") != -1) {
                            try {
                                if (hash.filters.pls_decoration === true || hash.filters.pls_decoration === false) app.Data.data.filters.pls_decoration = hash.filters.pls_decoration;
                            } catch (e) {}
                        }

                        //PLS YEARBOOK
                        if (app.Data.data.config.filters.use_pls_yearbook == true && app.Data.data.used_filters.indexOf("pls_yearbook") != -1) {
                            try {
                                if (hash.filters.pls_yearbook === true || hash.filters.pls_yearbook === false) app.Data.data.filters.pls_yearbook = hash.filters.pls_yearbook;
                            } catch (e) {}
                        }

                        //PLS COUNTRY PUBLICATION
                        if (app.Data.data.config.filters.use_pls_countrypublication == true && app.Data.data.used_filters.indexOf("pls_countrypublication") != -1) {
                            try {
                                if (hash.filters.pls_countrypublication) {
                                    for (var i = 0; i < hash.filters.pls_countrypublication.length; i++) {
                                        var str = hash.filters.pls_countrypublication[i];

                                        app.Data.data.filters.pls_countrypublication[str] = true;
                                    }
                                }
                            } catch (e) {}
                        }

                        //PLS DATES
                        if (app.Data.data.config.filters.use_pls_dates == true && app.Data.data.used_filters.indexOf("pls_dates") != -1) {
                            if (hash.filters.pls_dates) {
                                try {
                                    if (app.Data.data.filters.pls_dates.dates[hash.filters.pls_dates.min] && app.Data.data.filters.pls_dates.dates[hash.filters.pls_dates.max]) {
                                        app.Data.data.filters.pls_dates.selected_min = hash.filters.pls_dates.min;
                                        app.Data.data.filters.pls_dates.selected_max = hash.filters.pls_dates.max;
                                    }
                                } catch (e) {}
                            }
                        }

                        //PLS CREATION DATES
                        if (app.Data.data.config.filters.use_pls_cdates == true && app.Data.data.used_filters.indexOf("pls_cdates") != -1) {
                            if (hash.filters.pls_cdates) {
                                try {
                                    if (app.Data.data.filters.pls_cdates.dates[hash.filters.pls_cdates.min] && app.Data.data.filters.pls_cdates.dates[hash.filters.pls_cdates.max]) {
                                        app.Data.data.filters.pls_cdates.selected_min = hash.filters.pls_cdates.min;
                                        app.Data.data.filters.pls_cdates.selected_max = hash.filters.pls_cdates.max;
                                    }
                                } catch (e) {}
                            }
                        }

                        //PLS VALUES
                        var a = app.Data.data.config.filters.pls_values.length;
                        for (var i = 0; i < a; i++) {
                            var value = app.Data.data.config.filters.pls_values[i];
                            if (app.Data.data.config.filters["use_pls_" + value] == true && hash.filters["pls_" + value] && app.Data.data.used_filters.indexOf("pls_" + value) != -1) {

                                try {
                                    if (value == "decorators" || value == "contents" || value == "communication") {
                                        for (var str in hash.filters["pls_" + value]) {
                                            if (app.Data.data.filters["pls_" + value][str]) {
                                                app.Data.data.filters["pls_" + value][str].selected = true;
                                            }
                                        }
                                    } else {
                                        app.Data.data.filters["pls_" + value].selected = hash.filters["pls_" + value];

                                        var b = app.Data.data.filters["pls_" + value].selected.length;
                                        for (var j = 0; j < b; j++) {
                                            var num = 0;

                                            var c = app.Data.data.filters["pls_" + value].elements.length;
                                            for (var k = 0; k < c; k++) {
                                                if (app.Data.data.filters["pls_" + value].selected[j] == app.Data.data.filters["pls_" + value].elements[k].id) num++;
                                            }

                                            if (num == 0) {
                                                app.Data.data.filters["pls_" + value].selected.splice(j, 1);
                                            }
                                        }
                                    }
                                } catch (e) {}
                            }
                        }
                    }
                }
            } else if (app.Data.data.config.clean_url == true) {
                if (hash != "") {
                    try {
                        var sup_tab = hash.split("&");

                        var tab = sup_tab[0].split("/");
                        if (tab.length >= 3) {
                            for (var str in app.Data.data.baseproducts) {
                                var baseproduct = app.Data.data.baseproducts[str];
                                var la = verallia.StringUtils.makeURLString(baseproduct.la).toLowerCase();

                                if (la == tab[2]) {
                                    app.Data.data.baseproduct = baseproduct;
                                    var a = baseproduct.pr.length;

                                    for (var i = 0; i < a; i++) {
                                        if (baseproduct.pr[i].cb == tab[1]) {
                                            baseproduct.selected_index = i;
                                        }
                                    }
                                }
                            }
                        }

                        sup_tab.splice(0, 1);
                        if (sup_tab.length > 0) {
                            var obj = "";
                            for (var i = 0; i < sup_tab.length; i++) {
                                //trace(sup_tab[i]);
                                tab = sup_tab[i].split("=");
                                obj = tab[0];

                                if (sup_tab[i] == "sl=true") app.Data.data.filters.sl = true;

                                var tab2;
                                for (var j = 1; j < tab.length; j++) {
                                    tab2 = tab[j].split(",");

                                    for (var k = 0; k < tab2.length; k++) {
                                        var tab3 = tab2[k].split("-");

                                        app.Data.data.filters[obj][tab3[0]].selected = true;
                                        for (var l = 1; l < tab3.length; l++) {
                                            app.Data.data.filters[obj][tab3[0]].sub[tab3[l]].selected = true;
                                        }
                                    }
                                }
                            }
                        }
                    } catch (e) {
                        trace("no product found from url hash");
                    }
                }
            }
        }
    }, {
        key: 'generateHashFromCatalog',
        value: function generateHashFromCatalog() {
            var hash = {};
            //if(app.Data.data.config.clean_url == false){
            hash.platform = app.Data.data.platform;
            hash.filters = {};

            //COUNTRIES
            if (app.Data.data.config.filters.use_countries == true && app.Data.data.used_filters.indexOf("countries") != -1) {
                for (var str in app.Data.data.filters.countries) {
                    var country = app.Data.data.filters.countries[str];

                    if (country.selected == true) {
                        if (!hash.filters.countries) hash.filters.countries = {};
                        hash.filters.countries[str] = {};
                    }
                }

                if (hash.filters.countries && Object.keys(hash.filters.countries).length == Object.keys(app.Data.data.filters.countries).length) {
                    delete hash.filters.countries;
                } else if (!hash.filters.countries) {
                    hash.filters.countries = {};
                }
            }

            //MYVERALLIA PRODUCT MODE & GROUPS / ACCOUNTS
            if (app.Data.data.platform == "MyVerallia") {
                if (app.Data.data.product_mode == "myproducts") hash.filters.product_mode = app.Data.data.product_mode;

                try {
                    if (app.Data.data.product_mode == "myproducts") {
                        if (app.Data.data.filters.myverallia_groups.selected_group && app.Data.data.filters.myverallia_groups.selected_group != "all") hash.filters.selected_group = app.Data.data.filters.myverallia_groups.selected_group;
                        if (app.Data.data.filters.myverallia_groups.selected_account && app.Data.data.filters.myverallia_groups.selected_account != "all") hash.filters.selected_account = app.Data.data.filters.myverallia_groups.selected_account;
                    }
                } catch (e) {
                    //hash.filters.selected_group = "all";
                    //hash.filters.selected_account = "all";
                }
            }

            //RANGES
            if (app.Data.data.config.filters.use_ranges == true && app.Data.data.used_filters.indexOf("ranges") != -1) {
                for (var str in app.Data.data.filters.ranges) {
                    var range = app.Data.data.filters.ranges[str];

                    if (range.selected == true) {
                        if (!hash.filters.ranges) hash.filters.ranges = {};
                        hash.filters.ranges[range.id] = {};
                    }

                    for (var str2 in range.sub) {
                        var subrange = range.sub[str2];
                        if (subrange.selected == true) {
                            if (!hash.filters.ranges) hash.filters.ranges = {};
                            if (!hash.filters.ranges[range.id]) hash.filters.ranges[range.id] = {};
                            hash.filters.ranges[range.id][subrange.id] = {};
                        }
                    }
                }
            }

            //BRANDS
            if (app.Data.data.config.filters.use_brands == true && app.Data.data.used_filters.indexOf("brands") != -1) {
                for (var str in app.Data.data.filters.brands) {
                    var brand = app.Data.data.filters.brands[str];

                    if (brand.selected == true) {
                        if (!hash.filters.brands) hash.filters.brands = {};
                        hash.filters.brands[brand.id] = {};
                    }
                }
            }

            //COLORS
            if (app.Data.data.config.filters.use_colors == true && app.Data.data.used_filters.indexOf("colors") != -1) {
                for (var str in app.Data.data.filters.colors) {
                    var color = app.Data.data.filters.colors[str];

                    if (color.selected == true) {
                        if (!hash.filters.colors) hash.filters.colors = {};
                        hash.filters.colors[color.cs] = {};
                    }

                    for (var str2 in color.sub) {
                        var subcolor = color.sub[str2];
                        if (subcolor.selected == true) {
                            if (!hash.filters.colors) hash.filters.colors = {};
                            if (!hash.filters.colors[color.cs]) hash.filters.colors[color.cs] = {};
                            hash.filters.colors[color.cs][subcolor.cs] = {};
                        }
                    }
                }
            }

            //FINISH
            if (app.Data.data.config.filters.use_finish == true && app.Data.data.used_filters.indexOf("finish") != -1) {
                for (var str in app.Data.data.filters.finish) {
                    var finish = app.Data.data.filters.finish[str];

                    if (finish.selected == true) {
                        if (!hash.filters.finish) hash.filters.finish = {};
                        hash.filters.finish[finish.id] = {};
                    }

                    for (var str2 in finish.sub) {
                        var subfinish = finish.sub[str2];
                        if (subfinish.selected == true) {
                            if (!hash.filters.finish) hash.filters.finish = {};
                            if (!hash.filters.finish[finish.id]) hash.filters.finish[finish.id] = {};
                            hash.filters.finish[finish.id][subfinish.id] = {};
                        }
                    }
                }
            }

            //VG
            if (app.Data.data.config.filters.use_vg == true && app.Data.data.used_filters.indexOf("vg") != -1) {
                if (app.Data.data.filters.vg == true) hash.filters.vg = true;
            }

            //SL
            if (app.Data.data.config.filters.use_sl == true && app.Data.data.used_filters.indexOf("sl") != -1) {
                if (app.Data.data.filters.sl == true) hash.filters.sl = true;
            }

            //PREMIUM
            if (app.Data.data.config.filters.use_premium == true && app.Data.data.used_filters.indexOf("premium") != -1) {
                if (app.Data.data.filters.premium == true) hash.filters.premium = true;
            }

            //DEVELOP_READY
            if (app.Data.data.config.filters.use_develop_ready == true && app.Data.data.used_filters.indexOf("develop_ready") != -1) {
                if (app.Data.data.filters.develop_ready == true) hash.filters.develop_ready = true;
            }

            //VG_OBJECTS
            if (app.Data.data.config.filters.use_vg_objects == true && app.Data.data.used_filters.indexOf("vg_objects") != -1) {
                if (app.Data.data.filters.vg_objects == true) hash.filters.vg_objects = true;
            }

            //TYPE
            if (app.Data.data.config.filters.use_type == true && app.Data.data.used_filters.indexOf("type") != -1) {
                if (app.Data.data.filters.type.standard == true || app.Data.data.filters.type.special == true) {
                    hash.filters.type = { standard: app.Data.data.filters.type.standard, special: app.Data.data.filters.type.special };
                }
            }

            //VALUES RANGES
            var a = app.Data.data.config.filters.values_ranges.length;
            for (var i = 0; i < a; i++) {
                var value = app.Data.data.config.filters.values_ranges[i];

                if (app.Data.data.config.filters["use_" + value] == true && app.Data.data.used_filters.indexOf(value) != -1) {
                    if (app.Data.data.filters[value].selected_min != app.Data.data.filters[value].min || app.Data.data.filters[value].selected_max != app.Data.data.filters[value].max) {
                        hash.filters[value] = [app.Data.data.filters[value].selected_min, app.Data.data.filters[value].selected_max];
                    }
                }
            }

            //PLS
            if (app.Data.data.platform == "ICTPLS") {
                //PLS DECORATION
                if (app.Data.data.config.filters.use_pls_decoration == true && app.Data.data.used_filters.indexOf("pls_decoration") != -1) {
                    if (app.Data.data.filters.pls_decoration == true) {
                        hash.filters.pls_decoration = true;
                    }
                }

                //PLS YEARBOOK
                if (app.Data.data.config.filters.use_pls_yearbook == true && app.Data.data.used_filters.indexOf("pls_yearbook") != -1) {
                    if (app.Data.data.filters.pls_yearbook == true) {
                        hash.filters.pls_yearbook = true;
                    }
                }

                //PLS COUNTRY PUBLICATION
                if (app.Data.data.config.filters.use_pls_countrypublication == true && app.Data.data.used_filters.indexOf("pls_countrypublication") != -1) {
                    var tab = [];
                    for (var str in app.Data.data.filters.pls_countrypublication) {
                        if (app.Data.data.filters.pls_countrypublication[str] == true) tab.push(str);
                    }
                    if (tab.length > 0) {
                        hash.filters.pls_countrypublication = tab;
                    }
                }

                //PLS DATES
                if (app.Data.data.config.filters.use_pls_dates == true && app.Data.data.used_filters.indexOf("pls_dates") != -1) {
                    if (app.Data.data.filters.pls_dates.selected_min != app.Data.data.filters.pls_dates.min || app.Data.data.filters.pls_dates.selected_max != app.Data.data.filters.pls_dates.max) {
                        hash.filters.pls_dates = { min: app.Data.data.filters.pls_dates.selected_min, max: app.Data.data.filters.pls_dates.selected_max };
                    }
                }

                //PLS CREATION DATES
                if (app.Data.data.config.filters.use_pls_cdates == true && app.Data.data.used_filters.indexOf("pls_cdates") != -1) {
                    if (app.Data.data.filters.pls_cdates.selected_min != app.Data.data.filters.pls_cdates.min || app.Data.data.filters.pls_cdates.selected_max != app.Data.data.filters.pls_cdates.max) {
                        hash.filters.pls_cdates = { min: app.Data.data.filters.pls_cdates.selected_min, max: app.Data.data.filters.pls_cdates.selected_max };
                    }
                }

                //PLS VALUES
                var a = app.Data.data.config.filters.pls_values.length;
                for (var i = 0; i < a; i++) {
                    var value = app.Data.data.config.filters.pls_values[i];
                    if (app.Data.data.config.filters["use_pls_" + value] == true && app.Data.data.used_filters.indexOf("pls_" + value) != -1) {

                        if (value == "decorators" || value == "contents" || value == "communication") {
                            for (var str in app.Data.data.filters["pls_" + value]) {
                                var pls_filter = app.Data.data.filters["pls_" + value][str];

                                if (pls_filter.selected == true) {
                                    if (!hash.filters["pls_" + value]) hash.filters["pls_" + value] = {};
                                    hash.filters["pls_" + value][pls_filter.id] = {};
                                }
                            }
                        } else {
                            if (app.Data.data.filters["pls_" + value].selected.length > 0) hash.filters["pls_" + value] = app.Data.data.filters["pls_" + value].selected;
                        }
                    }
                }
            }

            if (!Object.keys(hash.filters).length) {
                delete hash.filters;
            }

            if (app.Data.data.baseproduct) {
                hash.product = { id: app.Data.data.baseproduct.id, cb: app.Data.data.baseproduct.pr[app.Data.data.baseproduct.selected_index].cb };
                if (app.Data.data.platform == "MyVerallia") {
                    hash.product.fip = app.Data.data.baseproduct.pr[app.Data.data.baseproduct.selected_index].selected_line;
                }

                if (app.Data.data.platform == "ICT") {
                    if (app.Data.data.baseproduct.pr[app.Data.data.baseproduct.selected_index].mg.toLowerCase() == 'naac') {
                        hash.product.section = app.Data.data.product_section;
                    }
                }
            }
            //}

            if (app.Data.data.config.clean_url == true) {
                var filters_hash = "";

                if (hash.filters) {
                    if (hash.filters.ranges) {
                        filters_hash += "&ranges=";
                        var b = 0;
                        var a = Object.keys(hash.filters.ranges).length;
                        for (var str in hash.filters.ranges) {
                            filters_hash += str;

                            var d = 0;
                            var c = Object.keys(hash.filters.ranges[str]).length;
                            for (var str2 in hash.filters.ranges[str]) {
                                filters_hash += "-" + str2;

                                d++;
                            }

                            if (b != a - 1) filters_hash += ",";
                            b++;
                        }
                    }
                    if (hash.filters.colors) {
                        filters_hash += "&colors=";
                        var b = 0;
                        var a = Object.keys(hash.filters.colors).length;
                        for (var str in hash.filters.colors) {
                            filters_hash += str;

                            var d = 0;
                            var c = Object.keys(hash.filters.colors[str]).length;
                            for (var str2 in hash.filters.colors[str]) {
                                filters_hash += "-" + str2;

                                d++;
                            }

                            if (b != a - 1) filters_hash += ",";
                            b++;
                        }
                    }
                    if (hash.filters.finish) {
                        filters_hash += "&finish=";
                        var b = 0;
                        var a = Object.keys(hash.filters.finish).length;
                        for (var str in hash.filters.finish) {
                            filters_hash += str;
                            if (b != a - 1) filters_hash += ",";

                            b++;
                        }
                    }
                    if (hash.filters.sl == true) {
                        filters_hash += "&sl=true";
                    }
                }

                if (app.Data.data.baseproduct) {
                    hash = app.Data.data.config.country.toLowerCase() + "-" + app.Data.data.config.language.toLowerCase() + "/" + app.Data.data.baseproduct.pr[app.Data.data.baseproduct.selected_index].cb + "/" + verallia.StringUtils.makeURLString(app.Data.data.baseproduct.la).toLowerCase();
                } else {
                    hash = app.Data.data.config.country.toLowerCase() + "-" + app.Data.data.config.language.toLowerCase();
                }

                hash += filters_hash;
            }

            var hash_url = "";
            if (app.Data.data.config.clean_url == false) {
                if (Object.keys(hash).length > 0) {
                    if (app.Data.data.baseproduct) hash_url = "/" + JSON.stringify(hash);else hash_url = location.pathname + "#/" + JSON.stringify(hash);
                } else {
                    if (app.Data.data.baseproduct) hash_url = hash_url;else hash_url = location.pathname + hash_url;
                }
            } else {
                if (hash && hash != "") hash_url = "#/" + hash;else hash_url = location.pathname;
            }

            return hash_url;
        }
    }, {
        key: 'resetFilters',
        value: function resetFilters() {
            //MYVERALLIA GROUPS/ACCOUNTS
            if (app.Data.data.platform == "MyVerallia" && app.Data.data.product_mode == "myproducts" && app.Data.data.config.filters.use_myverallia_groups == true && app.Data.data.used_filters.indexOf("myverallia_groups") != -1) {
                app.Data.data.filters.myverallia_groups.selected_group = "all";
                app.Data.data.filters.myverallia_groups.selected_account = "all";
            }

            //COUNTRIES
            if (app.Data.data.config.filters.use_countries == true && app.Data.data.used_filters.indexOf("countries") != -1) {
                for (var str in app.Data.data.filters.countries) {
                    var country = app.Data.data.filters.countries[str];
                    country.selected = true;
                }
            }

            //RANGES
            if (app.Data.data.config.filters.use_ranges == true && app.Data.data.used_filters.indexOf("ranges") != -1) {
                for (var str in app.Data.data.filters.ranges) {
                    var range = app.Data.data.filters.ranges[str];
                    range.selected = false;

                    if (range.default_selected == true) range.selected = true;

                    for (var str2 in range.sub) {
                        var subrange = range.sub[str2];
                        subrange.selected = false;
                    }
                }
            }

            //BRANDS
            if (app.Data.data.config.filters.use_brands == true && app.Data.data.used_filters.indexOf("brands") != -1) {
                for (var str in app.Data.data.filters.brands) {
                    var brand = app.Data.data.filters.brands[str];
                    brand.selected = false;
                }
            }

            //COLORS
            if (app.Data.data.config.filters.use_colors == true && app.Data.data.used_filters.indexOf("colors") != -1) {
                for (var str in app.Data.data.filters.colors) {
                    var color = app.Data.data.filters.colors[str];
                    color.selected = false;

                    for (var str2 in color.sub) {
                        var subcolor = color.sub[str2];
                        subcolor.selected = false;
                    }
                }
            }

            //FINISH
            if (app.Data.data.config.filters.use_finish == true && app.Data.data.used_filters.indexOf("finish") != -1) {
                for (var str in app.Data.data.filters.finish) {
                    var finish = app.Data.data.filters.finish[str];
                    finish.selected = false;

                    for (var str2 in finish.sub) {
                        var subfinish = finish.sub[str2];
                        subfinish.selected = false;
                    }
                }
            }

            //VG
            if (app.Data.data.config.filters.use_vg == true && app.Data.data.used_filters.indexOf("vg") != -1) {
                app.Data.data.filters.vg = false;
            }

            //SL
            if (app.Data.data.config.filters.use_sl == true && app.Data.data.used_filters.indexOf("sl") != -1) {
                app.Data.data.filters.sl = false;
            }

            //PREMIUM
            if (app.Data.data.config.filters.use_premium == true && app.Data.data.used_filters.indexOf("premium") != -1) {
                app.Data.data.filters.premium = false;
            }

            //DEVELOP_READY
            if (app.Data.data.config.filters.use_develop_ready == true && app.Data.data.used_filters.indexOf("develop_ready") != -1) {
                app.Data.data.filters.develop_ready = false;
            }

            //VG_OBJECTS
            if (app.Data.data.config.filters.use_vg_objects == true && app.Data.data.used_filters.indexOf("vg_objects") != -1) {
                app.Data.data.filters.vg_objects = false;
            }

            //TYPE
            if (app.Data.data.config.filters.use_type == true && app.Data.data.used_filters.indexOf("type") != -1) {
                app.Data.data.filters.type.standard = false;
                app.Data.data.filters.type.special = false;
            }

            //VALUES RANGES
            var a = app.Data.data.config.filters.values_ranges.length;
            for (var i = 0; i < a; i++) {
                var value = app.Data.data.config.filters.values_ranges[i];

                if (app.Data.data.config.filters["use_" + value] == true && app.Data.data.used_filters.indexOf(value) != -1) {
                    app.Data.data.filters[value].selected_min = app.Data.data.filters[value].min;
                    app.Data.data.filters[value].selected_max = app.Data.data.filters[value].max;

                    if (app.Data.data.config.filters.values_checkbox[value]) {
                        var b = app.Data.data.config.filters.values_checkbox[value].length;
                        for (var j = 0; j < b; j++) {
                            app.Data.data.config.filters.values_checkbox[value][j].selected = false;
                        }
                    }
                }
            }

            //PLS DECORAYION
            if (app.Data.data.config.filters.use_pls_decoration == true && app.Data.data.used_filters.indexOf("pls_decoration") != -1) {
                app.Data.data.filters.pls_decoration = false;
            }

            //PLS YEARBOOK
            if (app.Data.data.config.filters.use_pls_yearbook == true && app.Data.data.used_filters.indexOf("pls_yearbook") != -1) {
                app.Data.data.filters.pls_yearbook = false;
            }

            //PLS COUNTRY PUBLICATION
            if (app.Data.data.config.filters.use_pls_countrypublication == true && app.Data.data.used_filters.indexOf("pls_countrypublication") != -1) {
                for (var str in app.Data.data.filters.pls_countrypublication) {
                    app.Data.data.filters.pls_countrypublication[str] = false;
                }
            }

            //PLS DATES
            if (app.Data.data.config.filters.use_pls_dates == true && app.Data.data.used_filters.indexOf("pls_dates") != -1) {
                app.Data.data.filters.pls_dates.selected_min = app.Data.data.filters.pls_dates.min;
                app.Data.data.filters.pls_dates.selected_max = app.Data.data.filters.pls_dates.max;
            }

            //PLS CREATION DATES
            if (app.Data.data.config.filters.use_pls_cdates == true && app.Data.data.used_filters.indexOf("pls_cdates") != -1) {
                app.Data.data.filters.pls_cdates.selected_min = app.Data.data.filters.pls_cdates.min;
                app.Data.data.filters.pls_cdates.selected_max = app.Data.data.filters.pls_cdates.max;
            }

            //PLS VALUES
            var a = app.Data.data.config.filters.pls_values.length;
            for (var i = 0; i < a; i++) {
                var value = app.Data.data.config.filters.pls_values[i];

                if (app.Data.data.config.filters["use_pls_" + value] == true && app.Data.data.used_filters.indexOf("pls_" + value) != -1) {

                    if (value == "decorators" || value == "contents" || value == "communication") {
                        for (var str in app.Data.data.filters["pls_" + value]) {
                            var pls_filter = app.Data.data.filters["pls_" + value][str];
                            pls_filter.selected = false;
                        }
                    } else {
                        app.Data.data.filters["pls_" + value].selected = [];
                    }
                }
            }
        }
    }, {
        key: 'updateAccountsVisibility',
        value: function updateAccountsVisibility() {
            var a = app.Data.data.filters.myverallia_groups.cwg.length;
            for (var i = 0; i < a; i++) {
                if (app.Data.data.filters.myverallia_groups.selected_group == "all") {
                    app.Data.data.filters.myverallia_groups.cwg[i].disabled = false;
                } else {
                    if (i == 0) {
                        app.Data.data.filters.myverallia_groups.cwg[i].disabled = false;
                    } else {
                        app.Data.data.filters.myverallia_groups.cwg[i].disabled = true;
                        if (app.Data.data.filters.myverallia_groups.selected_group == app.Data.data.filters.myverallia_groups.cwg[i].id) {
                            app.Data.data.filters.myverallia_groups.cwg[i].disabled = false;
                        }
                    }
                }
            }
        }
    }, {
        key: 'getCountryFromCompanyCode',
        value: function getCountryFromCompanyCode(code) {
            for (var str in app.Data.data.config.company_code) {
                if (app.Data.data.config.company_code[str].code == code) return app.Data.data.config.company_code[str].country;
            }

            return null;
        }
    }, {
        key: 'getAllMetaProducts',
        value: function getAllMetaProducts() {
            var metaproducts = {};

            var products = verallia.ObjectUtils.copy(app.Data.data.baseproducts);

            for (var str in products) {
                var pr = products[str];
                var gd = this.getGDWithoutVersion(pr);

                pr.gd = gd;

                metaproducts[gd] = pr;
            }

            return metaproducts;
        }
    }]);

    return _class;
}();